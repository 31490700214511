<template>
	<div class="main-content">
		<pagesHeader title="会员商城售后管理" />
		<Search @changeShopSalesSearch="changeShopSalesSearch" />
		<avue-crud ref="crud" :data="data" :option="option" :page.sync="pageSync" @on-load="onLoad" @expand-change="expandChanges" @selection-change="selectionChange">
			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left: 10px" size="small" type="text" v-if="row.orderAfterSalesState == 2 && row.afterSalesType == 1" @click.stop="clickReturn(row)">
					直接退款
				</el-button>
				<el-button style="margin-left: 10px" size="small" type="text" v-if="row.orderAfterSalesState == 2 && row.afterSalesType == 2" @click.stop="clickadopt(row)">
					通过
				</el-button>
				<el-button style="margin-left: 10px" size="small" type="text" v-if="row.orderAfterSalesState == 2" @click.stop="clickrefuse(row)">拒绝</el-button>
				<el-button style="margin-left: 10px" size="small" type="text" v-if="row.orderAfterSalesState == 4" @click.stop="clickReceiptAndRefund(row)">确认收货并退款</el-button>
				<div v-if="!(row.orderAfterSalesState == 2 && row.afterSalesType == 1) && !(row.orderAfterSalesState == 2 && row.afterSalesType == 2) && !(row.orderAfterSalesState == 4)">/</div>
			</template>

			<template slot="expand" slot-scope="{ row }">
				<div class="expand">
					<div>{{ row.productName }}</div>
					<div>{{ row.specName }}</div>
				</div>
			</template>
		</avue-crud>
		<div class="pages" v-if="data.length > 0">
			<div class="single-choice">
				<el-checkbox v-model="checked">全选</el-checkbox>
				<div @click="clickadoptAll">批量通过</div>
				<div @click="clickrefuseAll">批量拒绝</div>
			</div>
			<div></div>
		</div>

		<Refuse v-if="refuse.type" :refuse="refuse"></Refuse>
	</div>
</template>

<script>
import Search from './search.vue';
import { shopSalesList, shopSalesRefundAgree, shopSalesReturnAgree, shopSalesRefuse,shopSalesReturnReceive } from '@/api/shop/sales.js';
import Refuse from './refuse.vue';
export default {
	name: 'index',
	components: {
		Search,
		Refuse
	},
	data() {
		return {
			refuse: {
				type: false,
				id: []
			},
			search: {
				date: [],
				commitTimeStart: '', //下单时间开始
				commitTimeEnd: '', //下单时间开始
				afterSalesType: '', //售后类型：1-仅退款；2-退货退款
				orderAfterSalesState: '', //订单售后状态：2-申请中；4-等待卖家收货；5-同意退款；6-拒绝
				code: ''
			},
			data: [],
			option: {
				expand: true,
				expandRowKeys: [],
				rowKey: 'id',
				addBtn: false,
				delBtn: false,
				editBtn: false,
				viewBtn: false,
				align: 'center',
				menuAlign: 'center',
				header: false,
				border: true,
				selection: true,
				column: [
					{
						label: '会员昵称',
						prop: 'nickname'
					},
					{
						label: '订单编号',
						prop: 'code'
					},
					{
						label: '申请时间',
						prop: 'afterSalesCreateTime'
					},
					{
						label: '售后类型',
						prop: 'afterSalesType',
						type: 'radio',
						dicData: [{ label: '仅退款', value: 1 }, { label: '退货退款', value: 2 }]
					},
					{
						label: '手机号',
						prop: 'phone'
					},
					{
						label: '退款金额',
						prop: 'reallyPrice'
					},
					{
						label: '退款理由',
						prop: 'refundExplain'
					},
					{
						label: '退货快递',
						prop: 'returnLogistics'
					},
					{
						label: '退货单号',
						prop: 'returnNumber',
						type: 'radio'
					},
					{
						label: '退款状态',
						prop: 'orderAfterSalesState',
						type: 'radio',
						dicData: [
							{ label: '申请中', value: 2 },
							{ label: '申请通过', value: 3 },
							{ label: '等待卖家收货', value: 4 },
							{ label: '退款通过', value: 5 },
							{ label: '拒绝', value: 6 }
						]
					},
					{
						label: '退款操作员',
						prop: 'returnUsername'
					}
				]
			},
			pageSync: {
				currentPage: 1,
				total: 0,
				layout: 'total,pager,prev, next',
				background: false,
				pageSize: 10
			},
			checked: false,
			list: []
		};
	},
	watch: {
		list(newValue){
			if(newValue.length == this.data.length){
				this.checked = true
			}else{
				this.checked = false
			}
		},
		checked(newValue) {
			if(newValue){
				if(this.list.length == 0){
					this.$refs.crud.toggleSelection(this.data);
				}else{
					let list = this.data.filter(items => {
						if (!this.list.includes(items)) return items;
					})
					console.log(list)
					this.$refs.crud.toggleSelection(list);
				}
			}else{
				this.list = [];
				this.$refs.crud.toggleSelection();
			}
		}
	},
	mounted() {
		this.$root.eventHub.$on('changeShopOrderRefuse', (n)=>{
			this.refuse =  {
				type: false,
				id: ''
			};
			if(n){
				this.GetshopSalesList();
			}
		})
	},
	methods: {
		clickReceiptAndRefund(row){
			shopSalesReturnReceive(row.id).then(res => {
				if (res.code === 200) {
					this.$message.success('操作成功');
					this.GetshopSalesList();
				}
			});
		},
		clickadoptAll() {
			// console.log('批量通过',this.list);
			let array = [];
			this.list.forEach(item => {
				array.push(item.id)
			})
			// console.log(array)
			if(array.length == 0) return this.$message.error('请选择至少一条数据')
			shopSalesReturnAgree(array.toString()).then(res => {
				if (res.code === 200) {
					this.$message.success('操作成功');
					this.GetshopSalesList();
				}
			});
		},
		clickrefuseAll() {
			// console.log('批量拒绝',this.list);
			let array = [];
			this.list.forEach(item => {
				array.push(item.id)
			})
			// console.log(array)
			if(array.length == 0) return this.$message.error('请选择至少一条数据')
			this.refuse.ids = array.toString()
			this.refuse.type = true
		},
		onLoad() {
			this.GetshopSalesList();
		},
		GetshopSalesList() {
			shopSalesList({
				page: this.pageSync.currentPage,
				size: this.pageSync.pageSize,
				commitTimeStart: this.search.commitTimeStart, //下单时间开始
				commitTimeEnd: this.search.commitTimeEnd, //下单时间结束
				afterSalesType: this.search.afterSalesType, //售后类型：1-仅退款；2-退货退款
				orderAfterSalesState: this.search.orderAfterSalesState, //订单售后状态：2-申请中；4-等待卖家收货；5-同意退款；6-拒绝
				code: this.search.code //订单编号/手机号
			}).then(res => {
				if (res.code === 200) {
					this.data = res.data.records;
					this.pageSync.total = res.data.total;
					this.pageSync.currentPage = res.data.current;
				}
			});
		},
		changeShopSalesSearch(e) {
			this.search = e;
			this.GetshopSalesList();
		},
		selectionChange(list) {
			if (list.length == this.data.length) {
				this.checked = true;
			} else {
				this.checked = false;
			}
			this.list = list;
			console.log(list);
		},
		expandChanges(row, expendList) {
			if (expendList.length) {
				this.option.expandRowKeys = [];
				if (row) {
					this.option.expandRowKeys.push(row.id);
				}
			} else {
				this.option.expandRowKeys = [];
			}
		},
		clickReturn(row) {
			shopSalesRefundAgree(row.id).then(res => {
				if (res.code === 200) {
					this.$message.success('操作成功');
					this.GetshopSalesList();
				}
			});
		},
		clickadopt(row) {
			shopSalesReturnAgree(row.id).then(res => {
				if (res.code === 200) {
					this.$message.success('操作成功');
					this.GetshopSalesList();
				}
			});
		},
		clickrefuse(row) {
			let array = [];
			array.push(row.id);
			this.refuse.ids = array
			this.refuse.type = true
		}
	}
};
</script>

<style scoped>
.avue-crud >>> .avue-crud__tip {
	display: none;
}
.expand {
	padding: 0 60px;
	display: flex;
}
.expand div {
	margin-right: 20px;
}
.pages {
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba(228, 228, 228, 1);
}
.single-choice {
	display: flex;
	align-items: center;
	padding-left: 10px;
}
.single-choice div {
	line-height: 19px;
	font-size: 14px;
	color: #409eff;
	margin-left: 20px;
	cursor: pointer;
}
</style>
