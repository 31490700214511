<template>
	<div class="main-content">
		<pagesHeader title="合作单位管理" />
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:table-loading="loading"
		>
			<template slot="menuLeft">
				<el-button type="primary" size="medium" @click.stop="clickAdd">新增合作单位</el-button>
			</template>

			<template slot-scope="{ row, index }" slot="state">
				<el-switch v-model="row.state" @change="switchChange($event,row.id)"></el-switch>
			</template>

			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickEdit(row)">编辑</el-button>
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickCel(row)">删除</el-button>
			</template>

		</avue-crud>
	</div>
</template>

<script>
	import { pageCompanyList,pageCompanyDelete,pageCompanyStateUpdate } from "@/api/page/company";
    import { imageUrl } from '@/config/public';
	export default {
		name: 'index',
		data(){
			return{
				loading: true,
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					columnBtn:false,  //列显隐
					refreshBtn:false,  //刷新
					border: true,
					index: true,
          indexLabel: '序号',
					column: [
            {
              label: '合作单位名称',
              prop: 'name'
            },
            {
              label: '合作单位图片',
              type: 'img',
              prop: 'image'
            },
            {
              label: '排序值',
              prop: 'sort'
            },
						{
							label: '是否上架',
							prop: 'state',
							slot: true
						}
					]
				},
			}
		},
		mounted() {
			this.GetPageCompanyList();
		},
		methods: {
			clickAdd(){
				if(this.data.length == 6){
					this.$message.error('合作单位数量已达最大')
				}else{
					this.$router.push({ name: 'companyAdd',params: { id: '0' } })
				}
			},
			clickEdit(row){
				this.$router.push({ name: 'companyAdd',params: { id: row.id } })
			},
			clickCel(row){
				this.$confirm(`确定删除合作单位`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					callback: action => {
						if (action == 'confirm') {
							pageCompanyDelete(row.id).then(res => {
								if(res.code === 200){
									this.$message.success('删除成功')
									this.GetPageCompanyList();
								}else{
									this.$message.error(res.msg)
								}
							})
						}
					}
				});

			},
			GetPageCompanyList(){
				pageCompanyList().then(res => {
					console.log(res)
					if(res.code === 200){
						this.loading = false;
						res.data.forEach(item => {
							item.state = item.state == 1 ? true : false
						})
						this.data = res.data;
					}
				})
			},
			switchChange(e,id){
				console.log(e,id)
				pageCompanyStateUpdate({
					id: id,
					state: e ? 1 : 2
				}).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功')
						this.GetPageCompanyList();
					}else{
						this.$message.error(res.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
</style>
