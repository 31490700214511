<template>
	<div class="main-content">
		<pagesHeader :title="`订单详情-${details.state == '1' ? '已下单' : details.state == '2' ? '已接单-待处理' : details.state == '3' ? '已完成' : details.state == '4' ? '已取消' : '已接单-已处理'}`" />
		<div class="details" v-if="loading">
			<el-button type="primary"
			style="margin-bottom: 20px;"
			@click="$router.go(-1)">返回</el-button>
				<div class="details-view">
					<div class="view-left">订单编号 ：</div>
					<div class="view-right">{{details.code}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">下单时间 ：</div>
					<div class="view-right">{{details.createTime}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">下单人 ：</div>
					<div class="view-right">{{details.nickname}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">下单人手机号 ：</div>
					<div class="view-right">{{details.userPhone}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">联系人 ：</div>
					<div class="view-right">{{details.username}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">联系方式 ：</div>
					<div class="view-right">{{details.phone}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">联系地址 ：</div>
					<div class="view-right">{{details.province}}{{details.city}}{{details.county}}{{details.detail}}</div>
				</div>
				<el-divider></el-divider>
				<div class="details-view">
					<div class="view-left">选择回收品类型 ：</div>
					<div class="view-right">{{details.goodsClassify}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">预约上门时间 ：</div>
					<div class="view-right">{{details.doorTime}}</div>
				</div>
				<div class="details-view">
					<div class="view-left">备注 ：</div>
					<div class="view-right">{{details.remarks}}</div>
				</div>
				<div class="details-view" v-if="details.state == 5 || details.state == 3">
					<div class="view-left">订单合计 ：</div>
					<div class="view-right" style="color: #D9001B !important;">￥{{details.price}}</div>
				</div>
				<div class="details-view" v-if="details.state == 5 || details.state == 3">
					<div class="view-left">获得积分 ：</div>
					<div class="view-right" style="color: #F59A23 !important;">{{details.integral}}分</div>
				</div>
				<div class="details-view" v-if="details.state == 1 || details.state == 4 ">
					<div class="view-left">订单状态 ：</div>
					<div class="view-right" style="color: #02A7F0;font-weight: 600;">
						{{details.state == '1' ? '已下单' : details.state == '2' ? '已接单' : details.state == '3' ? '已完成' : details.state == '4' ? '已取消' : '已处理'}}
					</div>
				</div>

				<el-divider v-if="details.state == 2 || details.state == 5 || details.state == 3"></el-divider>
				<div class="details-view" v-if="details.state == 2 || details.state == 5 || details.state == 3">
					<div class="view-left">订单状态 ：</div>
					<div class="view-right" style="color: #02A7F0;font-weight: 600;">
						{{details.state == '1' ? '已下单' : details.state == '2' ? '已接单' : details.state == '3' ? '已完成' : details.state == '4' ? '已取消' : '已接单'}}
					</div>
				</div>
				<div class="details-view" v-if="details.state == 2 || details.state == 5 || details.state == 3">
					<div class="view-left">回收员 ：</div>
					<div class="view-right">{{details.recoverUsername}}</div>
				</div>
				<div class="details-view" v-if="details.state == 2 || details.state == 5 || details.state == 3">
					<div class="view-left">手机号 ：</div>
					<div class="view-right">{{details.recoverPhone}}</div>
				</div>
				<el-divider v-if="details.state == 5 || details.state == 3"></el-divider>
				<div class="details-view" v-if="details.state == 5 || details.state == 3">
					<div class="view-left">回收详情 ：</div>
				</div>
				<avue-crud :data="details.productList" :option="option" v-if="details.state == 5 || details.state == 3">
					<template slot-scope="{ row, index }" slot="num">
						<div>{{row.num + row.unit}}</div>
					</template>
					<!-- <template slot-scope="{ row, index }" slot="image">
						<div class="images" v-if="row.uploadType == 1">
							<div class="images-item" v-for="(item,index) in row.images" :key="index">
								<img :src="item" alt="" @click="clickImageShow(row.images)">
							</div>
						</div>
						<div class="images" v-if="row.uploadType == 2">
							<div class="images-item">
								<video width="30" height="30" :src="row.image" @click="clickVideoShow(row.image)">
								</video>
							</div>
						</div>
					</template> -->
				</avue-crud>
		</div>
		<previewImage v-if="imagesShow" :images="images" @TAP_ImgesShow="TAP_ImgesShow"></previewImage>
		<previewVideo v-if="videoShow" :video="video" @TAP_VideoShow="TAP_VideoShow"></previewVideo>
	</div>
</template>

<script>
import { orderRecoverView } from '@/api/order/list.js';
export default {
	data() {
		return {
			loading: false,
			details: {},
			imagesShow: false,
			images: [],
			videoShow: false,
			video: '',
			option:{
				header: false,
				addBut: false,
				celBut: false,
				editBut: false,
				menu: false,
				border: true,
				align:'center',
				menuAlign:'center',
				column:[
					{
						label: '回收品类型',
						prop: 'classifyName'
					},
					{
						label: '回收物品名称',
						prop: 'name'
					},
					{
						label: '计量单位',
						prop: 'num',
						slot: true
					},
					{
						label: '图片/视频',
						prop: 'image',
						dataType: 'string',
						type: 'img',
						width: 300,
					},
					{
						label: '预计金额',
						prop: 'price'
					}
				]
			}
		};
	},
	mounted() {
		this.GetOrderRecoverView();
	},
	methods: {
		GetOrderRecoverView() {
			orderRecoverView(this.$route.params.id).then(res => {
				if (res.code === 200) {
					this.loading = true;
					res.data.productList.forEach(item => {
						item.images = item.image.split(',')
					});
					this.details = res.data;
				}
			});
		},
		clickImageShow(images){
			console.log(images)
			this.imagesShow = true;
			this.images = images;
		},
		TAP_ImgesShow(){
			this.imagesShow = false;
			this.images = [];
		},
		clickVideoShow(video){
			this.videoShow = true;
			this.video = video;
		},
		TAP_VideoShow(){
			this.videoShow = false;
			this.video = [];
		}
	}
};
</script>

<style scoped>
.details{
	padding: 0 10px;
}
.details-view{
	display: flex;
	font-family: 'Arial Normal', 'Arial', sans-serif;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	line-height: 30px;
	color: #333333;
}
.view-left{
	width: 150px;
}
.images{
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.images-item{
	width: 30px;
	height: 30px;
}
.images img{
	width: 30px;
	height: 30px;
}
</style>
