<template>
	<div class="specs">
		<div class="specs-title">
			 <el-button @click.stop="clickAddSpec">添加规格</el-button>
		</div>
		<div class="specsA-list">
			<div class="specsA-item" v-for="(item,index) in spec" :key="index">
				<div class="specsA-item-title">{{item.key}}</div>
				<div class="specsA-item-spec">
					<div class="specsA-item-spec-item" v-for="(itemC,indexC) in item.value" :key="indexC">
						<div>{{itemC.content}}</div>
						<i class="el-icon-close" @click="specDelete(index,indexC,item.value.length)"></i>
					</div>
					<div class="specsA-item-spec-item specadd" @click="specAdd(index)">添加</div>
				</div>
			</div>
		</div>
		
		<Specification v-if="specification.type" :specification="specification" :spec="spec"/>
		<Parameter v-if="parameter.type" :parameter="parameter" :spec="spec"/>
	</div>
</template>

<script>
	import Specification from "./specification.vue"
	import Parameter from "./parameter.vue"
	export default {
		components: {
			Specification,
			Parameter
		},
		props: {
			formSpecs: Array
		},
		data(){
			return{
				specification: {
					type: false
				},
				parameter: {
					type: false,
					index: 0
				},
				spec: this.formSpecs.length > 0 ? this.formSpecs : [] 
			}
		},
		mounted() {
			this.$root.eventHub.$on('changeShopGoodsAdditionSpecification', (n)=>{
			    this.specification = {
					type: false
				};
				if(n.state){
					this.spec.push({key: n.data.specification ,value: [{content: n.data.parameter}]})
					this.$root.eventHub.$emit('changeShopGoodsAdditionSpecList', this.spec);
					this.$root.eventHub.$emit('changeShopGoodsformSepc', this.spec);
				}
			})
			
			this.$root.eventHub.$on('changeShopGoodsAdditionParameter', (n)=>{
			    this.parameter = {
					type: false
				};
				if(n.state){
					this.spec[n.data.index].value.push({content: n.data.parameter})
					this.$root.eventHub.$emit('changeShopGoodsAdditionSpecList', this.spec);
					this.$root.eventHub.$emit('changeShopGoodsformSepc', this.spec);
				}
			})
		},
		methods: {
			specAdd(i){
				this.parameter.type = true;
				this.parameter.index = i;
			},
			specDelete(a,b,length){
				if(length == 1){
					this.spec.splice(a,1)
				}else{
					this.spec[a].value.splice(b,1)
				}
				this.$root.eventHub.$emit('changeShopGoodsAdditionSpecList', this.spec);
				this.$root.eventHub.$emit('changeShopGoodsformSepc', this.spec);
			},
			clickAddSpec(){
				this.specification.type = true;
			}
		}
	}
</script>

<style scoped>
.specsA-item{
	padding-top: 10px;
}
.specsA-item-title{
	font-size: 16px;
	color: #333333;
	line-height: 30px;
}
.specsA-item-spec{
	display: flex;
}
.specsA-item-spec-item{
	font-size: 14px;
	color: #333333;
	line-height: 30px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	border: 1px solid #eeeeee;
	margin-right: 10px;
	margin-bottom: 10px;
	padding: 0 10px;
}
.el-icon-close{
	display: block;
	font-size: 14px;
	line-height: 30px;
	margin-left: 10px;
	cursor: pointer;
	color: #409eff;
}
.specadd{
	background-color: #409eff;
	color: #FFFFFF;
}
</style>
