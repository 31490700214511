<template>
	<el-dialog title="新增分类"
		width="30%"
		v-dialogdrag
		:visible.sync="componentsData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form"
		   :upload-error="uploadError"
		   :upload-delete="uploadDelete"
               :upload-before="uploadBefore"
		   :upload-after="uploadAfter">
		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { imageUrl, actionUrl } from '@/config/public.js';
import { shopclassifyAdd,shopclassifyUpdate } from "@/api/shop/classify.js"
	export default {
		props: {
			componentsData: Object
		},
		data(){
			return {
				disabled: false,
				form: this.componentsData.state === 'edit' ? this.componentsData.data : {},
				option: {
					labelWidth: 120,
					submitBtn: false,
					emptyBtn: false,
					column: [
						{
							label:'分类名称',
							prop: 'name',
							type: 'input',
							maxlength: 4,
							span: 20,
							rules: [{
								required: true,
								message: "最多输入4个字",
								trigger: "blur"
							}]
						},
						{
							label: '分类Icon',
							prop: 'image',
							type: 'upload',
							accept: 'image/png, image/jpeg',
							span: 24,
							listType: 'picture-img',
							loadText: '附件上传中，请稍等',
							tip: '( 最多上传1张 )',
              fileSize: 1024 * 20,
							propsHttp: {
								res: 'data',
								url: 'url',
								name: 'path',
								home: imageUrl
							},
							headers: {
								Authorization: this.$store.state.user.Token
							},
							action: actionUrl,
							rules: [
								{
									required: true,
									message: '请上传分类Icon',
									trigger: 'blur'
								}
							]
						},
						{
							label:'排序值',
							prop:'sort',
							type: 'input',
							span: 20,
							rules: [{
								required: true,
								message: "请输入排序值",
								trigger: "blur"
							}]
						}
					]
				}
			}
		},
		methods: {
			handleSubmit(form){
				this.$refs.form.validate((vaild,done)=>{
				    if(vaild){
						this.disabled = true;
				        if(this.componentsData.state == 'add'){
							this.GetShopclassifyAdd(form);
				        }else{
							this.GetShopclassifyUpdate(form);
				        }
				    }
				})
			},
			GetShopclassifyAdd(form){
				shopclassifyAdd({
					name: form.name,
					image: form.image,
					sort: form.sort
				}).then(res => {
					if(res.code === 200){
						this.$message.success('添加成功');
						this.disabled = false;
						this.$root.eventHub.$emit('changeShopClassifyIndex', true);
					}
				})
			},
			GetShopclassifyUpdate(form){
				shopclassifyUpdate({
					id: form.id,
					name: form.name,
					image: form.image,
					sort: form.sort
				}).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功');
						this.disabled = false;
						this.$root.eventHub.$emit('changeShopClassifyIndex', true);
					}
				})
			},
			handleClose() {
				this.$root.eventHub.$emit('changeShopClassifyIndex', false);
			},
			uploadDelete() {
				return this.$confirm(`是否确定移除？`);
			},
			uploadError(error, column) {
				this.$message.error(error);
				// console.log(error, column)
			},
      uploadBefore(file, done, loading,column) {
        if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
          this.$message.error('文件格式有误');
          loading()
        }else {
          done();
        }
      },
			uploadAfter(res, done) {
				done();
				this.$nextTick(() => {
					this.form.image = res.path;
				});
			}
		}
	}
</script>

<style>
</style>
