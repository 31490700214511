<template>
	<div class="data">
		<div class="data-item">
			<div class="data-item-title">用户累计积分总和</div>
			<div class="data-item-num">{{presentation.integral || 0}}</div>
		</div>
		<div class="data-item">
			<div class="data-item-title">用户账户余额总和</div>
			<div class="data-item-num">{{presentation.balance || 0}}</div>
		</div>
		<div class="data-item">
			<div class="data-item-title">用户已提现余额总和</div>
			<div class="data-item-num">{{presentation.withdraw || 0}}</div>
		</div>
	</div>
</template>

<script>
	import { UserCount } from "@/api/user/list.js";
	export default{
		props: {
			presentation: Object
		}
	}
</script>

<style scoped>
	.data{
		display: flex;
		padding-bottom: 20px;
	}
	.data-item{
		width: 200px;
		height: 145px;
		border: 1px solid rgba(170, 170, 170, 1);
		border-radius: 10px;
		text-align: center;
		margin-right: 30px;
	}
	.data-item-title{
		font-family: 微软雅黑, sans-serif;
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		color: rgb(102, 102, 102);	
		padding: 20px 0;
	}
	.data-item-num{
		font-family: 微软雅黑, sans-serif;
		font-weight: 400;
		font-style: normal;
		font-size: 36px;
		color: rgb(0, 0, 255);
	}
</style>
