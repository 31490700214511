<template>
	<div>
		<el-carousel indicator-position="outside" :autoplay="false">
			<el-carousel-item v-for="(item,index) in images" :key="index">
				<div class="imgView">
					<img :src="item" >
				</div>
			</el-carousel-item>
		</el-carousel>
		<div class="close" @click="TAP_ImgesShow">
			<i class="el-icon-circle-close"></i>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		images: Array
	},
	data() {
		return {};
	},
	mounted() {
		console.log(this.images)
	},
	methods:{
		TAP_ImgesShow(){
			this.$emit('TAP_ImgesShow')
		}
	}
};
</script>

<style scoped>
.close{
	width: 40px;
	height: 40px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 99999;
}
.el-icon-circle-close{
	font-size: 40px;
}
.el-carousel {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba(0,0,0,0.3);
}
.el-carousel__container{
	width: 100vw;
	height: 100vh;
}
.el-carousel >>> .el-carousel__item{
	width: 100vw;
	height: 100vh;
}
.imgView{
	width: 100vw;
	height: 100vh;
	position: relative;
}
img{
	display: block;
	width: 50%;
	height: auto;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
</style>
