<template>
	<el-dialog :title="componentsData.title"
             width="30%"
		v-dialogdrag
		:visible.sync="componentsData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form"
			:upload-after="uploadAfter"
			:upload-error="uploadError"
			:upload-delete="uploadDelete">

			<template slot="sort">
				<el-input v-model="form.sort"
						  autocomplete="off"
						  type="text"
						  maxlength="6"
						  oninput="this.value = this.value.replace(/[^0-9]/g, '');"/>
			</template>

		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { orderClassifyAdd, orderClassifyUpdate } from '@/api/order/big.js';
export default {
	props: {
		componentsData: Object
	},
	data() {
		return {
			disabled: false,
			form: this.componentsData.state === 'edit' ? this.componentsData.data : {unit: 1},
			option: {
			    submitBtn: false,
			    emptyBtn: false,
			    column: [
			        {
			            label:'分类名称',
			            prop:'name',
						type: 'input',
			            span: 15,
						maxlength: 4,
                placeholder:'最多输入4个字',
			            rules: [{
			                required: true,
			                message: "请输入分类名称",
			                trigger: "blur"
			            }]
			        },
					{
					    label: '排序值',
					    prop: 'sort',
						type: 'number',
					    span: 15,
						slot: true,
						rules: [
							{
								required: true,
								message: '请填写排序值',
								trigger: 'blur'
							}
						]
					}
			    ]
			},
		};
	},
	methods: {
		handleSubmit(form){
			console.log(form)
			this.$refs.form.validate((vaild,done)=>{
			    if(vaild){
					this.disabled = true;
					if(this.componentsData.state === 'add'){
						this.GetOrderClassifyAdd(form,done)
					}else{
						this.GetOrderClassifyUpdate(form,done)
					}
			    }
			})
		},
		GetOrderClassifyAdd(form,done){
			orderClassifyAdd({
				name: form.name,
				sort: parseInt(form.sort)
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$root.eventHub.$emit('changeBigIndex', true);
					this.$message.success('提交成功');
				} else {
					this.$message.error(res.msg);
					this.disabled = false;
				}
				done();
			});
		},
		GetOrderClassifyUpdate(form,done){
			orderClassifyUpdate({
				id: form.id,
				name: form.name,
				sort: parseInt(form.sort)
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$root.eventHub.$emit('changeBigIndex', true);
					this.$message.success('提交成功');
				} else {
					this.$message.error(res.msg);
					this.disabled = false;
				}
				done();
			});
		},
		handleClose() {
			this.$root.eventHub.$emit('changeBigIndex', false);
		},
		uploadAfter(res, done) {
			done();
			this.$nextTick(() => {
				this.form.icon = res.path;
			});
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error(error);
			// console.log(error, column);
		}
	}
};
</script>

<style scoped>
.avue-form >>> .el-upload{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-icon-plus{
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.avue-form >>> .el-upload--picture-card{
    line-height: 100px;
}
.avue-form >>> .avue-upload__avatar{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-upload-list__item,.el-upload-list__item-actions{
    width: 100px;
    height: 100px;
}
</style>
