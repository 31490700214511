import {Delete, get, put} from '../axios'

// 回收订单列表-列表
export const orderRecoverList = params => get('/web/order/recover/list',params);

// 回收订单列表-物品分类-列表
export const orderRecoverClassifyList = params => get('/web/order/recover/classify/list',params);

// 回收订单列表-回收人员-列表
export const orderRecoverUserList = params => get('/web/order/recover/user/list',params);

// 回收订单列表-派单或重新派单
export const orderRecoverDispatch = params => put('/web/order/recover/dispatch',params);

// 回收订单列表-订单详情
export const orderRecoverView = params => get(`/web/order/recover/view/${params}`);

// 回收订单列表-取消订单
export const orderRecoverCancel = params => get(`/web/order/recover/cancel/${params}`);
