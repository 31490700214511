<template>
	<div class="header">
		<div class="search">
			<el-date-picker
				v-model="form.date"
				type="daterange"
				range-title="下单时间"
				range-separator="~"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				class="date-picker"
				format="yyyy-MM-dd"
				value-format="yyyy-MM-dd"
				style="margin-right: 10px;">
			</el-date-picker>
			<el-select v-model="form.goodsClassify"
				placeholder="回收品类型"
				style="margin-right: 10px;">
				<el-option
					  v-for="item in optionsClassify"
					  :key="item.name"
					  :label="item.name"
					  :value="item.name">
				</el-option>
			</el-select>
			<el-input v-model="form.name"
				placeholder="请输入下单人昵称/下单人手机号/回收员/手机号"
				style="margin-right: 10px;"></el-input>
			<el-select v-model="form.turnState"
				placeholder="请选择"
				style="margin-right: 10px;">
				<el-option
					  v-for="item in optionsState"
					  :key="item.value"
					  :label="item.label"
					  :value="item.value">
				</el-option>
			</el-select>
			<el-button type="primary" @click="searchQuery">查询</el-button>
			<el-button type="info" @click="searchReset">重置</el-button>
		</div>
		<el-button type="primary" style="height: 40px !important;"  @click="exportButton">导出</el-button>
	</div>
</template>

<script>
    import { mapActions } from 'vuex'
	export default {
		data(){
			return{
				form: {
					date: [],
					createTimeStart: '',
					createTimeEnd: '',
					goodsClassify: '回收品类型',
					name: '',
					turnState: 0,
				},
				optionsClassify: this.$store.state.storageCheck.storageClassifyList,
				optionsState: [
					{
				        value:  0,
				        label: '订单上缴状态'
				    },
					{
					    value: 1,
					    label: '待确认'
					},
					{
					    value: 2,
					    label: '已确认'
					}
				],
			}
		},
		watch: {
			"form.date"(newValue, oldValue) {
      if (newValue != null) {
        newValue.forEach((item, index) => {
          if (index === 0) {
            this.form.createTimeStart = item + " 00:00:00";
          } else {
            this.form.createTimeEnd = item + " 23:59:59";
          }
        });
      } else {
        this.form.createTimeStart = "";
        this.form.createTimeEnd = "";
      }
    },
		},
		mounted(){
			this.getstorageClassifyList();
		},
		methods: {
            ...mapActions(['getstorageClassifyList']),
			searchQuery(){
				if(this.form.goodsClassify == '回收品类型'){
					this.form.goodsClassify = ''
				}
				this.$emit('changeStorageCheckIndex',this.form)
			},
			searchReset(){
				this.form = {
					date: [],
					createTimeStart: '',
					createTimeEnd: '',
					goodsClassify: '回收品类型',
					name: '',
					turnState: 0,
				}
				let form = {
					date: [],
					createTimeStart: '',
					createTimeEnd: '',
					goodsClassify: '',
					name: '',
					turnState: 0,
				}
				this.$emit('changeStorageCheckIndex',form)
			},
			// 导出
			exportButton(){
				this.$confirm("是否导出订单数据?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					window.open(`/api/web/storage/recover/export?createTimeStart=${this.form.createTimeStart}&createTimeEnd=${this.form.createTimeEnd}&goodsClassify=${this.form.goodsClassify == '回收品类型' ? '' : this.form.goodsClassify}&name=${this.form.name}&turnState=${this.form.turnState}`,'_self');
				});
			}
		}
	}
</script>

<style scoped>
	.header{
		display: flex;
		justify-content: space-between;
	}
	.search{
		display: flex;
		margin-bottom: 20px;
	}
	.date-picker >>> .el-range-title{
		width: 20% !important;
		padding: 0 0 0 10px !important;
		font-size: 14px;
	}
	.date-picker >>> .el-range-separator{
		padding: 0;
	}
	.date-picker >>>.el-range-input{
		width: 30% !important;
	}
	.search > .el-range-editor.el-input__inner{
		padding: 3px 0px;
	}
	.search > .el-select{
		width: 200px;
	}
	.search > .el-input{
		width: 330px;
	}
</style>
