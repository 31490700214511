<template>
    <div class="main-content">
        <el-tabs v-model="activeName">
            <el-tab-pane label="个人信息" name="first">

                <avue-form :option="option1" v-model="form1"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-before="uploadBefore"
                   :upload-after="uploadAfter"
                   @submit="handleSubmit"
                >
                </avue-form>


            </el-tab-pane>
            <el-tab-pane label="修改密码" name="second">
                <avue-form :option="option2" v-model="form2" @submit="handleSubmit"></avue-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import { imageUrl,actionUrl } from '@/config/public';
    import { useredit, userpassword } from "../../api";
    export default {
        name: "index",
        data() {
        return {
            activeName: 'first',
            form1: {},
            form2: {},
            option1: {
                labelWidth: 120,
                column: [
                    {
                        label: '头像',
                        type: 'upload',
                        listType: 'picture-img',
                        tip: '只能上传jpg/png用户头像，且不超过500kb',
                        span: 12,
                        row: true,
                        prop: 'image',
                        loadText: '附件上传中，请稍等',
                        propsHttp:{
                            res:'data',
                            url: 'url',
                            name: 'path',
                            home: imageUrl
                        },
                        headers: {
                            Authorization: this.$store.state.user.Token
                        },
                        action: actionUrl
                    },{
                        label: '姓名',
                        span: 12,
                        row: true,
                        prop: 'fullName',
                        rules: [{
                            required: true,
                            message: "请输入姓名",
                            trigger: "blur"
                        }]
                    }, {
                        label: '手机号',
                        span: 12,
                        row: true,
                        prop: 'phone'
                    }
                ]
            },
            option2: {
                labelWidth: 120,
                column: [{
                    label: '原密码',
                    span: 12,
                    row: true,
                    type: 'password',
                    prop: 'oldPassword'
                }, {
                    label: '新密码',
                    span: 12,
                    row: true,
                    type: 'password',
                    prop: 'newPassword'
                }, {
                    label: '确认密码',
                    span: 12,
                    row: true,
                    type: 'password',
                    prop: 'newPassword1'
                }]
            }
        }
    },
    mounted() {
        this.form1 = {
            id: this.$store.state.user.userInfo.id,
            image: this.$store.state.user.userInfo.headPortrait,
            headPortrait: this.$store.state.user.userInfo.headPortrait,
            fullName: this.$store.state.user.userInfo.fullName,
            phone: this.$store.state.user.userInfo.phone
        }
    },
    methods: {
        uploadDelete() {
            return this.$confirm(`是否确定移除？`);
        },
        uploadBefore(file, done) {
            console.log(file)
            //如果你想修改file文件,由于上传的file是只读文件，必须复制新的file才可以修改名字，完后赋值到done函数里,如果不修改的话直接写done()即可
            var newFile = new File([file], file.name, { type: file.type });
            done(newFile)

            // this.$message.success('上传前的方法')
        },
        uploadError(error, column) {
            this.$message.error(error)
            // console.log(error, column)
        },
        uploadAfter(res, done) {
            done()
            // this.$message.success('上传后的方法')'
            this.$nextTick(() => {
                this.form1.image = res.image;
                this.form1.headPortrait = res.path;
            })
        },
        handleSubmit(form,done){
            let that = this;
            if(form.id){
                useredit(form).then(res => {
                    // 获取数据成功后的其他操作
                    if(res.code === 200){
                        that.$message.success('提交完成');
                        this.$store.commit("setUserInfo",form);
                        this.$root.eventHub.$emit('changeUserInfo', true)
                    }else{
                        that.$message.error(res.msg);
                    }
                    done()
                })
            }else{
                if(form.newPassword != form.newPassword1){
                    this.$message.warning('确认密码错误')
                    return done()
                }
                userpassword(form).then(res => {
                    // 获取数据成功后的其他操作
                    if(res.code === 200){
                        that.$message.success(res.msg);
                    }else{
                        this.$message.error(res.msg);
                    }
                    done()
                })
            }

        },
    }
}
</script>

<style scoped>

</style>
