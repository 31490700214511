<template>
	<div class="main-content">
		<pagesHeader title="回收订单列表" />

		<!-- 搜索栏 -->
		<Search @changeSearch="changeSearch" />

		<!-- 表格 -->
		<avue-crud :data="data" :option="option" :page.sync="pageSync" :row-style="rowStyle" @on-load="onLoad">
			<template slot-scope="{ row, index }" slot="dispatchTime">
				<div>{{ row.dispatchTime || '——' }}</div>
			</template>
      <template slot-scope="{ row, index }" slot="receiveTime">
        <div>{{ row.receiveTime || '——' }}</div>
      </template>

			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickMenu('detail', row)">订单详情</el-button>
				<el-button style="margin-left:10px;" size="small" type="text" v-if="row.dispatchState == 1 && row.state == 1" @click.stop="clickMenu('dispatch', row)">
					派单
				</el-button>
				<el-button style="margin-left:10px;" size="small" type="text" v-if="row.dispatchState == 2 && row.state == 1" @click.stop="clickMenu('dispatch', row)">
					重新派单
				</el-button>
        <el-button style="margin-left:10px;" size="small" type="text" v-if="row.dispatchState == 2 && row.state == 1" @click.stop="clickMenu('cel', row)">取消订单</el-button>
			</template>
		</avue-crud>

		<Dispatch v-if="dispatchData.type" :dispatchData="dispatchData" />
	</div>
</template>

<script>
import {orderRecoverCancel, orderRecoverList} from '@/api/order/list.js';
import Search from './search.vue';
import Dispatch from './dispatch.vue';
import { mapActions } from 'vuex';
import {orderClassifyDelete} from "@/api/order/classify";
export default {
	name: 'listIndex',
	components: {
		Search,
		Dispatch
	},
	data() {
		return {
			search: {
				data: [],
				createTimeStart: '',
				createTimeEnd: '',
				dispatchState: '',
				goodsClassify: '',
				name: '',
				state: ''
			},
			data: [],
			option: {
				stripe: false,
				border: true,
				align: 'center',
				menuAlign: 'center',
				columnBtn: false, //列显隐
				refreshBtn: false, //刷新
				updateBtn: false,
				searchMenuSpan: 6,
				addBtn: false,
				delBtn: false,
				editBtn: false,
				viewBtn: false,
				header: false,
				column: [
					{
						label: '订单编号',
						prop: 'code',
						width: 200
					},
					{
						label: '回收品类型',
						prop: 'goodsClassify',
						width: 200
					},
					{
						label: '预计上门时间',
						prop: 'doorTime',
						width: 200
					},
					{
						label: '备注',
						prop: 'remarks',
						width: 200
					},
					{
						label: '下单人昵称',
						prop: 'nickname',
						width: 200
					},
					{
						label: '下单人手机号',
						prop: 'userPhone',
						width: 200
					},
					{
						label: '联系人',
						prop: 'username',
						width: 200
					},
					{
						label: '联系方式',
						prop: 'phone',
						width: 200
					},
					{
						label: '联系地址',
						prop: 'detail',
						width: 200
					},
					{
						label: '下单时间',
						prop: 'createTime',
						width: 200
					},
          {
            label: '要求上门时间',
            prop: 'requireDoorTime',
            width: 200
          },
					{
						label: '订单状态',
						prop: 'state',
						type: 'radio',
						dicData: [
							{ label: '已下单', value: 1 },
							{ label: '已接单', value: 2 },
							{ label: '已完成', value: 3 },
							{ label: '已取消', value: 4 },
							{ label: '已接单', value: 5 }
						],
						width: 200
					},
					{
						label: '派单状态',
						prop: 'dispatchState',
						type: 'radio',
						dicData: [{ label: '未派单', value: 1 }, { label: '已派单', value: 2 }],
						width: 200
					},
					{
						label: '派单时间',
						prop: 'dispatchTime',
						width: 200,
						slot: true
					},
          {
            label: '接单时间',
            prop: 'receiveTime',
            width: 200
          },
				]
			},
			pageSync: {
				currentPage: 1,
				total: 0,
				layout: 'total,pager,prev, next',
				background: false,
				pageSize: 10
			},
			dispatchData: {
				id: '',
				type: false
			}
		};
	},
	mounted() {
		this.getUserList();

		this.$root.eventHub.$on('changeClassifyDispatch', n => {
			this.dispatchData = {
				id: '',
				type: false
			};
			if (n) {
				this.GetOrderRecoverList();
			}
		});
	},
	methods: {
		...mapActions(['getUserList']),
		clickMenu(name, row) {
			if (name === 'dispatch') {
				this.dispatchData.id = row.id;
				this.dispatchData.type = true;
			} else if (name === 'cel') {
        return this.rowDel(row.id);
      }  else {
				this.$router.push({ name: 'details', params: { id: row.id } });
			}
		},
    rowStyle({row,column,rowIndex}){
      if(row.colorState == 1){
        return {
          backgroundColor:'#f0cccc',
        }
      }
    },
    rowDel(ids) {
      this.$confirm(`是否取消订单`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: action => {
          if (action == 'confirm') {
            orderRecoverCancel(ids).then(res => {
              // console.log(res)
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.onLoad();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
		onLoad() {
			this.GetOrderRecoverList();
		},
		GetOrderRecoverList() {
			orderRecoverList({
				page: this.pageSync.currentPage,
				size: this.pageSync.pageSize,
				createTimeStart: this.search.createTimeStart, //创建时间开始
				createTimeEnd: this.search.createTimeEnd, //创建时间结束
				goodsClassify: this.search.goodsClassify, //回收品类型名称
				name: this.search.name, //下单人昵称/手机号/联系人/联系方式
				state: this.search.state ,//订单状态：1-已下单；2-已接单；3-已完成；4-已取消
				dispatchState: this.search.dispatchState, //派单状态：1-未派单；2-已派单
			}).then(res => {
				// console.log(res)
				this.data = res.data.records;
				this.pageSync.total = res.data.total;
				this.pageSync.currentPage = res.data.current;
			});
		},
		changeSearch(e) {
			this.search = e;
			this.GetOrderRecoverList();
		}
	}
};
</script>
