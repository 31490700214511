<template>
	<div class="main-content">
		<pagesHeader title="回收品统计列表" />
		<Search @changeCountGoods="changeCountGoods"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>

			<template slot="price" slot-scope="{ row }">
				<div>{{row.price + '元/' + row.unit}}</div>
			</template>

		</avue-crud>
	</div>
</template>

<script>
	import { countGoodsList } from "@/api/count/goods.js";
	import Search from "./search.vue"
	export default {
		name: 'index',
		components: {
			Search
		},
		data(){
			return{
				search: {
					classifyName: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
					menu: false,
					index: true,
					indexLabel: '序号',
					indexWidth: 100,
					column: [
						{
							label: '分类名称',
							prop: 'classifyName'
						},
						{
							label: '计量单位',
							prop: 'price'
						},
						{
							label: '回收总数',
							prop: 'num'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				},
			}
		},
		methods: {
			onLoad(){
				this.GetCountGoodsList();
			},
			GetCountGoodsList(){
				countGoodsList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					classifyName: this.search.classifyName,  //分类名称
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			changeCountGoods(e){
				this.search = e;
				this.GetCountGoodsList();
			}
		}
	}
</script>

<style scoped>
</style>
