<template>
	<div class="main-content">
		<pagesHeader :title="`${id == 0 ? '新建':'编辑'}合作单位`" />
		<avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-before="uploadBefore" :upload-delete="uploadDelete"
			:upload-after="uploadAfter">
			<template slot-scope="scope" slot="menuForm">
				<div class="submit-button">
					<el-button size="medium" @click="$router.go(-1)">取消</el-button>
					<el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
				</div>
			</template>

			<template slot="sort">
				<el-input v-model="form.sort" autocomplete="off" type="text" maxlength="8"
					oninput="this.value = this.value.replace(/[^0-9]/g, '');" />
			</template>
		</avue-form>
	</div>
</template>

<script>
	import {
		pageCompanyAdd,
		pageCompanyView,
		pageCompanyUpdate
	} from '@/api/page/company';
	import {
		imageUrl,
		actionUrl
	} from '@/config/public';
	const name = '';
	export default {
		name: 'Add',
		data() {
			return {
        id: this.$route.params.id || 0,
				dicData: [],
				form: {},
				option: {
					submitBtn: false,
					emptyBtn: false,
					labelWidth: 120,
					column: [
            {
              label: '合作单位名称',
              prop: 'name',
              span: 15,
              maxlength: 6,
              placeholder:'最多输入6个字',
              rules: [{
                required: true,
                message: '请输入合作单位名称',
                trigger: 'blur'
              }]
            },
              {
							label: '合作单位图片',
							type: 'upload',
							prop: 'image',
							listType: 'picture-img',
							accept: 'image/png, image/jpeg',
							loadText: '附件上传中，请稍等',
              fileSize: 1024 * 20,
							span: 24,
							tip: '仅一张，图片不大于20M',
							propsHttp: {
								res: 'data',
								url: 'url',
								name: 'path',
								home: imageUrl
							},
							headers: {
								Authorization: this.$store.state.user.Token
							},
							action: actionUrl,
							rules: [{
								required: true,
								message: '请上传合作单位图片',
								trigger: 'blur'
							}]
						},
						{
							label: '排序值',
							span: 15,
							prop: 'sort',
							slot: true,
							rules: [{
								required: true,
								message: '请输入排序值',
								trigger: 'blur'
							}]
						}
					]
				}
			};
		},
		mounted() {
			console.log(this.$route.params.id);
			if (this.$route.params.id != 0) {
				this.GetPageCompanyView(this.$route.params.id);
			}
			this.GET_pageGoodsList();
		},
		methods: {
			handleSubmit() {
				this.$refs.form.validate((vaild, done) => {
					if (vaild) {
						if (this.$route.params.id == 0) {
							this.GetPageCompanyAdd(done);
						} else {
							this.GetPageCompanyUpdate(done);
						}
					}
				});
			},
			GetPageCompanyAdd(done) {
				pageCompanyAdd({
						name: this.form.name,
						image: this.form.image,
						sort: this.form.sort,
					})
					.then(res => {
						// console.log(res)
						if (res.code === 200) {
							this.$message.success('添加完成');
							this.$router.go(-1);
						} else {
							this.$message.error(res.msg);
						}
						done();
					})
					.catch(err => {
						this.$message.error(err.msg);
						done();
					});
			},
			GetPageCompanyUpdate(done) {
				pageCompanyUpdate({
						id: this.form.id,
            name: this.form.name,
            image: this.form.image,
            sort: this.form.sort,
					}).then(res => {
						// console.log(res)
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.$router.go(-1);
						} else {
							this.$message.error(res.msg);
						}
						done();
					})
					.catch(err => {
						this.$message.error(err.msg);
						done();
					});
			},
      uploadBefore(file, done, loading,column) {
        if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
          this.$message.error('文件格式有误');
          loading()
        }else {
          done();
        }
      },
			uploadDelete() {
				return this.$confirm(`是否确定移除？`);
			},
			uploadError(error, column) {
				this.$message.error(error);
				// console.log(error, column)
			},
			uploadAfter(res, done) {
				done();
				this.$nextTick(() => {
					this.form.image = res.path;
				});
			},
			GetPageCompanyView(id) {
				pageCompanyView(id).then(res => {
					console.log(res);
					if (res.code === 200) {
						this.form = res.data;
					}
				});
			}
		}
	};
</script>

<style scoped>
	.submit-button {
		padding: 50px 0;
	}

	.submit-button .el-button {
		padding: 10px 50px;
	}

	.avue-form>>>.el-input__suffix {
		display: none;
	}
</style>
