<template>
	<div class="main-content">
		<pagesHeader title="用户列表" />
		<Search  @changeUserList="changeUserList"/>
		<Presentation :presentation="presentation"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>

		<template slot="menu" slot-scope="{ row }">
			<el-button style="margin-left:10px;" size="small" type="text" @click.stop="addView(row)">用户地址</el-button>
			<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickState(row)">{{row.state == 1 ? '禁用':'启用'}}</el-button>
      <el-button style="margin-left:10px;" size="small" type="text" v-if="row.balance == 0 && row.integral == 0" @click.stop="clickMenu('edit', row)">切换角色</el-button>
      <el-button style="margin-left:10px;color: #DCDFE9" size="small" type="text" v-else>切换角色</el-button>
    </template>

		</avue-crud>
    <ClassifyIndex v-if="componentsData.type" :componentsData="componentsData" />
	</div>
</template>

<script>
	import Search from "./search.vue"
	import Presentation from "./presentation.vue"
	import { UserList,UserCount,UserStateUpdate,Userdelete,userView } from "@/api/user/list.js"
  import ClassifyIndex from '@/components/user/user/index.vue';
	export default {
		name: 'index',
		components:{
			Search,
			Presentation,
      ClassifyIndex
		},
		data(){
			return{
        componentsData: {
          title: '',
          state: '',
          type: false,
          data: {}
        },
				search: {
					createTimeStart: '',
					createTimeEnd: '',
					name: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
					column: [
						{
							label: '用户ID',
							prop: 'id'
						},
						{
						    label: '用户头像',
						    prop: 'avatarUrl',
						    type: 'upload',
						    // propsHttp:{
						    //     home: imageUrl
						    // }
						},
						{
							label: '用户昵称',
							prop: 'nickname'
						},
						{
							label: '手机号',
							prop: 'phone'
						},
						{
							label: '累计积分数',
							prop: 'integral'
						},
						{
							label: '账户余额',
							prop: 'balance'
						},
						{
							label: '已提现余额',
							prop: 'withdraw'
						},
						{
							label: '注册时间',
							prop: 'createTime'
						},
						{
							label: '账号状态',
							prop: 'state',
							type: 'radio',
							dicData: [{label: '正常',value: 1},{label: '禁用',value: 2}]
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				},
				presentation: {}
			}
		},
    mounted() {
      this.$root.eventHub.$on('changeUserIndex', (n)=>{
        this.componentsData =  {
          title: '',
          state: '',
          type: false,
          data: {}
        };
        if(n){
          this.GetUserList();
        }
      })
    },
		methods: {
			addView(row){
				this.$router.push({name:'userAddress',params: { id: row.userId }})
			},
			clickState(row){
				UserStateUpdate({
					id: row.userId,
					state: row.state == 1 ? 2 : 1
				}).then(res => {
					// console.log(res)
					if(res.code === 200){
						this.GetUserList();
						this.$message.success(row.state == 1 ? '已禁用' : '已启用')
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			onLoad(){
				this.GetUserList();
				this.GetUserCount();
			},
			GetUserList(){
				UserList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //创建时间开始
					createTimeEnd: this.search.createTimeEnd, //创建时间结束
					name: this.search.name,  //id/昵称/手机号
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			GetUserCount(){
				UserCount({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //创建时间开始
					createTimeEnd: this.search.createTimeEnd, //创建时间结束
					name: this.search.name,  //id/昵称/手机号
				}).then(res => {
					// console.log(res)
					if(res.code === 200){
						this.presentation = res.data
					}
				})
			},
      clickMenu(name, form) {
        if (name === 'add') {
          this.componentsData = {
            title: '新增分类',
            state: 'add',
            type: true
          }
        } else if (name === 'cel') {
          return this.rowDel(form.id);
        } else {
          return this.getOrderClassifyView(form);
        }
      },
			changeUserList(e){
				this.search = e;
				this.GetUserList();
			},
      getOrderClassifyView(form) {
        userView(form.userId).then(res => {
          this.componentsData = {
            title: '切换成回收人员',
            state: 'edit',
            type: true,
            data: res.data
          }
        });
      },
			userDelete(row){
                Userdelete(row.userId).then(res => {
					// console.log(res)
					if(res.code === 200){
                        this.$message.success('删除成功')
                        this.GetUserList();
					}
				})
            }
		}
	}
</script>

<style scoped>
</style>
