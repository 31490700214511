var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('pagesHeader',{attrs:{"title":"回收订单列表"}}),_c('Search',{on:{"changeSearch":_vm.changeSearch}}),_c('avue-crud',{attrs:{"data":_vm.data,"option":_vm.option,"page":_vm.pageSync,"row-style":_vm.rowStyle},on:{"update:page":function($event){_vm.pageSync=$event},"on-load":_vm.onLoad},scopedSlots:_vm._u([{key:"dispatchTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(row.dispatchTime || '——'))])]}},{key:"receiveTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(row.receiveTime || '——'))])]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('detail', row)}}},[_vm._v("订单详情")]),(row.dispatchState == 1 && row.state == 1)?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('dispatch', row)}}},[_vm._v(" 派单 ")]):_vm._e(),(row.dispatchState == 2 && row.state == 1)?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('dispatch', row)}}},[_vm._v(" 重新派单 ")]):_vm._e(),(row.dispatchState == 2 && row.state == 1)?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('cel', row)}}},[_vm._v("取消订单")]):_vm._e()]}}])}),(_vm.dispatchData.type)?_c('Dispatch',{attrs:{"dispatchData":_vm.dispatchData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }