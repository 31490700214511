import { storageRecoverClassifyList } from "@/api/storage/check.js";

const storageCheck = {
    state: {
        storageClassifyList: []
    },
    mutations: {
        setstorageClassifyList(state,info) {
            state.storageClassifyList = info;
        }
    },
    actions: {
        async getstorageClassifyList({commit}) {
            const result = await storageRecoverClassifyList().then(res => {
                return res.data;
            });
            commit('setstorageClassifyList', result);
        }
    }

}
export default storageCheck