<template>
	<el-dialog title="发货"
		width="40%"
		v-dialogdrag
		:visible.sync="delivery.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form">
		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { shopOrderDeliver } from "@/api/shop/order.js"
	export default {
		props: {
			delivery: Object
		},
		data(){
			return{
				disabled: false,
				form: {
					id: this.delivery.id
				},
				option: {
					labelWidth: 120,
					submitBtn: false,
					emptyBtn: false,
					column: [
						{
							label:'快递公司',
							prop:'logisticsName',
							type: 'input',
							span: 20,
							rules: [{
								required: true,
								message: "请输入快递公司",
								trigger: "blur"
							}]
						},
						{
							label:'快递单号',
							prop:'logisticsNumber',
							type: 'input',
							span: 20,
							rules: [{
								required: true,
								message: "请输入快递单号",
								trigger: "blur"
							}]
						}
					]
				}
			}
		},
		methods: {
			handleSubmit(form){
				this.$refs.form.validate((vaild,done)=>{
				    if(vaild){
						this.disabled = true;
				        shopOrderDeliver({
							id: form.id,
							logisticsName: form.logisticsName,
							logisticsNumber: form.logisticsNumber,
						}).then(res => {
							if(res.code === 200){
								this.$message.success('发货成功')
								this.$root.eventHub.$emit('changeShopOrderDelivery', true);
							}else{
								this.$message.error(res.msg)
							}
						})
				    }
				})
			},
			handleClose(){
				this.$root.eventHub.$emit('changeShopOrderDelivery', false);
			}
		}
	}
</script>

<style>
</style>
