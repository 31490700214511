import {get, post} from '../axios'

// 入库核对-列表
export const storageCheckList = params => get('/web/storage/check/list',params);

// 入库核对-物品分类-列表
export const storageRecoverClassifyList = params => get('/web/storage/recover/classify/list',params);

// 入库核对-确认上缴
export const storageRecoverTurn = params => get(`/web/storage/recover/turn/${params}`);

// 入库核对-订单详情
export const storageRecoverView = params => get(`/web/storage/recover/view/${params}`);

// 入库核对-出库
export const storageCheckAdd = params => post('/web/storage/check/add',params);

// 配置订单-导出
// export const storageExport = params => get('/web/storage/export',params);s