import { get, put, Delete } from '../axios'

// 用户-列表
export const UserList = params => get('/web/user/list',params);

// 用户-统计
export const UserCount = params => get('/web/user/count',params);

// 用户-地址-列表
export const UserAddressList = params => get('/web/user/address/list',params);

// 用户-地址-查看
export const UserAddressView = params => get(`/web/user/address/${params}`);

// 用户-地址-修改
export const UserAddressUpdate = params => put('/web/user/address/update',params);

// 用户-默认地址-修改
export const UserDefaultUpdate = params => put('/web/user/address/default/update',params);

// 用户-状态-修改
export const UserStateUpdate = params => put('/web/user/state/update',params);

// 用户-删除
export const Userdelete = params => Delete(`/web/user/delete/${params}`);

// 用户-角色切换
export const userTypeUpdate = params => put(`/web/user/type/update`,params);

// 用户-查看
export const userView = params => get(`/web/user/detail/${params}`);
