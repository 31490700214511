import {get,put} from '../axios'

// 提现申请-列表
export const recoverWithdrawList = params => get('/web/recover/withdraw/list',params);

// 提现申请-导出
export const recoverWithdrawExport = params => get('/web/recover/withdraw/export',params);

// 提现申请-批量通过/驳回
export const recoverWithdrawExamineUpdate = params => put(`/web/recover/withdraw/state/update`,params);
