import { shopGoodsClassifyList } from "@/api/shop/goods.js";

const ShopGoods = {
    state: {
        shopGoodsClassifyList: []
    },
    mutations: {
        SetShopGoodsClassifyList(state,info) {
            state.shopGoodsClassifyList = info;
        }
    },
    actions: {
        async GetShopGoodsClassifyList({commit}) {
            const result = await shopGoodsClassifyList().then(res => {
                return res.data;
            });
            commit('SetShopGoodsClassifyList', result);
        }
    }

}
export default ShopGoods