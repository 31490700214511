<template>
  <div className="main-content">
    <div style="font-size: 30px; font-weight: bold;text-align: center">欢迎光临环保回收后台系统</div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {}
  },
}
</script>

<style scoped>
.avue-data-panel >>> .item {
  margin-bottom: 20px;
}

.avue-data-panel >>> .iconfont {
  font-size: 30px;
}
</style>
