<template>
	<div class="search">
		<el-date-picker
			v-model="form.date"
			type="daterange"
			range-title="下单时间"
			range-separator="~"
			start-placeholder="开始日期"
			end-placeholder="结束日期"
			class="date-picker"
			format="yyyy-MM-dd"
			value-format="yyyy-MM-dd"
			style="margin-right: 10px;"
		></el-date-picker>
		<el-select v-model="form.afterSalesType" placeholder="售后类型" style="margin-right: 10px;">
			<el-option v-for="item in optionsAfterSalesType" :key="item.value" :label="item.label" :value="item.value"></el-option>
		</el-select>
		<el-select v-model="form.orderAfterSalesState" placeholder="退款状态" style="margin-right: 10px;">
			<el-option v-for="item in optionsOrderAfterSalesState" :key="item.value" :label="item.label" :value="item.value"></el-option>
		</el-select>
		<el-input v-model="form.code" placeholder="输入订单编号/手机号" style="margin-right: 10px;"></el-input>
		<el-button @click="searchQuery">搜索</el-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			form: {
				date: [],
				commitTimeStart: '', //下单时间开始
				commitTimeEnd: '', //下单时间开始
				afterSalesType: '', //售后类型：1-仅退款；2-退货退款
				orderAfterSalesState: '', //订单售后状态：2-申请中；4-等待卖家收货；5-同意退款；6-拒绝
				code: ''
			},
			optionsAfterSalesType: [
				{
					value: 1,
					label: '仅退款'
				},
				{
					value: 2,
					label: '退货退款'
				}
			],
			optionsOrderAfterSalesState: [
				{
					value: 2,
					label: '申请中'
				},
				{
					value: 5,
					label: '同意退款'
				},
				{
					value: 6,
					label: '拒绝'
				},
				{
					value: 4,
					label: '等待卖家收货'
				}
			]
		};
	},
	watch: {
		'form.date'(newValue, oldValue) {
      if(newValue != null){
        newValue.forEach((item,index) =>{
          if(index === 0){
            this.form.commitTimeStart = item+' 00:00:00'
          }else{
            this.form.commitTimeEnd = item+' 23:59:59'
          }
        })
      }else{
        this.form.commitTimeStart = '';
        this.form.commitTimeEnd = '';
      }
		}
	},
	methods: {
		searchQuery() {
			this.$emit('changeShopSalesSearch', this.form);
		}
	}
};
</script>

<style scoped>
.search {
	display: flex;
	margin-bottom: 20px;
}
.date-picker >>> .el-range-title {
	width: 20% !important;
	padding: 0 0 0 10px !important;
	font-size: 14px;
}
.date-picker >>> .el-range-separator {
	padding: 0;
}
.date-picker >>> .el-range-input {
	width: 30% !important;
}
.search > .el-range-editor.el-input__inner {
	padding: 3px 0px;
}
.search > .el-select {
	width: 200px;
}
.search > .el-input {
	width: 300px;
}
</style>
