import {get, post, put,Delete} from '../axios'

// 回收人员列表-列表
export const recoverUserList = params => get('/web/recover/user/list',params);

// 回收人员列表-新增
export const recoverUserAdd = params => post('/web/recover/user/add',params);

// 回收人员列表-删除
export const recoverUserDelete = params => Delete(`/web/recover/user/delete/${params}`,);

// 回收人员列表-查看
export const recoverUserView = params => get(`/web/recover/user/view/${params}`);

// 回收人员列表-修改
export const recoverUserUpdate = params => put('/web/recover/user/update',params);

// 回收人员列表-状态修改
export const recoverUserStateUpdate = params => put('/web/recover/user/state/update',params);

// 回收人员列表-角色切换
export const recoverUserTypeUpdate = params => get(`/web/recover/user/type/update/${params}`,);
