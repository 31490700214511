<template>
	<div class="main-content">
		<pagesHeader title="商品管理" />
		<Search @changeShopGoodsSearch="changeShopGoodsSearch"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
			<template slot-scope="{ row, index }" slot="state">
				<el-switch v-model="row.state" @change="switchChange($event,row.id,index)"></el-switch>
			</template>
		
			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;"
					size="small" type="text"
					@click.stop="clickEdit(row)">编辑</el-button>
				<el-button style="margin-left:10px;"
					size="small" type="text" 
					@click.stop="clickCel(row)">删除</el-button>
			</template>
		</avue-crud>
	</div>
</template>

<script>
import Search from "./search.vue"
import { shopGoodsList,shopGoodsDelete,shopGoodsStateUpdate } from "@/api/shop/goods.js"
	export default {
		name: 'index',
		components:{
			Search
		},
		data(){
			return{
				search: {
					classifyId: '',
					name: '',
					state: 0,
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
					column: [
						{
							label: '商品编码',
							prop: 'code'
						},
						{
							label: '商品分类',
							prop: 'classifyName'
						},
						{
							label: '商品名称',
							prop: 'name'
						},
						{
							label: '销量',
							prop: 'volume'
						},
						{
							label: '上架状态',
							prop: 'state',
							slot: true
						},
						{
							label: '创建时间',
							prop: 'createTime'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				}
			}
		},
		methods: {
			clickEdit(row){
				this.$router.push({ name: 'shopGoodsDetail',params: { id: row.id } })
			},
			clickCel(row){
				this.$confirm("是否删除数据?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					shopGoodsDelete(row.id).then(res => {
						if(res.code === 200){
							this.$message.success('删除成功');
							this.GetShopGoodsList();
						}else{
							this.$message.error(res.msg)
						}
					})
				});
			},
			switchChange(e,id){
				// console.log(e,id)
				shopGoodsStateUpdate({
					id: id,
					state: e ? 1 : 2
				}).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功')
						this.GetShopGoodsList();
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			onLoad(){
				this.GetShopGoodsList();
			},
			GetShopGoodsList(){
				shopGoodsList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					classifyId: this.search.classifyId, //分类id
					name: this.search.name, //编号或名称
					state: this.search.state, //状态：1-上架；2-下架
				}).then(res => {
					if(res.code === 200){
						res.data.records.forEach(item => {
							item.state = item.state == 1 ? true : false
						})
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			changeShopGoodsSearch(e){
				this.search = e;
				this.GetShopGoodsList();
			}
		}
	}
</script>

<style scoped>
</style>
