<template>
    <div class="main-content">
        <pagesHeader title="其他管理" />
        <avue-form :option="option" v-model="form" ref="form">
            <template slot="title1">
                <label>用户取消订单设置</label>
            </template>
            <template slot="minute">
                <el-input v-model="form.minute"
                          autocomplete="off"
                          type="text"
                          placeholder="请输入正整数"
                          maxlength="8"
                          onkeyup="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                          @blur="salaryChange($event,'num')"/>
                <span>分钟，取消订单。</span>
            </template>
          <template slot="title2">
            <label>提现佣金比设置</label>
          </template>
          <template slot="withdraw">
            <el-input v-model="form.withdraw"
                      autocomplete="off"
                      type="text"
                      placeholder="请输入正整数"
                      maxlength="8"
                      onkeyup="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                      @blur="salaryChange($event,'num')">
              <template slot="append">%</template>
            </el-input>
          </template>
            <template slot="menuForm">
                <el-button type="primary" size="medium" @click="handleSubmit(form)">保存</el-button>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from './column'
    import { manageView,manageUpdate } from '@/api/other/manage';
    export default {
        name: "manage",
        data(){
            return{
                form: {
                    num: ''
                },
                option: {
                    labelWidth: 200,
                    submitBtn: false,
                    emptyBtn: false,
                    column: column.column
                },
            }
        },
        watch:{
            'form.minute'(value){
                // console.log(value)
                if(value > 0){
                    this.form.minute = value
                }else{
                    this.form.minute = ''
                }
            },
          'form.withdraw'(value){
            // console.log(value)
            if(value > 0){
              this.form.withdraw = value
            }else{
              this.form.withdraw = ''
            }
          },
        },
        mounted() {
            this.GET_manageView();
        },
        methods:{
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            GET_manageView(){
                manageView().then(res=>{
                    if(res.code===200){
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            handleSubmit(form){
                // console.log('立即生效',form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        this.GET_manageUpdate(form,done);
                    }
                })
            },
            GET_manageUpdate(form,done){
                manageUpdate({
                    id: form.id,
                  minute: form.minute,
                  withdraw: form.withdraw
                }).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done()
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done()
                })
            }
        }
    }
</script>

<style scoped>
    label{
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    }
    .tips{
        font-family: 'PingFang-SC-ExtraLight', 'PingFang SC ExtraLight', 'PingFang SC', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #F59A23;
    }
    .el-input{
        width: 400px;
        margin-right: 10px;
    }
    .el-button{
        padding: 10px 30px;
        margin-left: 300px !important;
    }
    /deep/ .avue-form__menu--center{
        text-align: left;
    }
</style>
