import {get, post, put,Delete} from '../axios'

// 轮播图管理-列表
export const pageBannerList = params => get('/web/page/banner/list',params);

// 轮播图管理-新增
export const pageBannerAdd = params => post('/web/page/banner/add',params);

// 轮播图管理-删除
export const pageBannerDelete = params => Delete(`/web/page/banner/delete/${params}`,);

// 轮播图管理-查看
export const pageBannerView = params => get(`/web/page/banner/view/${params}`);

// 轮播图管理-修改
export const pageBannerUpdate = params => put('/web/page/banner/update',params);

// 轮播图管理-显示修改
export const pageBannerShowUpdate = params => put('/web/page/banner/show/update',params);

// 商品管理-列表
export const pageGoodsList = params => get('/web/page/goods/list',params);