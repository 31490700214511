const column = {
    column: [
        {
            label: '',
            prop: 'title1',
            span: 24,
            labelWidth: 0
        },
        {
            label:'用户可于预约上门时间前',
            prop:'minute',
            span: 24,
            rules: [{
                required: true,
                message: "请输入上门时间",
                trigger: "blur"
            }]
        },{
            label: '',
            prop: 'title2',
            span: 24,
            labelWidth: 0
        },
        {
            label:'回收人员提现佣金比设置',
            prop:'withdraw',
            span: 24,
            rules: [{
                required: true,
                message: "请输入佣金比",
                trigger: "blur"
            }]
        }
    ]
}
export default column
