<template>
	<avue-crud ref="crud"
		:data="data"
		:option="option"
		v-if="data.length > 0 && show">
		<template slot="image" slot-scope="{ row,index }">
			<div class="upload">
				<input class="uploadImage" type="file" @change="tirggerFile($event,index)"/>
				<img v-if="row.image" :src="row.image" alt="" />
				<p v-else>上传图片</p>
			</div>
		</template>
		<template slot-scope="{ row, index }" slot="skuCode">
			<input type="text" v-model="row.skuCode" @input="priceInput" maxlength="10"/>
		</template>
		<template slot-scope="{ row, index }" slot="skuName">
			<input type="text" v-model="row.skuName" @input="priceInput" maxlength="10"/>
		</template>
		<template slot-scope="{ row, index }" slot="points">
<!--			<input type="text" v-model="row.points" @input="priceInput"/>-->
			<input
					type="text"
					v-model="row.points"
					min="1"
					pattern="[0-9]"
					@input="priceInput"
					maxlength="6"
					onkeyup="value=value.replace(/[\W]/g,'')"
					onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
			/>
		</template>
		<template slot-scope="{ row, index }" slot="stock">
			<!-- <input type="number" v-model="row.stock" @input="priceInput"/> -->
			<input
				type="text"
				v-model="row.stock"
				min="1"
				pattern="[0-9]"
				@input="priceInput"
				maxlength="6"
				onkeyup="value=value.replace(/[\W]/g,'')"
				onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
			/>
		</template>
	</avue-crud>
</template>

<script>
	import axios from 'axios';
  const tableHeader = [
    {
      index: 1000,
      label: '规格图片',
      prop: 'image',
      slot: true
    },
    {
      index: 1001,
      label: 'SKU编码',
      prop: 'skuCode',
      slot: true
    },
    {
      index: 1002,
      label: 'SKU名称',
      prop: 'skuName',
      slot: true
    }
  ];
	export default {
		props: {
			formSpecs: Array,
			formSpecsList: Array
		},
		data(){
			return{
        show: false,
				data: [],
				option: {
					align: 'center',
					menuAlign: 'center',
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					header: false,
					menu: false,
					border: true,
					column: []
				},
				setStorageArray: []
			}
		},
		mounted() {
			this.$root.eventHub.$on('changeShopGoodsAdditionSpecList', (n)=>{
			    // console.log(11111111,n)
        this.show = false;
        this.option.column = [];
				if(n.length == 1){
					this.columnOne(n)
				}else if(n.length > 1){
					this.columnTwo(n)
				}else{
					this.data = [];
				}
				this.optionColumn(n,false)
			})
			this.optionColumn(this.formSpecs,true)
		},
		methods: {
			specShow(type){
				// console.log('this.formSpecs',this.formSpecs)
				// console.log('this.formSpecsList',this.formSpecsList)
				if(this.formSpecs.length == 1){
					let result = [];
					for(let a = 0; a < this.formSpecs.length; a++){
						for(let b = 0; b < this.formSpecs[a].value.length; b++){
							result.push({
								name: this.formSpecs[a].value[b].content,
								specs0: this.formSpecs[a].value[b].content,
							})
						}
					}
					this.data = this.formSpecsList.map((key,index) => { return {...key,...result[index]}})
					// console.log(this.data)
				}else if(this.formSpecs.length > 1){
					var newArray = [];
					for(let item of this.formSpecs){
						newArray.push(item.value)
					}
					var result = newArray.reduce((last, current) => {
						const array = [];
						last.forEach(par1 => {
							current.forEach(par2 => {
								array.push({name:(par1.content || par1.name) + "," + par2.content});
							});
						});
						return array;
					});
					for(var i =0;i < result.length;i++){
						let resultArray = result[i].name.split(',');
						for(var a = 0;a < this.formSpecs.length;a++){
							let label = `specs${a}`;
							result[i][label] = resultArray[a];
						}
					}
					this.data = this.formSpecsList.map((key,index) => { return {...key,...result[index]}})
					// console.log(this.data)
				}
				if(type){
					this.setStorageArray = this.data;
					console.log('this.setStorageArray',this.setStorageArray)
				}
			},
			testing(data){
				// console.log('data',data)
				data.forEach((itemA,indexA) => {
					this.setStorageArray.forEach((itemB,indexB) =>{
						if(itemA.name == itemB.name){
							// console.log('itemA',itemA.name,indexA)
							// console.log('itemB',itemB.name,indexB)
							this.data[indexA] = itemB
						}
					})
				})
				this.$root.eventHub.$emit('changeShopGoodsformSpecList', this.data);
			},
			priceInput(){
				this.setStorageArray = this.data;
				this.$root.eventHub.$emit('changeShopGoodsformSpecList', this.data);
			},
			columnOne(n){
				let result = [];
				for(let a = 0; a < n.length; a++){
					for(let b = 0; b < n[a].value.length; b++){
						result.push({
							name: n[a].value[b].content,
							specs0: n[a].value[b].content,
							image: '',
							skuCode: '',
							skuName: '',
							points: '',
							stock: ''
						})
					}
				}
				this.$nextTick(()=>{
					this.data = result;
					this.testing(this.data)
				})
			},
			columnTwo(n){
				var newArray = [];
				for(let item of n){
					newArray.push(item.value)
				}
				var result = newArray.reduce((last, current) => {
					const array = [];
					last.forEach(par1 => {
						current.forEach(par2 => {
							array.push({name:(par1.content || par1.name) + "," + par2.content});
						});
					});
					return array;
				});
				for(var i =0;i < result.length;i++){
					let resultArray = result[i].name.split(',');
					for(var a = 0;a < n.length;a++){
						let label = `specs${a}`;
						result[i][label] = resultArray[a];
						result[i].image = '';
						result[i].skuCode = '';
						result[i].skuName = '';
						result[i].points = '';
						result[i].stock = '';
					}
				}
				// console.log(result)
				this.$nextTick(()=>{
					this.data = result;
					this.testing(this.data)
				})
			},
			optionColumn(n,type){
				let columnArray = []
				for(let i = 0;i < n.length;i++){
					let label = `specs${i}`;
          columnArray.push({label: n[i].key, prop: label,index: i + 1});
          if(columnArray.length == n.length){
						columnArray.push(
							{
								label: '规格图片',
								prop: 'image',
								slot: true
							},
							{
								label: 'SKU编码',
								prop: 'skuCode',
								slot: true
							},
							{
								label: 'SKU名称',
								prop: 'skuName',
								slot: true
							},
							{
								label: '积分数',
								prop: 'points',
								slot: true
							},
							{
								label: '库存',
								prop: 'stock',
								slot: true
							}
						)
					}
				}
				// console.log(columnArray)
        console.log('columnArray',columnArray)
        // this.$nextTick(()=>{
        // })
        // let array = columnArray.concat(tableHeader)
        // console.log(array)
        this.option.column = columnArray;
        console.log(this.option.column)
        setTimeout(()=>{
          this.show = true;
        },300)
				this.specShow(type);
			},
			// 上传图片
			tirggerFile(event,index){
				// console.log(event,index)
				let self = this;
				let file = event.target.files[0]
				if(file == undefined) return;
				let param = new FormData() // 创建form对象
				param.append('file', file, file.name) // 通过append向form对象添加数据
				param.append('type', '1') // 添加form表单中其他数据
				let config = {
				  headers: {'Content-Type': 'multipart/form-data'}
				}
				axios.post('/web/tool/file/upload', param, config)
					.then(response => {
						// console.log(response)
            if(response.data.code === 200){
              self.$nextTick(()=>{
                self.data[index].image = response.data.data.path;
                self.setStorageArray = this.data;
              })
              self.$root.eventHub.$emit('changeShopGoodsformSpecList', self.data);
              self.$message.success('上传成功')
            }else{
              self.$message.error(response.data.msg)
            }
					})
			}
		}
	}
</script>

<style scoped>
.avue-crud >>> .el-table__cell .cell input {
	width: 80%;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #e6e6e6;
	padding: 0 10px;
}
.upload{
	width: 100%;
	height: 32px;
	position: relative;
	margin: 0 auto;
}
.uploadImage{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}
.upload img{
	display: block;
	width: 32px;
	height: 32px;
	margin: 0 auto;
}
.upload p{
	display: block;
	height: 32px;
	font-size: 14px;
	color: #333333;
	line-height: 32px;
	text-align: center;
	cursor: pointer;
}
</style>
