import { get, put } from '../axios'

// 会员商城售后管理-列表
export const shopSalesList = params => get('/web/shop/sales/list',params);

// 会员商城售后管理-仅退款-直接退款
export const shopSalesRefundAgree = params => get(`/web/shop/sales/refund/agree/${params}`);

// 会员商城订单管理-批量拒绝
export const shopSalesRefuse = params => put('/web/shop/sales/refuse',params);

// 会员商城售后管理-退货退款-批量通过
export const shopSalesReturnAgree = params => get(`/web/shop/sales/return/agree/${params}`);

// 会员商城售后管理-退货退款-确认收货并退款
export const shopSalesReturnReceive = params => get(`/web/shop/sales/return/receive/${params}`);