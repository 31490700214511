<template>
	<div class="main-content">
		<pagesHeader title="操作日志" />
		<Search @changeLogOperate="changeLogOperate" />
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
		</avue-crud>
	</div>
</template>

<script>
	import { logOperate } from "@/api/log/operate.js";
	import Search from './search.vue'
	export default {
		name: 'index',
		components: {
			Search
		},
		data(){
			return{
				search: {
					createTimeStart: '',
					createTimeEnd: '',
					username: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
					menu: false,
					index: true,
					indexLabel: '序号',
					indexWidth: 100,
					column: [
						{
							label: '操作内容',
							prop: 'operation'
						},
						{
							label: '操作人',
							prop: 'username'
						},
						{
							label: '操作时间',
							prop: 'createTime'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				},
			}
		},
		methods: {
			changeLogOperate(e){
				this.search = e;
				this.GetlogOperate();
			},
			onLoad(){
				this.GetlogOperate();
			},
			GetlogOperate(){
				logOperate({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //操作时间开始
					createTimeEnd: this.search.createTimeEnd, //操作时间结束
					username: this.search.username,  //操作人/内容
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			}
		}
	}
</script>

<style scoped>
</style>
