<template>
	<div class="main-content">
		<pagesHeader title="新增商品" />
		<avue-form :option="option"
			v-model="form" ref="form"
			:upload-error="uploadError"
			:upload-delete="uploadDelete"
               :upload-before="uploadBefore"
			:upload-after="uploadAfter">

			<template slot="divider">
				<el-divider></el-divider>
			</template>
			<template slot-scope="{ row, index }" slot="spec">
				<Specs v-if="show" :formSpecs="form.spec"></Specs>
				<SpecsList v-if="show" :formSpecs="form.spec" :formSpecsList="form.specList"></SpecsList>
			</template>
		</avue-form>

		<div class="submit-button">
			<el-button @click="$router.go(-1)">取消</el-button>
			<el-button @click="submit(form)">保存</el-button>
		</div>
	</div>
</template>

<script>
import { imageUrl,actionUrl } from '@/config/public';
import Specs from "./specs.vue"
import SpecsList from "./specsList.vue"
import { shopGoodsAdd,shopGoodsUpdate,shopGoodsView } from "@/api/shop/goods.js";
export default {
	name: 'detail',
	components: {
		Specs,
		SpecsList
	},
	data() {
		return {
			form: {
				state: 1,
				spec: [],
				specList: []
			},
			show: false,
			option: {
				submitBtn: false,
				emptyBtn: false,
				labelWidth: 120,
				column: [
					{
						label: '商品名称',
						span: 10,
						prop: 'name',
						maxlength: 50,
						row: true,
						rules: [
							{
								required: true,
								message: '请输入姓名',
								trigger: 'blur'
							}
						]
					},
					{
						label: '商品分类',
						span: 10,
						prop: 'classifyId',
						type: 'select',
						row: true,
						dicData: this.$store.state.shopGoods.shopGoodsClassifyList,
						props: {
							label: 'name',
							value: 'id'
						},
						rules: [
							{
								required: true,
								message: '请选择商品分类',
								trigger: 'blur'
							}
						]
					},
					{
						label: '是否上架',
						span: 10,
						prop: 'state',
						type: 'select',
						row: true,
						dicData: [{ label: '上架', value: 1 }, { label: '下架', value: 2 }],
						rules: [
							{
								required: true,
								message: '请选择上架状态',
								trigger: 'blur'
							}
						]
					},
					{
                        label: '商品封面',
                        prop: 'cover',
                        type: 'upload',
                        listType: 'picture-img',
						accept: 'image/png, image/jpeg',
            fileSize: 1024 * 20,
                        span: 10,
                        row: true,
                        loadText: '附件上传中，请稍等',
                        propsHttp:{
                            res:'data',
                            url: 'url',
                            name: 'path',
                            home: imageUrl
                        },
                        headers: {
                            Authorization: this.$store.state.user.Token
                        },
                        action: actionUrl,
					    rules: [{
					        required: true,
					        message: "请上传商品封面",
					        trigger: "blur"
					    }]
                    },
					{
					    label: 'banner',
					    prop: 'banner',
					    listType: 'picture-card',
					    dataType: 'string',
					    type: 'upload',
						accept: 'image/png, image/jpeg',
            fileSize: 1024 * 20,
					    span: 24,
					    loadText: '附件上传中，请稍等',
					    propsHttp:{
					        res:'data',
					        url: 'url',
					        name: 'path',
					        home: imageUrl
					    },
					    headers: {
					        Authorization: this.$store.state.user.Token
					    },
					    action: actionUrl,
					    rules: [{
					        required: true,
					        message: "请上传banner图",
					        trigger: "blur"
					    }]
					},
					{
						label: '',
						span: 24,
						prop: 'divider',
						slot: true,
						labelWidth: 0
					},
					{
						label: '规格',
						span: 24,
						prop: 'spec',
						slot: true,
						rules: [{
						    required: true,
						    message: "请填写规格",
						    trigger: "blur"
						}]
					},
				]
			}
		};
	},
	mounted() {
		this.$root.eventHub.$on('changeShopGoodsformSepc', (n)=>{
		    this.form.spec = n
		})
		this.$root.eventHub.$on('changeShopGoodsformSpecList', (n)=>{
		    this.form.specList = n
		})
		if(this.$route.params.id == 0){
			this.show = true;
		}else{
			this.GetShopGoodsView();
		}
	},
	methods: {
		submit(form){
			this.$refs.form.validate((vaild,done)=>{
			    if(vaild){
					let type = false;
					this.form.specList.forEach((item,index) => {
						if(item.image == '' || item.skuCode == '' || item.skuName == '' || item.points == '' || item.stock == ''){
							type = true
						}
					})
					if(type){
						this.$message.error('请填写完整的规格参数');
					}else{
						if(this.$route.params.id == 0){
							this.GetShopGoodsAdd(form);
						}else{
							this.GetShopGoodsUpdate(form);
						}
					}
					done()
			    }
			})
		},
		GetShopGoodsAdd(form){
			shopGoodsAdd({
				name: form.name,
				classifyId: form.classifyId,
				state: form.state,
				cover: form.cover,
				banner: form.banner.split(',').filter(Boolean).toString(),
				spec: form.spec,
				specList: form.specList,
			}).then(res => {
				if(res.code === 200){
					this.$message.success('添加成功')
					this.$router.go(-1)
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		GetShopGoodsUpdate(form){
			shopGoodsUpdate({
				id: form.id,
				name: form.name,
				classifyId: form.classifyId,
				state: form.state,
				cover: form.cover,
				banner: form.banner.split(',').filter(Boolean).toString(),
				spec: form.spec,
				specList: form.specList,
			}).then(res => {
				if(res.code === 200){
					this.$message.success('修改成功')
					this.$router.go(-1)
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		GetShopGoodsView(){
			shopGoodsView(this.$route.params.id).then(res => {
				if(res.code === 200){
					// console.log(res.data)
					this.form = res.data;
					this.show = true;
				}
			})
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error(error)
			// console.log(error, column)
		},
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
		uploadAfter(res,done,loading,column) {
			done()
			// this.$message.success('上传后的方法')'
			this.$nextTick(() => {
			    if(column.prop == 'cover'){
					this.form.cover = res.path;
			    }else{
					if(this.form.banner  == ''){
					    this.form.banner = res.path
					}else{
					    this.form.banner += res.path + ','
					}
					console.log(this.form.banner)
			    }
			})
		}
	}
};
</script>

<style scoped>
.avue-form >>> .el-upload{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-icon-plus{
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.avue-form >>> .el-upload--picture-card{
    line-height: 100px;
}
.avue-form >>> .avue-upload__avatar{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-upload-list__item,.el-upload-list__item-actions{
    width: 100px;
    height: 100px;
}
.submit-button{
	padding: 50px 120px;
}
.submit-button > .el-button{
	padding: 12px 50px;
}
</style>
