<template>
	<div class="main-content">
		<pagesHeader title="用户地址" />
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
			:table-loading="loading"
		>
			<template slot="menuLeft">
				<el-button type="primary" size="medium" @click.stop="$router.go(-1)">返回</el-button>
			</template>
			
			<template slot-scope="{ row, index }" slot="isDefault">
				<el-switch v-model="row.isDefault" @change="switchChange($event,row.id,index)"></el-switch>
			</template>
		
			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickEdit(row)">编辑</el-button>
			</template>
		
		</avue-crud>
		
		<Edit v-if="componentsData.type" :componentsData="componentsData"/>
	</div>
</template>

<script>
	import { UserAddressList,UserDefaultUpdate,UserAddressView } from "@/api/user/list.js"
	import Edit from "./edit.vue"
	export default {
		components: {
			Edit
		},
		data(){
			return{
				loading: true,
				componentsData: {
					type: false,
					detail: {}
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
					border: true,
					index:true,
					indexLabel:'序号',
					column: [
						{
							label: '联系人',
							prop: 'username'
						},
						{
							label: '联系电话',
							prop: 'phone'
						},
						{
							label: '地址名称',
							prop: 'detail'
						},
						{
							label: '设置默认地址',
							prop: 'isDefault',
							slot: true
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				},
			}
		},
		mounted() {
			this.$root.eventHub.$on('changeUserListEdit', (n)=>{
			    this.componentsData = {
					type: false,
					detail: {}
				};
				if(n){
					this.GetUserAddressList();
				}
			})
		},
		methods: {
			clickEdit(row){
				UserAddressView(row.id).then(res => {
					if(res.code === 200){
						this.componentsData.detail = res.data;
						this.componentsData.type = true;
					}
				})
			},
			switchChange(e, id, index) {
				// console.log(111, e, id, index);
				UserDefaultUpdate({
					id: id,
					isDefault: e ? 1 : 2
				}).then(res => {
					if (res.code === 200) {
						this.data[index].isDefault = e;
						this.$message.success(res.msg);
						this.GetUserAddressList();
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err =>{
					this.$message.error(err.msg);
				})
			},
			onLoad(){
				this.GetUserAddressList();
			},
			GetUserAddressList(){
				UserAddressList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					userId: this.$route.params.id
				}).then(res => {
					// console.log(res)
					if(res.code === 200){
						this.loading = false;
						res.data.records.forEach(item => {
							item.isDefault = item.isDefault == 1 ? true : false;
						});
						// console.log(res.data.records)
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			}
		}
	}
</script>

<style scoped>
</style>
