<template>
    <el-dialog
            :title="title == 'add' ? '新增':'编辑'"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit"></avue-form>
    </el-dialog>
</template>

<script>
    import { roleAdd,roleView,roleUpdate } from "@/api/system/role";
    export default {
        name: "index",
        props: {
            title: String,
            choiceId: {
                default: '0',
                type: String
            },
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: this.title == 'add' || this.title == 'edit' ? true : false,
                    emptyBtn: this.title == 'add' ? true : false,
                    column: [
                        {
                            label:'识别码',
                            prop:'code',
                            span: 24,
                          maxlength: 20,
                          showWordLimit:true,
                            disabled: this.title == 'add' || this.title == 'edit' ? false : true,
                            rules: [{
                                required: true,
                                message: "请输入识别码",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'名称',
                            prop:'name',
                          maxlength: 20,
                          showWordLimit:true,
                            span: 24,
                            disabled: this.title == 'add' || this.title == 'edit' ? false : true,
                            rules: [{
                                required: true,
                                message: "请输入角色名称",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'状态',
                            prop:'state',
							type: 'radio',
							dicData: [{label: '启用',value: 1},{label: '禁用',value: 2}],
                            span: 24,
                            disabled: this.title == 'add' || this.title == 'edit' ? false : true,
                            rules: [{
                                required: true,
                                message: "请选择状态",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'类型',
                            prop:'remarks',
                          maxlength: 20,
                          showWordLimit:true,
                            span: 24,
                            disabled: this.title == 'add' || this.title == 'edit' ? false : true,
                            rules: [{
                                message: "请输入类型",
                                trigger: "blur"
                            }]
                        }
                    ]
                }
            }
        },
        mounted() {
            if(this.title == 'edit'){
                this.roleData();
            }
        },
        methods:{
            roleData(){
                roleView(this.choiceId).then(res => {
                    // console.log(res)
                    this.form = res.data;
                })
            },
            handleClose(){
                        this.$root.eventHub.$emit('changeRole', false);
            },
            formSubmit(form,done){
                if(this.title == 'add'){
                    return this.formSubmitRoleAdd(form,done)
                }
                if(this.title == 'edit'){
                    return this.formSubmitRoleUpdate(form,done)
                }
            },
            // 新增
            formSubmitRoleAdd(form,done){
                roleAdd({
                    code: form.code,
                    name: form.name,
                    state: form.state,
                    remarks: form.remarks,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeRole', true);
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitRoleUpdate(form,done){
                roleUpdate({
                    id: this.choiceId,
                    code: form.code,
                    name: form.name,
                    state: form.state,
                    remarks: form.remarks,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeRole', true);
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            }
        }
    }
</script>

<style scoped>

</style>
