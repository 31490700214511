<template>
	<el-dialog
		title="选择相应的订单导入快递信息"
		width="40%"
		v-dialogdrag
		:visible.sync="batchDelivery.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose"
	>
		<div class="content">
			<div class="title">已选择发货订单： {{list.length}}个</div>
			<div class="content-submit">
				<el-upload
					:headers="headers"
					:action="action"
					:show-file-list="false"
					:on-success="handleAvatarSuccess"
					:before-upload="beforeUpload"
					multiple
					type="primary"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:limit="3"
					:file-list="fileList"
				>
					<el-button size="small">导入快递信息</el-button>
				</el-upload>
				<el-button style="margin-left: 30px;" size="small" @click="clickExport">导出模板</el-button>
			</div>
		</div>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleSubmit()" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { shopOrderDeliver, shopOrderExport,shopOrderDeliverBatch } from '@/api/shop/order.js';
import { baseUrl } from '@/config/public.js';
export default {
	props: {
		batchDelivery: Object
	},
	data() {
		return {
			action: `${baseUrl}/web/shop/order/import`,
			headers: {
				Authorization: this.$store.state.user.Token
			},
			fileList: [],
			disabled: false,
			list: []
		};
	},
	methods: {
		// 文件校验
		beforeUpload(file) {
			// 文件类型
			const isType = file.type === 'application/vnd.ms-excel';
			const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			const fileType = isType || isTypeComputer;
			if (!fileType) {
				this.$message.error('上传文件只能是xls/xlsx格式！');
			} // 文件大小限制为2M
			const fileLimit = file.size / 1024 / 1024 < 2;
			if (!fileLimit) {
				this.$message.error('上传文件大小不超过2M！');
			}
			return fileType && fileLimit;
		}, 
		// 导入成功
		handleAvatarSuccess(res, file) {
			console.log(res)
			if (res.code == 200) {
				this.$message.success(res.msg);
				this.list = res.data
			} else {
				this.$message.error(res.msg);
			}
		},
		clickExport() {
			this.$confirm('是否导出模板?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				window.open(`/api/web/shop/order/export`, '_self');
			});
		},
		handleSubmit(){
			if(this.list.length == 0) return this.$message.error('请导入快递信息')
			// let type = true;
			this.list.forEach((item,index) =>{
				if(item.code == '' || item.logisticsName == '' || item.logisticsNumber == ''){
					this.list[index] = ''
				}
			})
			// if(type){
			// console.log(this.list.filter(Boolean))
				shopOrderDeliverBatch({
					batch: this.list.filter(Boolean),
				}).then(res => {
					console.log(res)
					if(res.code === 200){
						this.$message.success('发货成功');
						this.$root.eventHub.$emit('changeShopOrderBatchDelivery', true);
					}else{
						this.$message.error(res.msg);
					}
				})
			// }else{
			// 	this.$message.error('导入快递信息不全');
			// }
		},
		handleClose() {
			this.$root.eventHub.$emit('changeShopOrderBatchDelivery', false);
		}
	}
};
</script>

<style>
.content-submit {
	padding-top: 20px;
	display: flex;
	align-items: center;
}
</style>
