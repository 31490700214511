<template>
	<el-dialog title="编辑"
		width="30%"
		v-dialogdrag
		:visible.sync="componentsData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form">
		</avue-form>
		<div class="submit-button">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import { GetArea } from "@/api/index";
	import { UserAddressUpdate } from "@/api/user/list"
	export default {
		props: {
			componentsData: Object
		},
		data(){
			return {
				disabled: false,
				form: this.componentsData.detail,
				option: {
					labelWidth: 120,
					submitBtn: false,
					emptyBtn: false,
					column: [
						{
							label:'联系人',
							prop:'username',
							type: 'input',
							span: 24,
							rules: [{
								required: true,
								message: "输入联系人姓名",
								trigger: "blur"
							}]
						},
							{
							label:'联系电话',
							prop:'phone',
							type: 'input',
							span: 24,
							rules: [{
								required: true,
								message: "输入联系电话",
								trigger: "blur"
							}]
						},
							{
							label:'联系地址',
							prop: 'province',
							type: "select",
							dicData: [],
							span: 12,
							rules: [{
								required: true,
								message: "选择省份",
								trigger: "blur"
							}],
							props: {
								label: 'name',
								value: 'id'
							}
						},
						{
							label: '',
							prop:'city',
							type: "select",
							dicData: [],
							span: 6,
							rules: [{
								required: true,
								message: "选择城市",
								trigger: "blur"
							}],
							labelWidth: 0,
							props: {
								label: 'name',
								value: 'id'
							}
						},
						{
							label:'',
							prop:'county',
							type: "select",
							span: 6,
							dicData: [],
							rules: [{
								required: true,
								message: "选择县区",
								trigger: "blur"
							}],
							labelWidth: 0,
							props: {
								label: 'name',
								value: 'id'
							}
						},
						{
							label:'',
							prop:'detail',
							type: 'textarea',
							span: 24,
							rules: [{
								required: true,
								message: "输入详细地址",
								trigger: "blur"
							}]
						},
					]
				},
				province: 0,
				city: 0,
				county: 0,
			}
		},
		watch: {
			'form.province'(newValue,oldValue){
				if(newValue != ''){
					this.GetAreaCity(newValue);
				}else{
					this.form.province = '';
					this.form.city = '';
					this.form.county = '';
				}
			},
			'form.city'(newValue,oldValue){
				if(newValue != ''){
					this.GetAreaCounty(newValue);
				}else{
					this.form.city = '';
					this.form.county = '';
				}
			}
		},
		mounted() {
			this.GetAreaProvince(0);
			
		},
		methods: {
			GetAreaProvince(id){
				GetArea(id).then(res => {
					if(res.code === 200){
						const province = this.findObject(this.option.column, "province");
						province.dicData = res.data
						res.data.forEach(item => {
							if(item.name == this.form.province){
								// console.log(item.name,item.id)
								this.GetAreaCity(item.id);
							}
						})
					}
				})
			},
			GetAreaCity(id){
				GetArea(id).then(res => {
					if(res.code === 200){
						const city = this.findObject(this.option.column, "city");
						city.dicData = res.data;
						res.data.forEach(item => {
							if(item.name == this.form.city){
								// console.log(item.name,item.id)
								this.GetAreaCounty(item.id);
							}
						})
					}
				})
			},
			GetAreaCounty(id){
				GetArea(id).then(res => {
					if(res.code === 200){
						const county = this.findObject(this.option.column, "county");
						county.dicData = res.data;
					}
				})
			},
			handleSubmit(form){
				this.$refs.form.validate((vaild,done)=>{
				    if(vaild){
						console.log(form)
						UserAddressUpdate({
							id: form.id,
							username: form.username,
							phone: form.phone,
							province: form.$province,
							city: form.$city,
							county: form.$county,
							detail: form.detail,
						}).then(res =>{
							console.log(res)
							if(res.code === 200){
								this.$message.success('修改成功');
								this.$root.eventHub.$emit('changeUserListEdit', true);
							}else{
								this.$message.error(res.msg)
							}
							done()
						})
				    }
				})
			},
			handleClose(){
				this.$root.eventHub.$emit('changeUserListEdit', false);
			}
		}
	}
</script>

<style scoped>
	.avue-dialog >>> .el-dialog__body{
		margin-bottom: 0;
	}
	.submit-button{
		display: flex;
		justify-content: center;
	}
</style>
