<template>
	<div class="main-content">
		<pagesHeader title="提现申请列表" />
		<Search @changeRecoverUserSearch="changeRecoverUserSearch"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
      <template slot="money" slot-scope="{ row }">
        <div>￥{{row.money}}</div>
      </template>
			<template slot="menu" slot-scope="{ row }">
        <el-button size="small" type="text" @click="toExamine(row)" v-if="row.state == 1">审核</el-button>
			</template>

		</avue-crud>
    <ToExamine v-if="to_Examine.type" :toExamine="to_Examine"/>
	</div>
</template>

<script>
import {
  recoverWithdrawList
} from "@/api/recover/withdraw";
	import Search from "./search.vue";
  import ToExamine from './toExamine'
	export default {
		name: 'index',
		components: {
			Search,
      ToExamine
		},
		data(){
			return{
				componentsData: {
					type: false,
					state: '',
					detail: {}
				},
				search: {
					createTimeStart: '',
					createTimeEnd: '',
					name: '',
					state: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
          index: true,
          indexLabel: '序号',
          indexWidth: 100,
					column: [
						{
							label: '回收员姓名',
							prop: 'username',
						},
						{
						    label: '联系方式',
						    prop: 'contact',
						},
						{
							label: '提现金额（元）',
							prop: 'money'
						},
						{
							label: '申请时间',
							prop: 'createTime'
						},
						{
							label: '申请状态',
							prop: 'state',
							type: 'radio',
							dicData: [{label: '待审核',value: 1},{label: '审核通过',value: 2},{label: '审核驳回',value: 3}]
						},
						{
							label: '驳回原因',
							prop: 'rejectReason'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				},
        to_Examine: {
          type: false,
          id: '',
          state: 2
        },
			}
		},
		mounted(){
			this.$root.eventHub.$on('changeRecoverUserIndex', (n)=>{
			    this.componentsData.type = false;
				if(n){
					this.GetRecoverUserList();
				}
			});
      this.$root.eventHub.$on('changeToExamine', (n)=>{
        this.to_Examine.type = false;
        if(this.state == 1){
          this.state = n.state
        }
        if(n.type){
          this.GetRecoverUserList();
        }
      })

		},
		methods: {
			clickEdit(row){
				this.GetRecoverUserView(row.id);
			},
			onLoad(){
				this.GetRecoverUserList();
			},
			GetRecoverUserList(){
				recoverWithdrawList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //创建时间开始
					createTimeEnd: this.search.createTimeEnd, //创建时间结束
					name: this.search.name,  //id/姓名/登录账号
					state: this.search.state, //状态：1-启用；2-停用
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
      toExamine(row){
        // console.log(row)
        this.to_Examine = {
          type: true,
          id: row.id,
          state: 2
        }
      },
			changeRecoverUserSearch(e){
				this.search = e;
				this.GetRecoverUserList();
			}
		}
	}
</script>

<style scoped>
</style>
