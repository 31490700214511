<template>
  <div class="search">
    <el-date-picker
      v-model="form.date"
      type="daterange"
      range-title="申请时间"
      range-separator="~"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      class="date-picker"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      style="margin-right: 10px"
    >
    </el-date-picker>
    <el-input
      v-model="form.name"
      placeholder="请输入回收员姓名/联系方式"
      style="margin-right: 10px"
    ></el-input>
    <el-select
      v-model="form.state"
      placeholder="请选择"
      style="margin-right: 10px"
    >
      <el-option
        v-for="item in optionsState"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-button type="primary" @click="searchQuery">查询</el-button>
    <el-button type="info" @click="searchReset">重置</el-button>
    <el-button type="primary" style="height: 40px !important;"  @click="exportButton">导出</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: [],
        createTimeStart: "",
        createTimeEnd: "",
        name: "",
        state: "0",
      },
      optionsState: [
        {
          value: "0",
          label: "审核状态",
        },
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "审核驳回",
        },
      ],
    };
  },
  watch: {
    "form.date"(newValue, oldValue) {
      if (newValue != null) {
        newValue.forEach((item, index) => {
          if (index === 0) {
            this.form.createTimeStart = item + " 00:00:00";
          } else {
            this.form.createTimeEnd = item + " 23:59:59";
          }
        });
      } else {
        this.form.createTimeStart = "";
        this.form.createTimeEnd = "";
      }
    },
  },
  methods: {
    searchQuery() {
      this.$emit("changeRecoverUserSearch", this.form);
    },
    searchReset() {
      this.form = {
        date: [],
        createTimeStart: "",
        createTimeEnd: "",
        name: "",
        state: "0",
      };
      this.$emit("changeRecoverUserSearch", this.form);
    },
    // 导出
    exportButton(){
      this.$confirm("是否导出提现申请数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        window.open(`/api/web/recover/withdraw/export?createTimeStart=${this.form.createTimeStart}&createTimeEnd=${this.form.createTimeEnd}&name=${this.form.name}&state=${this.form.state}`,'_self');
      });
    }
  },
};
</script>

<style scoped>
.search {
  display: flex;
  margin-bottom: 20px;
}
.date-picker >>> .el-range-title {
  width: 20% !important;
  padding: 0 0 0 10px !important;
  font-size: 14px;
}
.date-picker >>> .el-range-separator {
  padding: 0;
}
.date-picker >>> .el-range-input {
  width: 30% !important;
}
.search > .el-range-editor.el-input__inner {
  padding: 3px 0px;
}
.search > .el-select {
  width: 200px;
}
.search > .el-input {
  width: 300px;
}
</style>
