import {get, post, put,Delete} from '../axios'

// 商品管理-列表
export const shopGoodsList = params => get('/web/shop/goods/list',params);

// 商品管理-新增
export const shopGoodsAdd = params => post('/web/shop/goods/add',params);

// 商品管理-删除
export const shopGoodsDelete = params => Delete(`/web/shop/goods/delete/${params}`);

// 商品管理-查看
export const shopGoodsView = params => get(`/web/shop/goods/view/${params}`);

// 商品管理-修改
export const shopGoodsUpdate = params => put('/web/shop/goods/update',params);

// 商品管理-显示修改
export const shopGoodsStateUpdate = params => put('/web/shop/goods/state/update',params);

// 商品管理-分类-列表
export const shopGoodsClassifyList = params => get('/web/shop/goods/classify/list',params);
