<template>
	<div class="main-content">
		<pagesHeader title="回收品出库列表" />
		<Search  @changeStorageRetrievalIndex="changeStorageRetrievalIndex"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
			<template slot="retrievalNum" slot-scope="{ row }">
				<div>{{row.retrievalNum + row.unit}}</div>
			</template>
			<template slot="retrievalPrice" slot-scope="{ row }">
				<div>￥{{row.retrievalPrice}}</div>
			</template>
		</avue-crud>
	</div>
</template>

<script>
	import Search from "./search.vue";
	import { storageRetrievalList } from "@/api/storage/retrieval.js"
	export default {
		name: 'index',
		components: {
			Search
		},
		data(){
			return{
				search: {
					date: [],
					createTimeStart: '',
					createTimeEnd: '',
					goodsClassify: '',
					name: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					menu: false,
					border: true,
					column: [
						{
							label: '出库订单编号',
							prop: 'code'
						},
						{
							label: '回收品类型',
							prop: 'classifyName'
						},
						{
							label: '回收物品名称',
							prop: 'name'
						},
						{
							label: '计量单位',
							prop: 'retrievalNum',
							slot: true
						},
						{
							label: '出库金额',
							prop: 'retrievalPrice',
							slot: true
						},
						{
							label: '出库时间',
							prop: 'createTime'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				}
			}
		},
		methods: {
			onLoad(){
				this.GetStorageRetrievalList();
			},
			GetStorageRetrievalList(){
				storageRetrievalList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //创建时间开始
					createTimeEnd: this.search.createTimeEnd, //创建时间结束
					goodsClassify: this.search.goodsClassify, //回收品类型名称
					name: this.search.name,  //物品名称
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			changeStorageRetrievalIndex(e){
				this.search = e;
				this.GetStorageRetrievalList();
			}
		}
	}
</script>

<style scoped>
</style>
