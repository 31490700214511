<template>
	<div class="search">
		<el-button type="primary"
		style="margin-right: 10px;"
		@click="goodsAdd">新增</el-button>
			<el-select v-model="form.classifyId"
				placeholder="商品分类"
				style="margin-right: 10px;">
				<el-option
					  v-for="item in optionsClassifyId"
					  :key="item.id"
					  :label="item.name"
					  :value="item.id">
				</el-option>
			</el-select>
			<el-select v-model="form.state"
				placeholder="上架状态"
				style="margin-right: 10px;">
				<el-option
					  v-for="item in optionsState"
					  :key="item.value"
					  :label="item.label"
					  :value="item.value">
				</el-option>
			</el-select>
			<el-input v-model="form.name"
				placeholder="输入商品编码/商品名称"
				style="margin-right: 10px;"></el-input>
			<el-button @click="searchQuery">搜索</el-button>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
	export default {
		data(){
			return{
				form: {
					classifyId: '',
					name: '',
					state: '',
				},
				optionsClassifyId: this.$store.state.shopGoods.shopGoodsClassifyList,
				optionsState: [
          {
            value: 0,
            label: '上架状态'
          },
					{
					    value: 1,
					    label: '已上架'
					},
					{
					    value: 2,
					    label: '未上架'
					}
				],
			}
		},
		mounted() {
			this.GetShopGoodsClassifyList();
		},
		methods: {
			...mapActions(['GetShopGoodsClassifyList']),
			goodsAdd(){
				this.$router.push({ name: 'shopGoodsDetail',params: { id: 0 } })
			},
			searchQuery(){
				this.$emit('changeShopGoodsSearch',this.form)
			}
		}
	}
</script>

<style scoped>
	.search{
		display: flex;
		margin-bottom: 20px;
	}
	.date-picker >>> .el-range-title{
		width: 20% !important;
		padding: 0 0 0 10px !important;
		font-size: 14px;
	}
	.date-picker >>> .el-range-separator{
		padding: 0;
	}
	.date-picker >>>.el-range-input{
		width: 30% !important;
	}
	.search > .el-range-editor.el-input__inner{
		padding: 3px 0px;
	}
	.search > .el-select{
		width: 200px;
	}
	.search > .el-input{
		width: 300px;
	}
</style>
