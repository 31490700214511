import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 首页
import index from '@/pages/index/index';



// view 内容
// index首页
import indexHome from '@/view/index/index';
// info  个人信息
import infoIndex from '@/view/info/index';

// order 订单回收管理
import orderBigClassifyIndex from '@/view/order/big/index.vue';
import orderClassifyIndex from '@/view/order/classify/index.vue';
import orderListIndex from '@/view/order/list/index.vue';
import orderListDetails from '@/view/order/list/details.vue';

// cell 回收上门时间管理
import callTimeIndex from '@/view/call/time/index.vue';

// recover 回收人员管理
import recoverUserIndex from '@/view/recover/user/index.vue';
import recoverWithdraw from '@/view/recover/withdraw/index.vue';

// storage 线下仓储
import storageCheckIndex from '@/view/storage/check/index.vue';
import storageCheckDetails from '@/view/storage/check/details.vue';
import storageRetrievalIndex from '@/view/storage/retrieval/index.vue';

// user 用户列表
import userListIndex from '@/view/user/list/index.vue';
import userListAddress from '@/view/user/list/address.vue';

// page 页面管理
import pageBannerIndex from '@/view/page/banner/index.vue';
import pageBannerAdd from '@/view/page/banner/add.vue';
import pageCompanyIndex from '@/view/page/company/index.vue';
import pageCompanyAdd from '@/view/page/company/add.vue';

// shop 会员商城
import shopPageIndex from '@/view/shop/page/index.vue';
import shopClassifyIndex from '@/view/shop/classify/index.vue';
import shopGoodsIndex from '@/view/shop/goods/index.vue';
import shopGoodsDetail from '@/view/shop/goods/detail.vue';
import shopOrderIndex from '@/view/shop/order/index.vue';
import shopOrderDetail from '@/view/shop/order/detail.vue';
import shopSalesIndex from '@/view/shop/sales/index.vue';

// integral 积分管理
import integralManege from '@/view/integral/manege/index.vue';

// count 数据统计
import countGoods from '@/view/count/goods/index.vue';

// page 操作日志
import logOperate from '@/view/log/operate/index.vue';
import otherManage from '@/view/other/manage/manage.vue';

// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';
import systemMenu from '@/view/system/menu';
import systemLog from '@/view/system/log';

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			children: [
				{
					path: '/',
					name: 'indexHome',
					component: indexHome
				},
				{
					path: '/info/index',
					name: 'infoIndex',
					component: infoIndex
				},
				// ------------------------------------------------------ 订单回收管理
				{
					path: '/order/classify',
					name: 'classify',
					component: orderClassifyIndex
				},
				{
					path: '/order/big',
					name: 'big',
					component: orderBigClassifyIndex
				},
				{
					path: '/order/list',
					name: 'list',
					component: orderListIndex
				},
				{
					path: '/order/details/:id',
					name: 'details',
					component: orderListDetails
				},
				// ------------------------------------------------------ 回收上门时间管理
				{
					path: '/call/time',
					name: 'time',
					component: callTimeIndex
				},
				// ------------------------------------------------------ 回收人员管理
				{
					path: '/recover/user',
					name: 'recoverUser',
					component: recoverUserIndex
				},
				{
					path: '/recover/withdraw',
					name: 'recoverWithdraw',
					component: recoverWithdraw
				},
				// ------------------------------------------------------ 线下仓储
				{
					path: '/storage/check',
					name: 'storageCheck',
					component: storageCheckIndex
				},
				{
					path: '/storage/check/details/:id/:type',
					name: 'storageCheckDetails',
					component: storageCheckDetails
				},
				{
					path: '/storage/retrieval',
					name: 'storageRetrieval',
					component: storageRetrievalIndex
				},
				// ------------------------------------------------------ 用户列表
				{
					path: '/user/list',
					name: 'userList',
					component: userListIndex
				},
				{
					path: '/user/list/address/:id',
					name: 'userAddress',
					component: userListAddress
				},
				// ------------------------------------------------------ 页面管理
				{
					path: '/page/banner',
					name: 'pageBanner',
					component: pageBannerIndex
				},
				{
					path: '/page/banner/add/:id',
					name: 'bannerAdd',
					component: pageBannerAdd
				},
				{
					path: '/page/company',
					name: 'pageCompany',
					component: pageCompanyIndex
				},
				{
					path: '/page/company/add/:id',
					name: 'companyAdd',
					component: pageCompanyAdd
				},
				// ------------------------------------------------------ 会员商城
				{
					path: '/shop/page',
					name: 'shopPageIndex',
					component: shopPageIndex
				},
				{
					path: '/shop/classify',
					name: 'shopClassifyIndex',
					component: shopClassifyIndex
				},
				{
					path: '/shop/goods',
					name: 'shopGoodsIndex',
					component: shopGoodsIndex
				},
				{
					path: '/shop/goods/detail/:id',
					name: 'shopGoodsDetail',
					component: shopGoodsDetail
				},
				{
					path: '/shop/order',
					name: 'shopOrderIndex',
					component: shopOrderIndex
				},
				{
					path: '/shop/order/detail/:id',
					name: 'shopOrderDetail',
					component: shopOrderDetail
				},
				{
					path: '/shop/sales',
					name: 'shopSalesIndex',
					component: shopSalesIndex
				},
				// ------------------------------------------------------ 积分管理
				{
					path: '/integral/manege',
					name: 'integralManege',
					component: integralManege
				},
				// ------------------------------------------------------ 数据统计
				{
					path: '/count/goods',
					name: 'countGoods',
					component: countGoods
				},
				// ------------------------------------------------------ 操作日志
				{
					path: '/log/operate',
					name: 'logOperate',
					component: logOperate
				},
				{
					path: '/other/manage',
					name: 'otherManage',
					component: otherManage
				},
				// ------------------------------------------------------ 系统管理
				{
					path: '/system/user',
					name: '/systemUser',
					component: systemUser
				},
				{
					path: '/system/role',
					name: '/role',
					component: systemRole
				},
				{
					path: '/system/menu',
					name: '/menu',
					component: systemMenu
				},
				{
					path: '/system/log',
					name: '/log',
					component: systemLog
				}
			]
		}
	]
})
