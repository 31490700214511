<template>
    <div class="main-content">
        <el-alert
                title="管理员日志"
                type="info"
                description="管理员可以查看自己所拥有的权限的管理员日志"
                :closable="false"
                show-icon>
        </el-alert>

        <avue-crud
                :data="data"
                :option="option"
                :page.sync="pageSync"
                @on-load="onLoad"
                @current-change="currentChange">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           type="danger"
                           :icon="item.icon"
                           size="small"
                           @click.stop="clickMenu">{{item.name}}</el-button>
            </template>
        </avue-crud>
    </div>
</template>

<script>
import { logList,logDelete } from '@/api/system/log'
export default {
    name: "journal",
    data() {
        return {
            pageSync: {
                currentPage: 1,
                total: 0,
                layout: "total,pager,prev, next",
                background:false,
                pageSize: 10,
            },
            Buttons: [],
            data: [],
            option: {
                menu:false,
                emptyText: '暂无数据',
                align: 'center',
                menuAlign: 'center',
                border:true,
                addBtn:false,
                delBtn:false,
                viewBtn: true,
                columnBtn:false,  //列显隐
                refreshBtn:false,  //刷新
                column: [
                    {
                        label: 'IP',
                        prop: 'ip'
                    },
                    {
                        label: '用户名',
                        prop: 'username'
                    },
                    {
                        label: '方法',
                        prop: 'method'
                    },
                    {
                        label: '操作名称',
                        prop: 'operation'
                    },
                    {
                        label: '参数',
                        prop: 'params'
                    },
                    {
                        label: '操作时长',
                        prop: 'time'
                    },
                    {
                        label: '创建时间',
                        prop: 'createTime'
                    }

                ]
            }
        }
    },
    mounted() {
        let that = this;
        that.Buttons = that.$store.state.index.menuSubButtons
    },
    methods: {
        clickMenu(){
            this.$confirm(`确定清空日志`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: action => {
                    if(action == 'confirm'){
                        logDelete().then(res => {
                            if(res.code === 200){
                                this.$message.success(res.msg)
                                this.list();
                            }else{
                                this.$message.error(res.msg)
                            }
                        })
                    }
                }
            })
        },
        onLoad(){
            this.list()
        },
        list(){
            logList({
                page: this.pageSync.currentPage,
                size: this.pageSync.pageSize
            }).then(res => {
                // console.log(res)
                if(res.code === 200){
                    this.data = res.data.records;
                    this.pageSync.currentPage = res.data.current;
                    this.pageSync.total = res.data.total;
                }
            })
        },
        currentChange(val) {
            this.pageSync.currentPage = val;
            // this.$message.success('页码' + val);
            this.list();
        }
    }
    }
</script>

<style scoped>
.el-alert{
    margin-bottom: 30px;
}
</style>