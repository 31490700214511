<template>
	<div class="main-content">
		<pagesHeader title="入库核对列表" />
		<Search @changeStorageCheckIndex="changeStorageCheckIndex"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;"
					size="small" type="text"
					@click.stop="clickDetail(row)">订单详情</el-button>
				<el-button style="margin-left:10px;"
					size="small" type="text" 
					v-if="row.turnState === 1"
					@click.stop="clickSubmit(row)">确认上缴</el-button>
				<el-button style="margin-left:10px;"
					size="small" type="text" 
					v-if="row.turnState === 2"
					@click.stop="clickOut(row)">出库</el-button>
			</template>
		</avue-crud>
	</div>
</template>

<script>
	import { storageCheckList,storageRecoverTurn } from "@/api/storage/check.js"
	import Search from "./search.vue";
	export default {
		name: 'index',
		components: {
			Search
		},
		data(){
			return{
				search: {
					date: [],
					createTimeStart: '',
					createTimeEnd: '',
					goodsClassify: '',
					name: '',
					turnState: 0,
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
					column: [
						{
							label: '订单编号',
							prop: 'code',
							width: 200
						},
						{
							label: '回收品类型',
							prop: 'goodsClassify',
							width: 200
						},
						{
							label: '预计上门时间',
							prop: 'doorTime',
							width: 200
						},
						{
							label: '备注',
							prop: 'remarks',
							width: 200
						},
						{
							label: '下单人昵称',
							prop: 'nickname',
							width: 200
						},
						{
							label: '下单人手机号',
							prop: 'userPhone',
							width: 200
						},
						{
							label: '回收员',
							prop: 'recoverUsername',
							width: 200
						},
						{
							label: '手机号',
							prop: 'recoverPhone',
							width: 200
						},
						{
							label: '下单时间',
							prop: 'createTime',
							width: 200
						},
						{
							label: '订单上缴状态',
							prop: 'turnState',
							type: 'radio',
							dicData: [{label: '待确认',value: 1},{label: '已确认',value: 2}],
							width: 200
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				}
			}
		},
		methods: {
			clickDetail(row){
				this.$router.push({name:'storageCheckDetails',params: { id: row.id,type: false }})
			},
			clickSubmit(row){
				storageRecoverTurn(row.id).then(res => {
					if(res.code === 200){
						this.$message.success('操作成功');
						this.GetStorageCheckList();
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			clickOut(row){
				this.$router.push({name:'storageCheckDetails',params: { id: row.id,type: true }})
			},
			onLoad(){
				this.GetStorageCheckList();
			},
			GetStorageCheckList(){
				storageCheckList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //创建时间开始
					createTimeEnd: this.search.createTimeEnd, //创建时间结束
					goodsClassify: this.search.goodsClassify, //回收品类型名称
					name: this.search.name,  //下单人昵称/手机号/回收员/回收员手机号
					turnState: this.search.turnState, //上缴状态：1-待确认；2-已确认
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			changeStorageCheckIndex(e){
				this.search = e;
				this.GetStorageCheckList();
			}
		}
	}
</script>

<style scoped>
</style>
