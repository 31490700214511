<template>
	<div class="main-content">
		<pagesHeader title="会员商城订单管理" />
		<Search @changeShopOrderSearch="changeShopOrderSearch" @changeShopOrderSearchBatchDelivery="changeShopOrderSearchBatchDelivery" />
		<avue-crud ref="crud" :data="data" :option="option" :page.sync="pageSync" @on-load="onLoad" @expand-change="expandChanges">
			<template slot="payType" slot-scope="{ row }">
				<div v-if="row.state != 1 && row.state != 5">
					{{row.payType == 1 ? '纯积分': row.payType == 2 ? '积分和现金':row.payType == 3 ? '纯现金':''}}
				</div>
				<div v-else>/</div>
			</template>
			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickView(row)">查看详情</el-button>
				<el-button style="margin-left:10px;" size="small" type="text" v-if="row.logisticsState == 2" @click.stop="clickDelivery(row)">发货</el-button>
			</template>

			<template slot="expand" slot-scope="{ row }">
				<div class="expand">
					<div>{{ row.productName }}</div>
					<div>{{ row.specName }}</div>
				</div>
			</template>
		</avue-crud>

		<Delivery v-if="delivery.type" :delivery="delivery" />
		<BatchDelivery v-if="batchDelivery.type" :batchDelivery="batchDelivery" />
	</div>
</template>

<script>
import Search from "./search.vue";
import Delivery from "./delivery.vue";
import BatchDelivery from "./batchdelivery.vue"
import { shopOrderList } from "@/api/shop/order.js"
export default {
	name: 'index',
	components: {
		Search,
		Delivery,
		BatchDelivery
	},
	data(){
		return{
			search: {
				data: [],
				commitTimeStart: '',
				commitTimeEnd: '',
				logisticsState: '',
				state: 0,
				code: ''
			},
			data: [],
			option: {
				expand: true,
				expandRowKeys:[],
				rowKey:'id',
				addBtn: false,
				delBtn: false,
				editBtn: false,
				viewBtn: false,
				align: 'center',
				menuAlign: 'center',
				header: false,
				border: true,
				column: [
					{
						label: '订单编号',
						prop: 'code'
					},
					{
						label: '下单人',
						prop: 'nickname'
					},
					{
						label: '下单时间',
						prop: 'commitTime'
					},
					{
						label: '收货人',
						prop: 'username'
					},
					{
						label: '手机号',
						prop: 'phone'
					},
					{
						label: '总金额',
						prop: 'reallyPrice'
					},
					{
						label: '订单状态',
						prop: 'state',
						type: 'radio',
						dicData: [{label: '待付款',value: 1},{label: '待发货',value: 2},{label: '待收货',value: 3},{label: '已完成',value: 4},{label: '已取消',value: 5}]
					},
					{
						label: '支付方式',
						prop: 'payType',
						// type: 'radio',
						// dicData: [{label: '纯积分',value: 1},{label: '积分和现金',value: 2},{label: '纯现金',value: 3}],
						slot: true
					},
					{
						label: '物流状态',
						prop: 'logisticsState',
						type: 'radio',
						dicData: [{label: '无需发货',value: 1},{label: '未发货',value: 2},{label: '已发货',value: 3}]
					},
					{
						label: '售后状态',
						prop: 'afterSalesState',
						type: 'radio',
						dicData: [{label: '无',value: 0},{label: '尚未退款',value: 1},{label: '全部退款',value: 2},{label: '部分退款',value: 3}]
					}
				]
			},
			pageSync: {
				currentPage: 1,
				total: 0,
				layout: 'total,pager,prev, next',
				background: false,
				pageSize: 10
			},
			delivery: {
				type: false,
				id: 0
			},
			batchDelivery:{
				type: false,
			}
		}
	},
	mounted() {
		this.$root.eventHub.$on('changeShopOrderDelivery', (n)=>{
			this.delivery =  {
				type: false,
				id: 0
			};
			if(n){
				this.GetShopOrderList();
			}
		})
		this.$root.eventHub.$on('changeShopOrderBatchDelivery', (n)=>{
			this.batchDelivery =  {
				type: false
			};
			if(n){
				this.GetShopOrderList();
			}
		})
	},
	methods: {
		clickView(row){
			this.$router.push({name: 'shopOrderDetail',params: { id: row.id }})
		},
		clickDelivery(row){
			this.delivery = {
				type: true,
				id: row.id
			}
		},
		onLoad(){
			this.GetShopOrderList();
		},
		GetShopOrderList(){
			shopOrderList({
				page: this.pageSync.currentPage,
				size: this.pageSync.pageSize,
				code: this.search.code, //订单编号/收货人/手机号
				commitTimeStart: this.search.commitTimeStart, //下单时间开始
				commitTimeEnd: this.search.commitTimeEnd, //下单时间结束
				logisticsState: this.search.logisticsState, //物流状态：1-无需发货；2-未发货；3-已发货
				state: this.search.state, //订单状态：1-待付款；2-待发货；3-待收货；4-已完成；5-已取消
			}).then(res => {
				if(res.code === 200){
					this.data = res.data.records;
					this.pageSync.total = res.data.total;
					this.pageSync.currentPage = res.data.current;
				}
			})
		},
		changeShopOrderSearch(e){
			this.search = e;
			this.GetShopOrderList();
		},
		changeShopOrderSearchBatchDelivery(){
			console.log(11);
			this.batchDelivery = {
				type: true
			}
		},
		expandChanges(row, expendList) {
			if (expendList.length) {
				this.option.expandRowKeys = []
				if (row) {
					this.option.expandRowKeys.push(row.id)
				}
			} else {
					this.option.expandRowKeys = []
			}
		}
	}
}
</script>

<style scoped>
.expand {
	padding: 0 60px;
	display: flex;
}
.expand div {
	margin-right: 20px;
}
</style>
