<template>
	<div class="main-content">
		<pagesHeader title="商品分类管理" />
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
			<template slot="menuLeft">
			    <el-button type="primary" @click.stop="clickAdd()">新增</el-button>
			</template>
			
			<template slot="menu" slot-scope="{row}">
			    <el-button type="text" @click.stop="clickEdit(row)">编辑</el-button>
			    <el-button type="text" @click.stop="clickCel(row)">删除</el-button>
			</template>
			
			<template slot-scope="{ row, index }" slot="isShow">
				<el-switch v-model="row.isShow" @change="switchChange($event,row.id)"></el-switch>
			</template>
			
		</avue-crud>
		
		<Classify v-if="componentsData.type" :componentsData="componentsData"/>
	</div>
</template>

<script>
	import { shopclassifyList,shopclassifyDelete,shopclassifyShowUpdate,shopclassifyView } from "@/api/shop/classify.js"
	import Classify from "./classify.vue"
	export default {
		name: 'index',
		components: {
			Classify
		},
		data(){
			return{
				componentsData: {
					type: false,
					data: {},
					state: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					columnBtn:false,  //列显隐
					refreshBtn:false,  //刷新
					border: true,
					index: true,
					indexLabel: '序号',
					indexWidth: 100,
					column: [
						{
							label: '分类名称',
							prop: 'name'
						},
						{
							label: '排序',
							prop: 'sort'
						},
						{
							label: '是否显示',
							prop: 'isShow'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				},
			}
		},
		mounted() {
			this.$root.eventHub.$on('changeShopClassifyIndex', (n)=>{
				this.componentsData =  {
					type: false,
					data: {},
					state: ''
				};
				if(n){
					this.GetShopclassifyList();
				}
			})
		},
		methods: {
			clickAdd(){
				this.componentsData.type = true;
				this.componentsData.state = 'add';
			},
			clickEdit(row){
				shopclassifyView(row.id).then(res => {
					if(res.code === 200){
						this.componentsData =  {
							type: true,
							data: res.data,
							state: 'edit'
						};
					}
				})
			},
			clickCel(row){
				this.$confirm("是否删除数据?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					shopclassifyDelete(row.id).then(res => {
						if(res.code === 200){
							this.$message.success('删除成功');
							this.GetShopclassifyList();
						}else{
							this.$message.error(res.msg)
						}
					})
				});
			},
			switchChange(e,id){
				shopclassifyShowUpdate({
					id: id,
					isShow: e ? 1 : 2
				}).then(res =>{
					if(res.code === 200){
						this.$message.success('修改成功')
						this.GetShopclassifyList();
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			onLoad(){
				this.GetShopclassifyList();
			},
			GetShopclassifyList(){
				shopclassifyList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize
				}).then(res => {
					if(res.code === 200){
						res.data.records.forEach(item => {
							item.isShow = item.isShow === 1 ? true : false
						})
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			}
		}
	}
</script>

<style scoped>
</style>
