<template>
	<el-dialog
		title="派单"
		width="30%"
		v-dialogdrag
		:visible.sync="dispatchData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose"
	>
		<div class="dispatch">
			<p>指派回收人员 : </p>
			<el-select v-model="value" filterable placeholder="请选择">
				<el-option v-for="item in options" :key="item.id" :label="item.username" :value="item.id" :disabled="item.recoverState == 1 ? true : false"></el-option>
			</el-select>	
		</div>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" :disabled="disabled" @click="handleSubmit()">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { orderRecoverDispatch } from '@/api/order/list.js';
export default {
	props: {
		dispatchData: Object
	},
	data() {
		return {
			options: this.$store.state.orderList.userList,
			value: '',
			disabled: false
		};
	},
	methods: {
		handleSubmit() {
			if(this.value == '') return this.$message.error('请选择回收人员');
			this.disabled = true;
			orderRecoverDispatch({
				id: this.dispatchData.id,
				recoverUserId: this.value
			}).then(res => {
				if (res.code === 200) {
					this.$message.success('派单成功');
					this.$root.eventHub.$emit('changeClassifyDispatch', true);
					this.disabled = false;
				} else {
					this.$message.error(res.msg);
					this.disabled = false;
				}
			}).catch(err =>{
				this.$message.error(err.msg);
				this.disabled = false;
			})
		},
		handleClose() {
			this.$root.eventHub.$emit('changeClassifyDispatch', false);
		}
	}
};
</script>

<style scoped>
.dispatch{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 50px;
}
.dispatch p{
	font-size: 14px;
	color: #333333;
	padding-right: 30px;
}
</style>
