<template>
	<el-dialog :title="componentsData.title"
		width="30%"
		v-dialogdrag
		:visible.sync="componentsData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form"
			:upload-after="uploadAfter"
			:upload-error="uploadError"
			:upload-delete="uploadDelete">

			<template slot="sort">
				<el-input v-model="form.sort"
						  autocomplete="off"
						  type="text"
						  maxlength="6"
						  oninput="this.value = this.value.replace(/[^0-9]/g, '');"/>
			</template>

		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { imageUrl, actionUrl } from '@/config/public.js';
import { userTypeUpdate } from '@/api/user/list';
export default {
	props: {
		componentsData: Object
	},
	data() {
		return {
			disabled: false,
			form: this.componentsData.state === 'edit' ? this.componentsData.data : {unit: 1},
			option: {
			    submitBtn: false,
			    emptyBtn: false,
          labelWidth: 150,
			    column: [
					{
						label: '回收员头像',
						prop: 'avatarUrl',
						type: 'upload',
						accept: 'image/*',
						span: 24,
						listType: 'picture-img',
						loadText: '附件上传中，请稍等',
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						action: actionUrl,
						rules: [
							{
								required: true,
								message: '请上传回收员头像',
								trigger: 'blur'
							}
						]
					},
					{
					    label: '回收员姓名',
					    prop: 'username',
						type: 'input',
					    span: 18,
            maxlength: 10,
						rules: [
							{
								required: true,
								message: '请填写回收员姓名',
								trigger: 'blur'
							}
						]
					},
            {
              label:'回收员账号',
              prop:'phone',
              type: 'input',
              span: 18,
              disabled:true,
              rules: [{
                required: true,
                message: "请输入回收员账号",
                trigger: "blur"
              }]
            },
			    ]
			},
      uOptions: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token
        },
        customConfig: {},
        props: {
          url: 'path',
          res: 'data',
          name: 'path'
        }
      },
		};
	},
	methods: {
		handleSubmit(form){
			console.log(form)
			this.$refs.form.validate((vaild,done)=>{
			    if(vaild){
					this.disabled = true;
					if(this.componentsData.state === 'add'){
						this.GetOrderClassifyAdd(form,done)
					}else{
						this.GetOrderClassifyUpdate(form,done)
					}
			    }
			})
		},
		GetOrderClassifyUpdate(form,done){
			userTypeUpdate({
				id: form.id,
				username: form.username,
        avatarUrl: form.avatarUrl,
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$root.eventHub.$emit('changeUserIndex', true);
					this.$message.success('提交成功');
				} else {
					this.$message.error(res.msg);
					this.disabled = false;
				}
				done();
			});
		},
		handleClose() {
			this.$root.eventHub.$emit('changeUserIndex', false);
		},
		uploadAfter(res, done) {
			done();
			this.$nextTick(() => {
				this.form.avatarUrl = res.path;
			});
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error(error);
			// console.log(error, column);
		}
	}
};
</script>

<style scoped>
.avue-form >>> .el-upload{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-icon-plus{
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.avue-form >>> .el-upload--picture-card{
    line-height: 100px;
}
.avue-form >>> .avue-upload__avatar{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-upload-list__item,.el-upload-list__item-actions{
    width: 100px;
    height: 100px;
}
</style>
