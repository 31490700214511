<template>
    <el-dialog title=""
               width="600px"
               v-dialogdrag
               :visible.sync="toExamine.type"
               :close-on-click-modal="false"
               :show-close="false"
               :before-close="handleClose">
        <avue-form :option="option" v-model="form" ref="form">
            <template slot="rejectReason">
                <el-input
                        type="textarea"
                        placeholder=""
                        v-model="form.rejectReason"
                        maxlength="30"
                        show-word-limit
                >
                </el-input>
            </template>
        </avue-form>
        <div class="submit-button">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"
                       @click="handleSubmit(form)">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { recoverWithdrawExamineUpdate } from "@/api/recover/withdraw"
    export default {
        props: {
            toExamine: Object
        },
        data(){
            return{
                form: {
                    state: this.toExamine.state
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                      {
                        label: '审核',
                        prop: 'state',
                        type: 'radio',
                        dicData: [{
                          label: '审核通过',
                          value: 2
                        }, {
                          label: '审核驳回',
                          value: 3
                        }]
                      },
                      {
                        label:'',
                        prop:'rejectReason',
                        span: 24,
                        row: true,
                        slot: true,
                        labelWidth: 0,
                        maxlength: 30,
                        showWordLimit:true,
                        rules: [{
                          required: true,
                          message: "输入拒绝理由",
                          trigger: "blur"
                        }],
                        display: false
                      },
                    ]
                }
            }
        },
        watch: {
            'form.state'(value){
                const rejectReason = this.findObject(this.option.column, 'rejectReason');
                rejectReason.display = value == 2 ? false : true;
            }
        },
        mounted() {
            // console.log('toExamine',this.toExamine)
            const state = this.findObject(this.option.column, 'state');
            const rejectReason = this.findObject(this.option.column, 'rejectReason');
            if(this.toExamine.state == 3){
                state.disabled = true;
                rejectReason.display = true;
            }else{
                state.disabled = false;
                rejectReason.display = false;
            }
        },
        methods: {
            handleSubmit(form){
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                      recoverWithdrawExamineUpdate({
                            id: this.toExamine.id,
                            state: form.state,
                            rejectReason: form.rejectReason,
                        }).then(res =>{
                            // console.log(res)
                            if(res.code === 200){
                                this.$message.success(res.msg)
                                this.$root.eventHub.$emit('changeToExamine', {type:true,state: 2});
                            }else{
                                this.$message.error(res.msg)
                            }
                            done();
                        }).catch(err =>{
                            this.$message.error(err.msg)
                            done();
                        })
                    }
                })
            },
            handleClose(){
                this.$root.eventHub.$emit('changeToExamine', {type:false,state: 2});
            },
        }
    }
</script>

<style scoped>
.submit-button{
    display: flex;
    justify-content: center;
}
</style>
