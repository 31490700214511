let baseUrl = 'api';
// 上传图片接口
let actionUrl = baseUrl + '/web/tool/file/upload'
// 图片路劲
let imageUrl = 'https://admin.jinglabao.com';

export {
    baseUrl,
    actionUrl,
    imageUrl
}
