<template>
	<div class="main-content">
		<pagesHeader title="回收物品分类管理" />
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
			<template slot="menuLeft">
				<el-button type="primary" @click.stop="clickMenu('add')">新增</el-button>
			</template>


			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickMenu('edit', row)">编辑</el-button>
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickMenu('cel', row)">删除</el-button>
			</template>
			<template slot-scope="{ row, index }" slot="price">
				<div v-if="row.price == 0">{{ row.unit }}</div>
				<div v-else>{{ row.price }}元{{ row.unit == '' ? '':'/'}}{{ row.unit }}</div>
			</template>



			<template slot-scope="{ row, index }" slot="isShow">
				<el-switch v-model="row.isShow" @change="switchChange($event,row.id,index)"></el-switch>
			</template>
		</avue-crud>

		<ClassifyIndex v-if="componentsData.type" :componentsData="componentsData" />
	</div>
</template>

<script>
import { orderClassifyList, orderClassifyDelete,orderClassifyView,orderClassifyShowUpdate } from '@/api/order/classify.js';
import ClassifyIndex from '@/components/order/classify/index.vue';
export default {
	name: 'classifyIndex',
	components: {
		ClassifyIndex
	},
	data() {
		return {
			componentsData: {
				title: '',
				state: '',
				type: false,
				data: {}
			},
			data: [],
			option: {
				index: true,
				indexLabel: '序号',
				stripe: false,
				showHeader: true,
				border: true,
				align: 'center',
				menuAlign: 'center',
				columnBtn: false, //列显隐
				refreshBtn: false, //刷新
				updateBtn: false,
				searchMenuSpan: 6,
				addBtn: false,
				delBtn: false,
				editBtn: false,
				viewBtn: false,
				column: [
					{
						label: '分类名称',
						prop: 'name'
					},
					{
						label: '排序',
						prop: 'sort'
					},
					{
						label: '单价',
						prop: 'price',
						slot: true
					},
					{
						label: '显示',
						prop: 'isShow',
						slot: true
					}
				]
			},
			pageSync: {
				currentPage: 1,
				total: 0,
				layout: 'total,pager,prev, next',
				background: false,
				pageSize: 10
			}
		};
	},
	mounted() {
		this.$root.eventHub.$on('changeClassifyIndex', (n)=>{
			this.componentsData =  {
				title: '',
				state: '',
				type: false,
				data: {}
			};
		    if(n){
		        this.getOrderClassifyList();
		    }
		})
	},
	methods: {
		switchChange(e, id, index) {
			// console.log(111, e, id, index);
			orderClassifyShowUpdate({
				id: id,
				isShow: e ? 1 : 2
			}).then(res => {
				if (res.code === 200) {
					this.data[index].isShow = e;
					this.$message.success(res.msg);
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		clickMenu(name, form) {
			if (name === 'add') {
				this.componentsData = {
					title: '新增分类',
					state: 'add',
					type: true
				}
			} else if (name === 'cel') {
				return this.rowDel(form.id);
			} else {
				return this.getOrderClassifyView(form);
			}
		},
		rowDel(ids) {
			this.$confirm(`删除这条数据`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				callback: action => {
					if (action == 'confirm') {
						orderClassifyDelete(ids).then(res => {
							// console.log(res)
							if (res.code === 200) {
								this.$message.success(res.msg);
								this.onLoad();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				}
			});
		},
		onLoad() {
			this.getOrderClassifyList();
		},
		getOrderClassifyList() {
			orderClassifyList({
				page: this.pageSync.currentPage,
				size: this.pageSync.pageSize
			}).then(res => {
				// console.log(res)
				res.data.records.forEach(item => {
					item.isShow = item.isShow == 1 ? true : false;
				});
				this.data = res.data.records;
				this.pageSync.total = res.data.total;
				this.pageSync.currentPage = res.data.current;
			});
		},
		getOrderClassifyView(form) {
			orderClassifyView(form.id).then(res => {
				this.componentsData = {
					title: '编辑分类',
					state: 'edit',
					type: true,
					data: res.data
				}
			});
		}
	}
};
</script>

<style scoped>
/* .avue-crud >>> .avue-crud__pagination{
	text-align: left;
	border: 1px solid #EBEEF5;
	padding: 0 10px;
	margin: 20px 0;
} */
</style>
