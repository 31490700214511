<template>
	<el-dialog
		title="新增/编辑账号"
		width="30%"
		v-dialogdrag
		:visible.sync="componentsData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose"
	>
		<avue-form :option="option" v-model="form" ref="form" :upload-error="uploadError" :upload-before="uploadBefore" :upload-delete="uploadDelete" :upload-after="uploadAfter">
			<template slot="phone">
				<!-- <input type="tel" maxlength="11" v-model="form.phone" placeholder="请输入 手机号"/> -->
				<el-input type="tel" maxlength="11" style="height:32px" placeholder="请输入 手机号" v-model="form.phone" clearable></el-input>
			</template>
		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { imageUrl, actionUrl } from '@/config/public';
import { recoverUserAdd, recoverUserUpdate } from '@/api/recover/user.js';
export default {
	props: {
		componentsData: Object
	},
	data() {
		return {
			disabled: false,
			form: this.componentsData.state === 'add' ? {state: 1} : this.componentsData.detail,
			option: {
				labelWidth: 120,
				submitBtn: false,
				emptyBtn: false,
				column: [
					{
						label: '回收员头像',
						prop: 'avatarUrl',
						type: 'upload',
						span: 24,
						listType: 'picture-img',
            accept: 'image/png, image/jpeg',
						loadText: '附件上传中，请稍等',
            fileSize: 1024 * 20,
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						action: actionUrl,
						rules: [
							{
								required: true,
								message: '请上传回收员头像',
								trigger: 'blur'
							}
						]
					},
					{
						label: '回收员姓名',
						prop: 'username',
						type: 'input',
						maxlength: 10,
						span: 20,
						rules: [
							{
								required: true,
								message: '请输入回收员姓名',
								trigger: 'blur'
							}
						]
					},
					{
						label: '登录账号',
						prop: 'phone',
						type: 'input',
						maxlength: 11,
						span: 20,
						slot: true,
						rules: [
							{
								required: true,
								// message: '请输入登录账号',
								message: '请输入手机号',
								trigger: 'blur'
							}
						]
					},
					{
						label: '联系方式',
						prop: 'contact',
						type: 'input',
						maxlength: 11,
						span: 20,
						rules: [
							{
								required: true,
								message: '请输入联系方式',
								trigger: 'blur'
							}
						]
					},
					{
						label: '账号状态',
						prop: 'state',
						type: 'radio',
						dicData: [{ label: '启用', value: 1 }, { label: '停用', value: 2 }],
						span: 24,
						rules: [
							{
								required: true,
								message: '请选择账号状态',
								trigger: 'blur'
							}
						]
					}
				]
			}
		};
	},
	methods: {
		handleSubmit(form) {
			this.disabled = true;
			if (this.componentsData.state === 'add') {
				this.GetRecoverUserAdd(form);
			} else {
				this.GetRecoverUserUpdate(form);
			}
		},
		GetRecoverUserAdd(form) {
			recoverUserAdd({
				avatarUrl: form.avatarUrl,
				username: form.username,
				phone: form.phone,
				contact: form.contact,
				state: form.state
			})
				.then(res => {
					if (res.code === 200) {
						this.$message.success('新增完成');
						this.$root.eventHub.$emit('changeRecoverUserIndex', true);
					} else {
						this.$message.error(res.msg);
					}
					setTimeout(() => {
						this.disabled = false;
					}, 1000);
				})
				.catch(err => {
					// console.log(err)
					this.$message.error(err.msg);
					setTimeout(() => {
						this.disabled = false;
					}, 1000);
				});
		},
		GetRecoverUserUpdate(form) {
			recoverUserUpdate({
				id: form.id,
				avatarUrl: form.avatarUrl,
				username: form.username,
				phone: form.phone,
				contact: form.contact,
				state: form.state
			})
				.then(res => {
					if (res.code === 200) {
						this.$message.success('修改完成');
						this.$root.eventHub.$emit('changeRecoverUserIndex', true);
					} else {
						this.$message.error(res.msg);
					}
					setTimeout(() => {
						this.disabled = false;
					}, 1000);
				})
				.catch(err => {
					// console.log(err)
					this.$message.error(err.msg);
					setTimeout(() => {
						this.disabled = false;
					}, 1000);
				});
		},
		handleClose() {
			this.$root.eventHub.$emit('changeRecoverUserIndex', false);
		},
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
		uploadAfter(res, done) {
			done();
			// this.$message.success('上传后的方法')'
			this.$nextTick(() => {
				this.form.avatarUrl = res.path;
			});
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error(error);
			// console.log(error, column)
		}
	}
};
</script>

<style scoped>
.avue-form >>> .el-upload {
	width: 100px;
	height: 100px;
}
.avue-form >>> .el-icon-plus {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
.avue-form >>> .el-upload--picture-card {
	line-height: 100px;
}
.avue-form >>> .avue-upload__avatar {
	width: 100px;
	height: 100px;
}
.avue-form >>> .el-upload-list__item,
.el-upload-list__item-actions {
	width: 100px;
	height: 100px;
}
</style>
