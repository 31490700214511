import {get, put} from '../axios'

// 会员商城订单管理-列表
export const shopOrderList = params => get('/web/shop/order/list',params);

// 会员商城订单管理-发货
export const shopOrderDeliver = params => put('/web/shop/order/deliver',params);

// 会员商城订单管理-订单详情
export const shopOrderView = params => get(`/web/shop/order/view/${params}`);

// 会员商城订单管理-批量发货
export const shopOrderDeliverBatch = params => put('/web/shop/order/deliver/batch',params);

// 批量发货-模板导出
export const shopOrderExport = params => GET('/web/shop/order/export',params);