<template>
	<div class="main-content">
		<pagesHeader :title="title" />
		<div class="details" v-if="loading">
			<div class="details-view">
				<div class="view-left">订单编号 ：</div>
				<div class="view-right">{{ details.code }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">下单时间 ：</div>
				<div class="view-right">{{ details.createTime }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">下单人 ：</div>
				<div class="view-right">{{ details.nickname }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">下单人手机号 ：</div>
				<div class="view-right">{{ details.userPhone }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">联系人 ：</div>
				<div class="view-right">{{ details.username }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">联系方式 ：</div>
				<div class="view-right">{{ details.phone }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">联系地址 ：</div>
				<div class="view-right">{{details.province}}{{details.city}}{{details.county}}{{details.detail}}</div>
			</div>
			<el-divider></el-divider>
			<div class="details-view">
				<div class="view-left">选择回收品类型 ：</div>
				<div class="view-right">{{ details.goodsClassify }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">预约上门时间 ：</div>
				<div class="view-right">{{ details.doorTime }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">备注 ：</div>
				<div class="view-right">{{ details.remarks }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">订单合计 ：</div>
				<div class="view-right" style="color: #d9001b;font-weight: 600;">￥{{ details.price }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">获得积分 ：</div>
				<div class="view-right" style="color: #f59a23;font-weight: 600;">{{ details.integral }}分</div>
			</div>
			<el-divider></el-divider>
			<div class="details-view">
				<div class="view-left">订单状态 ：</div>
				<div class="view-right" style="color: #02A7F0;font-weight: 600;">
					{{ details.state == '1' ? '已下单' : details.state == '2' ? '已接单' : details.state == '3' ? '已完成' : '已取消' }}
				</div>
			</div>
			<div class="details-view">
				<div class="view-left">回收员 ：</div>
				<div class="view-right">{{ details.recoverUsername }}</div>
			</div>
			<div class="details-view">
				<div class="view-left">手机号 ：</div>
				<div class="view-right">{{ details.recoverPhone }}</div>
			</div>
			<el-divider></el-divider>
			<div class="details-view"><div class="view-left">回收详情 ：</div></div>
			<avue-crud :data="details.productList" :option="option">
				<template slot-scope="{ row, index }" slot="outNum">
					<div v-if="!type">{{row.outNum}}</div>
					<input
						v-else
						type="number"
						v-model="row.retrievalNum"
						min="1"
						pattern="[0-9]"
						onkeyup="value=value.replace(/[\W]/g,'')"
						onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
						placeholder="请输入"
					/>
				</template>
				<template slot-scope="{ row, index }" slot="outPrice">
					<div v-if="!type">{{row.outPrice}}</div>
					<input
						v-else
						type="number"
						v-model="row.retrievalPrice"
						min="1"
						pattern="[0-9]"
						onkeyup="value=value.replace(/[\W]/g,'')"
						onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
						placeholder="请输入"
					/>
				</template>
				<template slot-scope="{ row, index }" slot="num">
					<div>{{ row.num }}{{ row.unit || '个' }}</div>
				</template>

<!--				<template slot-scope="{ row, index }" slot="image">-->
<!--					 {{row.images}}-&#45;&#45;{{row.uploadType}} -->
<!--					<div class="images" v-if="row.uploadType == 1">-->
<!--						<div class="images-item" v-for="(item, index) in row.images" :key="index"><img :src="item" alt="" @click="clickImageShow(row.images)" /></div>-->
<!--					</div>-->
<!--					<div class="images" v-if="row.uploadType == 2">-->
<!--						<div class="images-item"><video width="30" height="30" :src="row.image" @click="clickVideoShow(row.image)"></video></div>-->
<!--					</div>-->
<!--				</template>-->
			</avue-crud>
			<div class="details-button" v-if="details.turnState == 1 && details.state == 3">
				<el-button @click="$router.go(-1)">返回</el-button>
				<el-button type="primary" @click="clickSubmit()">确认上缴</el-button>
			</div>
			<div class="details-button" v-if="details.turnState == 2 && details.state == 3">
				<el-button @click="$router.go(-1)">返回</el-button>
				<!-- <el-button v-else @click="checkoutClose">返回</el-button> -->
				<el-button v-if="!type" type="primary" @click="checkout">出库</el-button>
				<el-button v-else type="primary" @click="confirmCheckout">确认出库</el-button>
			</div>
		</div>
		<previewImage v-if="imagesShow" :images="images" @TAP_ImgesShow="TAP_ImgesShow"></previewImage>
		<previewVideo v-if="videoShow" :video="video" @TAP_VideoShow="TAP_VideoShow"></previewVideo>
	</div>
</template>

<script>
import { Loading } from 'element-ui';
import { storageRecoverView, storageCheckAdd, storageRecoverTurn } from '@/api/storage/check.js';
export default {
	data() {
		return {
			imagesShow: false,
			images: [],
			videoShow: false,
			video: '',
			type: this.$route.params.type,
			loading: false,
			title: '',
			details: {},
			product: [],
			submitType: false,
			option: {
				header: false,
				addBut: false,
				celBut: false,
				editBut: false,
				menu: false,
				border: true,
				align: 'center',
				menuAlign: 'center',
				column: [
					{
						label: '回收品类型',
						prop: 'classifyName'
					},
					{
						label: '回收物品名称',
						prop: 'name'
					},
					{
						label: '计量单位',
						prop: 'num',
						slot: true
					},
					{
						label: '出库数量',
						prop: 'outNum',
						hide: true
					},
					{
						label: '剩余数量',
						prop: 'surplusNum',
						hide: true
					},
					{
						label: '图片/视频',
						prop: 'image',
						dataType: 'string',
						type: 'img',
						// slot: true
					},
					{
						label: '预计金额',
						prop: 'price'
					},
					{
						label: '出库金额',
						prop: 'outPrice',
						slot: true,
						hide: true
					}
				]
			}
		};
	},
	watch: {
		type(newValue) {
			if (newValue) {
				this.title = '出库';
			} else {
				this.title = `订单详情-${this.details.turnState === 1 ? '待确认' : '已确认'}`;
			}
			const outNum = this.findObject(this.option.column, 'outNum');
			const surplusNum = this.findObject(this.option.column, 'surplusNum');
			const outPrice = this.findObject(this.option.column, 'outPrice');
			let state = Boolean;
			if(this.details.turnState === 1){
				state = this.type ? false : true;
			}else{
				state = false;
			}
			outNum.hide = state;
			surplusNum.hide = state;
			outPrice.hide = state;
		}
	},
	mounted() {
		this.GetStorageRecoverView();
	},
	methods: {
		clickSubmit() {
			this.$confirm('是否确认上缴？', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			})
				.then(() => {
					storageRecoverTurn(this.$route.params.id).then(res => {
						if (res.code === 200) {
							this.$message.success('操作成功');
							this.GetStorageRecoverView();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},
		checkout() {
			this.type = true;
		},
		checkoutClose() {
			this.type = false;
		},
		confirmCheckout() {
			console.log('确认出库');
			if (this.submitType) return;
			let productList = this.details.productList;
			let result = [];
			productList.forEach(item => {
				if(item.retrievalNum != 0 && item.retrievalPrice != 0){
					if (item.retrievalNum != '' && item.retrievalPrice != '' && item.retrievalNum <= item.surplusNum) {
						let data = {
							id: item.id,
							retrievalNum: item.retrievalNum * 1,
							retrievalPrice: item.retrievalPrice * 1
						};
						result.push(data)
					}
				}
			});
			console.log(result)
			if(result.length == 0){
				this.$message.error('没有可以出库的数据!');
				this.submitType = false;
			}else{
				this.submitType = true;
				this.GetStorageCheckAdd(result);
			}
		},
		GetStorageCheckAdd(result) {
			storageCheckAdd({
				orderId: this.$route.params.id,
				product: result
			}).then(res => {
				if (res.code === 200) {
					this.$message.success('出库成功');
					this.type = false;
					this.GetStorageRecoverView();
				} else {
					this.$message.error(res.msg);
				}
				this.submitType = false;
			}).catch(err => {
				this.submitType = false;
				this.$message.error(err.msg);
            });
		},
		GetStorageRecoverView() {
			Loading.service({ fullscreen: true });
			storageRecoverView(this.$route.params.id).then(res => {
				console.log(res);
				if (res.code === 200) {
					this.loading = true;
					let data = {
						id: '',
						retrievalNum: 0,
						retrievalPrice: 0
					};
					res.data.productList.forEach(item => {
						item.retrievalNum = '';
						item.retrievalPrice = '';
					});
					if (this.type) {
						this.title = '出库';
					} else {
						this.title = `订单详情-${res.data.turnState === 1 ? '待确认' : '已确认'}`;
					}
					res.data.productList.forEach(item => {
						item.images = item.image.split(',');
					});
					this.details = res.data;
					console.log(this.details);
					if (res.data.turnState === 2) {
						const outNum = this.findObject(this.option.column, 'outNum');
						const surplusNum = this.findObject(this.option.column, 'surplusNum');
						const outPrice = this.findObject(this.option.column, 'outPrice');
						// let state = this.$route.params.type === false ? true : false;
						outNum.hide = false;
						surplusNum.hide = false;
						outPrice.hide = false;
					}

					this.$nextTick(() => {
						Loading.service({ fullscreen: true }).close();
					});
				}
			});
		},
		clickImageShow(images) {
			console.log(images);
			this.imagesShow = true;
			this.images = images;
		},
		TAP_ImgesShow() {
			this.imagesShow = false;
			this.images = [];
		},
		clickVideoShow(video) {
			this.videoShow = true;
			this.video = video;
		},
		TAP_VideoShow() {
			this.videoShow = false;
			this.video = [];
		}
	}
};
</script>

<style scoped>
.details {
	padding: 0 10px;
}
.details-view {
	display: flex;
	font-family: 'Arial Normal', 'Arial', sans-serif;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	line-height: 30px;
	color: #333333;
}
.view-left {
	width: 150px;
}
.details-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
}

.details-button .el-button {
	padding: 12px 40px;
}
.details-button .el-button + .el-button {
	margin-left: 50px;
}
.details input {
	width: 80%;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #e6e6e6;
	padding: 0 10px;
}
.images {
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.images-item {
	width: 30px;
	height: 30px;
}
.images img {
	width: 30px;
	height: 30px;
}
</style>
