<template>
	<div class="header">
		<div class="search">
			<el-input v-model="form.classifyName"
				placeholder="输入分类名称"
				style="margin-right: 10px;"></el-input>
			<el-button @click="searchQuery">搜索</el-button>
		</div>
		<el-button type="primary" style="height: 40px !important;"  @click="exportButton">导出</el-button>
	</div>
</template>

<script>
	import { countGoodsExport } from "@/api/count/goods.js";
	export default {
		data(){
			return{
				form: {
					classifyName: '',
				}
			}
		},
		methods: {
			exportButton(){
				this.$confirm("是否导出数据?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					window.open(`/api/web/count/goods/export?classifyName=${this.form.classifyName}`,'_self');
				});
			},
			searchQuery(){
				this.$emit('changeCountGoods',this.form)
			}
		}
	}
</script>

<style scoped>
	.header{
		display: flex;
		justify-content: space-between;
	}
	.search{
		display: flex;
		margin-bottom: 20px;
	}
	.date-picker{
		width: 480px !important;
	}
	.date-picker >>> .el-range-title{
		width: 30% !important;
		padding: 0 0 0 10px !important;
		font-size: 14px;
	}
	.date-picker >>> .el-range-separator{
		padding: 0;
	}
	.date-picker >>>.el-range-input{
		width: 100% !important;
	}
	.search > .el-range-editor.el-input__inner{
		padding: 3px 0px;
	}
	.search > .el-select{
		width: 200px;
	}
	.search > .el-input{
		width: 300px;
	}
</style>
