<template>
	<div>
		<div class="view"></div>
		<video :width="Width" :height="Height" :autoplay="true" muted>
			<source :src="video" type="video/mp4">
			<source :src="video" type="video/ogg">
			<source :src="video" type="video/webm">
		</video>
		<div class="close" @click="TAP_VideoShow">
			<i class="el-icon-circle-close"></i>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			video: '',
			Width: document.body.clientWidth,
			Height: document.body.clientHeight
		},
		mounted(){
			console.log(this.video)
		},
		methods:{
			TAP_VideoShow(){
				this.$emit('TAP_VideoShow')
			}
		}
	}
</script>

<style scoped>
.view{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(0,0,0,0.3);
}
video{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 9999;
	background: rgba(0,0,0,0.3);
}
source{
	width: 100%;
	height: 100%;
}
.close{
	width: 40px;
	height: 40px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 99999;
}
.el-icon-circle-close{
	font-size: 40px;
}
</style>
