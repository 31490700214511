import {get, post, put,Delete} from '../axios'

// 合作单位管理-列表
export const pageCompanyList = params => get('/web/page/company/list',params);

// 合作单位管理-新增
export const pageCompanyAdd = params => post('/web/page/company/add',params);

// 合作单位管理-删除
export const pageCompanyDelete = params => Delete(`/web/page/company/delete/${params}`,);

// 合作单位管理-查看
export const pageCompanyView = params => get(`/web/page/company/view/${params}`);

// 合作单位管理-修改
export const pageCompanyUpdate = params => put('/web/page/company/update',params);

// 合作单位管理-显示修改
export const pageCompanyStateUpdate = params => put('/web/page/company/show/update',params);
