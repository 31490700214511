<template>
	<div class="search">
		<el-date-picker
			v-model="form.date"
			type="datetimerange"
			range-title="下单时间"
			range-separator="~"
			start-placeholder="开始日期"
			end-placeholder="结束日期"
			class="date-picker"
			format="yyyy-MM-dd HH:mm:ss"
			value-format="yyyy-MM-dd HH:mm:ss"
			style="margin-right: 10px;">
		</el-date-picker>
		<el-select v-model="form.state"
			placeholder="订单状态"
			style="margin-right: 10px;">
			<el-option
				  v-for="item in optionsState"
				  :key="item.value"
				  :label="item.label"
				  :value="item.value">
			</el-option>
		</el-select>
		<el-select v-model="form.logisticsState"
			placeholder="物流状态"
			style="margin-right: 10px;">
			<el-option
				  v-for="item in optionsLogisticsState"
				  :key="item.value"
				  :label="item.label"
				  :value="item.value">
			</el-option>
		</el-select>
		<el-input v-model="form.code"
			placeholder="输入订单编号/收货人/手机号"
			style="margin-right: 10px;"></el-input>
		<el-button @click="searchQuery">搜索</el-button>
		<el-button @click="batchdelivery">批量发货</el-button>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { shopOrderDeliverBatch } from "@/api/shop/order.js"
	export default {
		data(){
			return{
				form: {
					date: [],
					commitTimeStart: '', //下单时间开始
					commitTimeEnd: '', //下单时间开始
					logisticsState: '', //物流状态：1-无需发货；2-未发货；3-已发货
					state: '', //订单状态：1-待付款；2-待发货；3-待收货；4-已完成；5-已取消
					code: '',
				},
				optionsLogisticsState: [
					{
					    value: 0,
					    label: '物流状态'
					},
					{
					    value: 1,
					    label: '无需发货'
					},
					{
					    value: 2,
					    label: '未发货'
					},
					{
					    value: 3,
					    label: '已发货'
					}
				],
				optionsState: [
          {
            value: 0,
            label: '订单状态'
          },
					{
					    value: 1,
					    label: '待付款'
					},
					{
					    value: 2,
					    label: '待发货'
					},
					{
					    value: 3,
					    label: '待收货'
					},
					{
					    value: 4,
					    label: '已完成'
					},
					{
					    value: 5,
					    label: '已取消'
					}
				],
				delivery: {
					type: false
				}
			}
		},
		watch: {
			'form.date'(newValue,oldValue){
        if(newValue != null){
          newValue.forEach((item,index) =>{
            if(index === 0){
              this.form.commitTimeStart = item+' 00:00:00'
            }else{
              this.form.commitTimeEnd = item+' 23:59:59'
            }
          })
        }else{
          this.form.commitTimeStart = '';
          this.form.commitTimeEnd = '';
        }
			},
		},
		mounted() {
			this.GetShopGoodsClassifyList();
		},
		methods: {
			...mapActions(['GetShopGoodsClassifyList']),
			goodsAdd(){
				this.$router.push({ name: 'shopGoodsDetail',params: { id: 0 } })
			},
			searchQuery(){
				this.$emit('changeShopOrderSearch',this.form)
			},
			batchdelivery(){
				this.$emit('changeShopOrderSearchBatchDelivery')
			}
		}
	}
</script>

<style scoped>
	.search{
		display: flex;
		margin-bottom: 20px;
	}
	.date-picker{
		width: 480px !important;
	}
	.date-picker >>> .el-range-title{
		width: 20% !important;
		padding: 0 0 0 10px !important;
		font-size: 14px;
	}
	.date-picker >>> .el-range-separator{
		padding: 0;
	}
	.date-picker >>>.el-range-input{
		width: 30% !important;
	}
	.search > .el-range-editor.el-input__inner{
		padding: 3px 0px;
	}
	.search > .el-select{
		width: 200px;
	}
	.search > .el-input{
		width: 300px;
	}
</style>
