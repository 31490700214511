<template>
	<div class="main-content">
		<pagesHeader title="新增轮播图" />
		<avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-before="uploadBefore" :upload-delete="uploadDelete"
			:upload-after="uploadAfter">
			<template slot-scope="scope" slot="menuForm">
				<div class="submit-button">
					<el-button size="medium" @click="$router.go(-1)">取消</el-button>
					<el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
				</div>
			</template>

			<template slot="contentA">
				<el-select v-model="form.contentA" filterable placeholder="请选择">
					<el-option v-for="item in dicData" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</template>
			<template slot="sort">
				<el-input v-model="form.sort" autocomplete="off" type="text" maxlength="6"
					oninput="this.value = this.value.replace(/[^0-9]/g, '');" />
			</template>
		</avue-form>
	</div>
</template>

<script>
	import {
		pageBannerAdd,
		pageBannerView,
		pageBannerUpdate,
		pageGoodsList
	} from '@/api/page/banner';
	import {
		baseUrl,
		imageUrl,
		actionUrl
	} from '@/config/public';
	const name = '';
	export default {
		name: 'Add',
		data() {
			return {
				dicData: [],
				form: {},
				option: {
					submitBtn: false,
					emptyBtn: false,
					labelWidth: 120,
					column: [{
							label: '轮播图',
							type: 'upload',
							prop: 'image',
							listType: 'picture-img',
							accept: 'image/png, image/jpeg',
							loadText: '附件上传中，请稍等',
              fileSize: 1024 * 20,
							span: 24,
							propsHttp: {
								res: 'data',
								url: 'url',
								name: 'path',
								home: imageUrl
							},
							headers: {
								Authorization: this.$store.state.user.Token
							},
							action: actionUrl,
							rules: [{
								required: true,
								message: '请上传轮播图',
								trigger: 'blur'
							}]
						},
						{
							label: '跳转类型',
							span: 15,
							prop: 'jumpType',
							type: 'select',
							dicData: [{
								label: '内部链接',
								value: 1
							}, {
								label: '外部链接',
								value: 2
							}]
						},
						// {
						// 	label: '跳转内容',
						// 	span: 15,
						// 	prop: 'contentA',
						// 	type: 'select',
						// 	remote: true,
						// 	// dicUrl: `${baseUrl}/web/page/goods/list?name={{key}}`,
						// 	dicData: [],
						// 	props: {
						// 		label: 'name',
						// 		value: 'name'
						// 	},
						// 	headers: {
						// 		Authorization: this.$store.state.user.Token
						// 	},
						// 	display: true
						// },
						{
							label: '跳转内容',
							span: 15,
							prop: 'contentA',
							display: true,
							slot: true
						},
						{
							label: '跳转',
							span: 15,
							prop: 'contentB',
							display: true
						},
						{
							label: '排序值',
							span: 15,
							prop: 'sort',
							slot: true,
							rules: [{
								required: true,
								message: '请输入排序值',
								trigger: 'blur'
							}]
						},
						{
							label: '是否显示',
							span: 15,
							prop: 'isShow',
							type: 'select',
							dicData: [{
								label: '显示',
								value: 1
							}, {
								label: '不显示',
								value: 2
							}],
							rules: [{
								required: true,
								message: '请选择是否显示',
								trigger: 'blur'
							}]
						}
					]
				}
			};
		},
		watch: {
			'form.jumpType'(newValue) {
				const contentA = this.findObject(this.option.column, 'contentA');
				const contentB = this.findObject(this.option.column, 'contentB');
				if (newValue == 1) {
					contentA.display = true;
					contentB.display = false;
					// this.GetShopGoodsList();
				} else if (newValue == 2) {
					contentA.display = false;
					contentB.display = true;
				} else {
					contentA.display = false;
					contentB.display = false;
				}
			}
		},
		mounted() {
			console.log(this.$route.params.id);
			if (this.$route.params.id != 0) {
				this.GetPageBannerView(this.$route.params.id);
			}
			this.GET_pageGoodsList();
		},
		methods: {
			GET_pageGoodsList(name) {
				pageGoodsList({
					name: name
				}).then(res => {
					console.log(res)
					if (res.code === 200) {
						this.dicData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			handleSubmit() {
				this.$refs.form.validate((vaild, done) => {
					if (vaild) {
						if (this.$route.params.id == 0) {
							this.GetPageBannerAdd(done);
						} else {
							this.GetPageBannerUpdate(done);
						}
					}
				});
			},
			GetPageBannerAdd(done) {
				pageBannerAdd({
						image: this.form.image,
						jumpType: this.form.jumpType,
						content: this.form.jumpType == 1 ? this.form.contentA : this.form.contentB,
						sort: this.form.sort,
						isShow: this.form.isShow
					})
					.then(res => {
						// console.log(res)
						if (res.code === 200) {
							this.$message.success('添加完成');
							this.$router.go(-1);
						} else {
							this.$message.error(res.msg);
						}
						done();
					})
					.catch(err => {
						this.$message.error(err.msg);
						done();
					});
			},
			GetPageBannerUpdate(done) {
				pageBannerUpdate({
						id: this.form.id,
						image: this.form.image,
						jumpType: this.form.jumpType,
						content: this.form.jumpType == 1 ? this.form.contentA : this.form.contentB,
						sort: this.form.sort,
						isShow: this.form.isShow
					}).then(res => {
						// console.log(res)
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.$router.go(-1);
						} else {
							this.$message.error(res.msg);
						}
						done();
					})
					.catch(err => {
						this.$message.error(err.msg);
						done();
					});
			},
      uploadBefore(file, done, loading,column) {
        if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
          this.$message.error('文件格式有误');
          loading()
        }else {
          done();
        }
      },
			uploadDelete() {
				return this.$confirm(`是否确定移除？`);
			},
			uploadError(error, column) {
				this.$message.error(error);
				// console.log(error, column)
			},
			uploadAfter(res, done) {
				done();
				this.$nextTick(() => {
					this.form.image = res.path;
				});
			},
			GetPageBannerView(id) {
				pageBannerView(id).then(res => {
					console.log(res);
					if (res.code === 200) {
						if (res.data.jumpType === 1) {
							res.data.contentA = res.data.content;
						} else if(res.data.jumpType === 2) {
							res.data.contentB = res.data.content;
						}
						this.form = res.data;
						this.form.jumpType = res.data.jumpType === 0 ? '': res.data.jumpType
					}
				});
			}
		}
	};
</script>

<style scoped>
	.submit-button {
		padding: 50px 0;
	}

	.submit-button .el-button {
		padding: 10px 50px;
	}

	.avue-form>>>.el-input__suffix {
		display: none;
	}
</style>
