<template>
    <div class="main-content">

        <div class="table-content">
            <avue-crud
                    :data="data"
                    :option="option"
                    :search.sync="search"
                    @search-change="searchChange"
                    @selection-change="selectionChange"
                    :table-loading="loading"
                    ref="crud">

                <template slot="menuLeft">
                    <el-button v-for="(item,index) in Buttons" :key="index"
                               :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                               :icon="item.icon"
                               size="small"
                               :plain="item.name == '新增' ? false : true"
                               v-show="(item.name != '修改') && (item.name != '查看')"
                               @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
                </template>


                <template slot="menu" slot-scope="{row}">
                    <el-button v-for="(item,index) in Buttons" :key="index"
                               v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                               style="margin-left:10px;"
                               size="small"
                               type="text"
                               :icon="item.icon"
                               @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
                    <el-button style="margin-left:10px;"
                               size="small"
                               type="text"
                               icon="el-icon-circle-plus-outline"
                               @click.stop="clickMenu('新增子项',row)">新增子项</el-button>
                </template>

                <template slot-scope="{row,index,type}" slot="menuForm">
                    <el-button type="primary"
                               icon="el-icon-circle-plus-outline"
                               size="small"
                               v-if="type=='add'"
                               @click="$refs.crud.rowSave()"
                    >保存</el-button>
                    <el-button
                            icon="el-icon-circle-close"
                            size="small"
                            plain
                            @click="$refs.crud.closeDialog()"
                    >取消</el-button>
                </template>

                <template slot="type" slot-scope="scope" >
                    <el-tag>{{scope.row.type == 1 ? '菜单':'按钮'}}</el-tag>
                </template>

            </avue-crud>
        </div>

        <Add v-if="dialogVisible"
               :title="title"
               :choiceId="choiceId"
               :parentId="parentId"
               :dialogVisible="dialogVisible"/>
    </div>
</template>

<script>
    import iconList from "@/config/iconList";
    import { menuList,menuDelete } from "@/api/system/menu";
    import Add from "@/components/system/menu/add"
    export default {
        name: "MenuIndex",
        components: {
            Add
        },
        data() {
            return {
                loading: true,
                Buttons: [],
                search:{},
                data: [],
                option:{
                    emptyText: '暂无数据',
                    lazy:true,
                    rowKey:'id',
                    border:true,
                    stripe:false,
                    showHeader:true,
                    selection:true,
                    page:false,
                    menuAlign:'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    menuWidth: 300,
                    tip: false,
                    searchShow: true,
                    dialogWidth: "60%",
                    column:[
                        {
                            label:'菜单名称',
                            prop:'name',
                        },
                        {
                            label:'路由地址',
                            prop:'url',
                        },
                        {
                            label:'菜单图标',
                            prop:'icon',
                            type: "icon",
                            iconList: iconList,
                            slot: true,
                            align:'center',
                        },
                        {
                            label:'菜单排序',
                            prop:'sort',
                            align:'center',
                        },
                        {
                            label:'类型',
                            prop:'type',
                            align:'center',
                            slot:true
                        }


                    ]
                },

                title: '',
                dialogVisible: false,
                choiceId: '0',
                parentId: '0',
                ids: []
            };
        },
        mounted() {
            let that = this;
            that.Buttons = that.$store.state.index.menuSubButtons
            this.getMenuList();

            this.$root.eventHub.$on('changeMenu', (n)=>{
                this.dialogVisible = false;
                if(n){
                    this.getMenuList();
                }
            })
        },
        methods:{
            getMenuList(){
                menuList().then(res => {
                    if(res.code === 200){
                        this.data = res.data;
                        this.loading = false;
                    }
                })
            },
            tip(e){
                this.$message.success(e);
            },
            clickMenu(name,data){
                console.log(data)
                if(name != '删除'){
                    this.dialogVisible = true;
                    this.title = name;
                }
                if(name == '新增') return this.choiceId = '0';
                if(name == '新增子项') return this.choiceId = data.id;
                if(name == '删除') return this.rowDel(data);
                if(name == '修改'){
                    this.choiceId = data.id;
                    this.parentId = data.parentId;
                    return
                }
                if(name == '查看') return this.choiceId = data.id;
            },
            selectionChange(list){
                let array = [];
                if(list.length == 0){
                    this.ids = [];
                }else{
                    list.forEach(item => {
                        array.push(item.id);
                    })
                    this.ids = array;
                }
            },
            rowDel(form){
                if(form == undefined){
                    if(this.ids.length > 0){
                        this.UserDelete()
                    }else{
                        this.$message({
                            showClose: true,
                            message: '请选择一项数据',
                            type: 'warning'
                        });
                    }
                }else{
                    this.UserDelete(form.id)

                }
            },
            UserDelete(id){
                this.$confirm(`删除${ id == undefined ? this.ids.length : '这' }条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            menuDelete( id == undefined ? this.ids : id ).then(res => {
                                // 获取数据成功后的其他操作
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.getMenuList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            searchChange(params,done) {
                done();
                this.$message.success(JSON.stringify(params))
            },

        }
    }
</script>

<style scoped>

</style>