<template>
	<div class="main-content">
		<pagesHeader title="积分管理" />
		<div class="integral">
			<div class="integral-title">用户获得积分比例设置：</div>
			<div class="conversion">
				<input
					type="text"
					v-model="getMoney"
					min="1"
					pattern="[0-9]"
					maxlength="6"
					onkeyup="value=value.replace(/[\W]/g,'')"
					onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
					placeholder="获得金额"
				/>
				<div>：</div>
				<input
					type="text"
					v-model="getIntegral"
					min="1"
					pattern="[0-9]"
					maxlength="6"
					onkeyup="value=value.replace(/[\W]/g,'')"
					onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
					placeholder="获得积分"
				/>
			</div>
			<div class="integral-tips">（例如：获得金额：获得积分=1:1，100元获得100积分）</div>
		</div>
		<div class="integral">
			<div class="integral-title">积分与金额比例设置：</div>
			<div class="conversion">
				<input
					type="text"
					v-model="integral"
					min="1"
					pattern="[0-9]"
					maxlength="6"
					onkeyup="value=value.replace(/[\W]/g,'')"
					onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
					placeholder="积分"
				/>
				<div>：</div>
				<input
					type="text"
					v-model="money"
					min="1"
					pattern="[0-9]"
					maxlength="6"
					onkeyup="value=value.replace(/[\W]/g,'')"
					onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
					placeholder="金额"
				/>
			</div>
			<div class="integral-tips">（例如：积分：金额=100:1，1元钱抵100积分）</div>
		</div>
		<div class="submit-button">
			 <el-button type="primary" @click="submit">立即生效</el-button>
		</div>
	</div>
</template>

<script>
	import { integralManageView,integralManageUpdate } from "@/api/integral/manege.js";
	export default {
		name: 'index',
		data(){
			return{
				getIntegral: '',
				getMoney: '',
				integral: '',
				money: ''
			}
		},
		mounted() {
			this.GetIntegralManageView();
		},
		methods: {
			submit(){
				integralManageUpdate({
					getIntegral: this.getIntegral,
					getMoney: this.getMoney,
					integral: this.integral,
					money: this.money,
				}).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功')
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			GetIntegralManageView(){
				integralManageView().then(res => {
					if(res.code === 200){
						this.getIntegral = res.data.getIntegral;
						this.getMoney = res.data.getMoney;
						this.integral = res.data.integral;
						this.money = res.data.money;
					}
				})
			},
		}
	}
</script>

<style scoped>
.integral{
	margin-bottom: 30px;
}
.integral-title{
	font-size: 16px;
	color: #000000;
	line-height: 40px;
	font-weight: 900;
}
.conversion{
	display: flex;
	align-items: center;
}
.conversion input{
	width: 200px;
	height: 40px;
	font-size: 14px;
	color: #333333;
	line-height: 40px;
	padding: 0 10px;
	border-radius: 5px;
}
.conversion div{
	padding: 0 20px;
}
.integral-tips{
	font-size: 14px;
	color: #F59A23;
	line-height: 40px;
}
.submit-button{
	padding: 20px 200px;
}
</style>
