<template>
	<div class="main-content">
		<pagesHeader title="商城页面管理" />
		<div class="shop">
			<div class="shop-title">选择会员商城小程序展示样式：</div>
			<div class="shop-radio">
				<el-radio v-model="radio" label="1">单列排版</el-radio>
				<el-radio v-model="radio" label="2">双列排版</el-radio>
			</div>
		</div>
		<div class="submit-button">
			 <el-button type="primary" @click="submit">立即生效</el-button>
		</div>
	</div>
</template>

<script>
	import { shopPageTypeView,shopPageTypeUpdate } from "@/api/shop/page.js"
	export default {
		name: 'index',
		data(){
			return{
				radio: '0'
			}
		},
		mounted() {
			this.GetShopPageTypeView();
		},
		methods: {
			submit(){
				shopPageTypeUpdate(this.radio * 1).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功')
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			GetShopPageTypeView(){
				shopPageTypeView().then(res => {
					if(res.code === 200){
						this.radio = res.data.type.toString();
					}
				})
			}
		}
	}
</script>

<style scoped>
.shop-title{
	font-size: 16px;
	color: #333333;
	line-height: 40px;
	font-family: 'Arial Normal', 'Arial', sans-serif;
	font-weight: 400;
	font-style: normal;
}
.shop-radio{
	padding: 25px 0;
}
.integral-tips{
	font-size: 14px;
	color: #F59A23;
	line-height: 40px;
}
.submit-button{
	padding: 20px 200px;
}
</style>
