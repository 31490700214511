<template>
	<div class="main-content">
		<pagesHeader title="回收人员列表" />
		<Search @changeRecoverUserSearch="changeRecoverUserSearch" @recoverUserAdd="recoverUserAdd"/>
		<avue-crud ref="crud"
			:data="data"
			:option="option"
			:page.sync="pageSync"
			@on-load="onLoad"
		>
			<template slot="menu" slot-scope="{ row }">
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickEdit(row)">编辑</el-button>
				<!-- <el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickCel(row)">删除</el-button> -->
				<el-button style="margin-left:10px;" size="small" type="text" @click.stop="clickState(row)">{{row.state == 1 ? '停用':'启用'}}</el-button>
        <el-button style="margin-left:10px;" size="small" type="text" v-if="row.recoverBalance == 0" @click.stop="clickMenu(row)">切换角色</el-button>
        <el-button style="margin-left:10px;color: #DCDFE9" size="small" type="text" v-else>切换角色</el-button>
			</template>

		</avue-crud>


		<RecoverUserIndex v-if="componentsData.type" :componentsData="componentsData"/>
	</div>
</template>

<script>
import {
  recoverUserList,
  recoverUserView,
  recoverUserStateUpdate,
  recoverUserDelete,
  recoverUserTypeUpdate
} from "@/api/recover/user.js";
	import Search from "./search.vue";
	import RecoverUserIndex from "@/components/recover/user/index.vue"
  import {orderRecoverCancel} from "@/api/order/list";
	export default {
		name: 'index',
		components: {
			Search,
			RecoverUserIndex
		},
		data(){
			return{
				componentsData: {
					type: false,
					state: '',
					detail: {}
				},
				search: {
					createTimeStart: '',
					createTimeEnd: '',
					name: '',
					state: ''
				},
				data: [],
				option: {
					addBtn: false,
					delBtn: false,
					editBtn: false,
					viewBtn: false,
					align: 'center',
					menuAlign: 'center',
					header: false,
					border: true,
					column: [
						{
							label: '回收员ID',
							prop: 'id',
							width: 250
						},
						{
						    label: '回收员头像',
						    prop: 'avatarUrl',
						    type: 'upload'
						},
						{
							label: '回收员姓名',
							prop: 'username'
						},
						{
							label: '登录账号',
							prop: 'phone'
						},
            {
              label: '累计回收金额',
              prop: 'recoverAmount'
            },
            {
              label: '已提现金额',
              prop: 'recoverWithdraw'
            },
            {
              label: '可提现金额',
              prop: 'recoverBalance'
            },
						{
							label: '联系方式',
							prop: 'contact'
						},
						{
							label: '账号状态',
							prop: 'state',
							type: 'radio',
							dicData: [{label: '启用',value: 1},{label: '停用',value: 2}]
						},
						{
							label: '创建时间',
							prop: 'createTime'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: 'total,pager,prev, next',
					background: false,
					pageSize: 10
				}
			}
		},
		mounted(){
			this.$root.eventHub.$on('changeRecoverUserIndex', (n)=>{
			    this.componentsData.type = false;
				if(n){
					this.GetRecoverUserList();
				}
			})
		},
		methods: {
			clickEdit(row){
				this.GetRecoverUserView(row.id);
			},
			clickState(row){
				recoverUserStateUpdate({
					id: row.id,
					state: row.state == 1 ? 2 : 1
				}).then(res => {
					if(res.code === 200){
						this.$message.success(row.state == 1 ? '已停用' : '已启用');
						this.GetRecoverUserList();
					}else{
						this.$message.error(res.msg)
					}
				})
			},
      clickMenu(row){
        this.$confirm(`是否切换成用户`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback: action => {
            if (action == 'confirm') {
              recoverUserTypeUpdate(row.id).then(res => {
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  this.onLoad();
                } else {
                  this.$message.error(res.msg);
                }
              });
            }
          }
        });
      },
			clickCel(row){
				recoverUserDelete(row.id).then(res => {
					if(res.code === 200){
						this.$message.success('已删除');
						this.GetRecoverUserList();
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			onLoad(){
				this.GetRecoverUserList();
			},
			GetRecoverUserList(){
				recoverUserList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					createTimeStart: this.search.createTimeStart, //创建时间开始
					createTimeEnd: this.search.createTimeEnd, //创建时间结束
					name: this.search.name,  //id/姓名/登录账号
					state: this.search.state, //状态：1-启用；2-停用
				}).then(res => {
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			GetRecoverUserView(id){
				recoverUserView(id).then(res => {
					if(res.code === 200){
						this.componentsData.type = true;
						this.componentsData.state = 'edit';
						this.componentsData.detail = res.data;
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			recoverUserAdd(e){
				this.componentsData.type = e;
				this.componentsData.state = 'add';
			},
			changeRecoverUserSearch(e){
				this.search = e;
				this.GetRecoverUserList();
			}
		}
	}
</script>

<style scoped>
</style>
