<template>
    <el-dialog
            title="编辑"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form"></avue-form>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="handleClose">确 定</el-button>
        </span> -->
    </el-dialog>
</template>

<script>
    import { userView } from "@/api/system/index";
    import { imageUrl } from '@/config/public';
    export default {
        name: "lookview",
        props: {
            dialogVisible: Boolean,
            choiceId: String
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label: '头像',
                            type: 'upload',
                            listType: 'picture-img',
                            span: 12,
                            row: true,
                            prop: 'headPortrait',
                            propsHttp:{
                                home: imageUrl
                            },
                            disabled:true,
                        },
                        {
                            label:'用户姓名',
                            prop:'fullName',
                            disabled:true,
                        },
                        {
                            label:'手机号码',
                            prop:'phone',
                            disabled:true,
                        },{
                            label: '所属角色',
                            prop: 'role',
                            type: "cascader",
                            dicData: this.$store.state.system.role,
                            multiple: true,
                            span: 24,
                            props: {
                                label: 'name',
                                value: 'id'
                            },
                            disabled:true,
                        }
                    ]
                }
            }
        },
        mounted() {
            userView(this.choiceId).then(res => {
                this.form = res.data;
            })
        },
        methods:{
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeView', false)
                    })
            },
        }
    }
</script>

<style scoped>

</style>