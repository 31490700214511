<template>
	<div class="main-content">
		<pagesHeader title="订单详情" />
		<el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
			<el-radio-button label="one">订单详情</el-radio-button>
			<el-radio-button label="two">物流信息</el-radio-button>
		</el-radio-group>
		<div v-if="tabPosition === 'one'">
			<el-descriptions :column="1">
				<el-descriptions-item label="订单编号">{{detail.code}}</el-descriptions-item>
				<el-descriptions-item label="下单时间">{{detail.commitTime}}</el-descriptions-item>
				<el-descriptions-item label="下单人">{{detail.nickname}}</el-descriptions-item>
				<el-descriptions-item label="订单金额">{{detail.price}}</el-descriptions-item>
				<el-descriptions-item label="运费">{{detail.isFreePostage == 1 ? '包邮':'不包邮'}}</el-descriptions-item>
				<el-descriptions-item label="应付金额">{{detail.meetPrice}}</el-descriptions-item>
				<el-descriptions-item label="实付金额">{{detail.reallyPrice}}</el-descriptions-item>
				<el-descriptions-item label="留言备注">{{detail.remarks}}</el-descriptions-item>
				<el-descriptions-item label="订单详情"></el-descriptions-item>
			</el-descriptions>
			<el-descriptions direction="vertical" :column="5" border>
			  <el-descriptions-item label="商品规格名称">{{detail.specName}}</el-descriptions-item>
			  <el-descriptions-item label="SKU编码">{{detail.skuCode}}</el-descriptions-item>
			  <el-descriptions-item label="数量">{{detail.num}}</el-descriptions-item>
			  <el-descriptions-item label="实付金额">{{detail.reallyPrice}}</el-descriptions-item>
			  <el-descriptions-item label="积分数">{{detail.specPoints}}</el-descriptions-item>
			</el-descriptions>
		</div>
		<div v-if="tabPosition === 'two'">
			<el-descriptions :column="1">
				<el-descriptions-item label="发货时间">{{detail.deliverTime}}</el-descriptions-item>
				<el-descriptions-item label="收货人">{{detail.username}}</el-descriptions-item>
				<el-descriptions-item label="收货地址">{{detail.detail}}</el-descriptions-item>
				<el-descriptions-item label="手机号">{{detail.phone}}</el-descriptions-item>
				<el-descriptions-item label="快递公司">{{detail.logisticsName || '无'}}</el-descriptions-item>
				<el-descriptions-item label="快递单号">{{detail.logisticsNumber || '无'}}</el-descriptions-item>
				<el-descriptions-item label="运费">{{detail.isFreePostage == 1 ? '包邮':'不包邮'}}</el-descriptions-item>
			</el-descriptions>
		</div>
	</div>
</template>

<script>
	import { shopOrderView } from "@/api/shop/order.js"
	export default {
		data(){
			return{
				tabPosition: 'one',
				detail: {},
			}
		},
		mounted() {
			this.GetShopOrderView();
		},
		methods: {
			GetShopOrderView(){
				shopOrderView(this.$route.params.id).then(res => {
					if(res.code === 200){
						this.detail = res.data;
					}
				})
			}
		}
	}
</script>

<style scoped>
	.el-radio-group >>> .el-radio-button__inner{
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
		background: #FFF !important;
		box-shadow: -1px 0 0 0 #DCDFE6 !important;
	}
	.el-radio-group >>> .el-radio-button__inner:hover {
	    color: #606266;
	}
	.el-descriptions >>> .el-descriptions-item__label{
		width: 100px;
	}
</style>
