import { orderRecoverClassifyList,orderRecoverUserList } from "@/api/order/list.js";

const orderList = {
    state: {
        classifyList: [],
		userList: []
    },
    mutations: {
        setClassifyList(state,info) {
			let data = [{name: '全部类型'}]
            state.classifyList = data.concat(info);
        },
        setUserList(state,info) {
            state.userList = info;
        },
    },
    actions: {
        async getClassifyList({commit}) {
            const result = await orderRecoverClassifyList().then(res => {
                return res.data;
            });
            commit('setClassifyList', result);
        },
		async getUserList({commit}) {
		    const result = await orderRecoverUserList().then(res => {
		        return res.data;
		    });
		    commit('setUserList', result);
		}
    }

}
export default orderList