<template>
	<div class="main-content">
		<pagesHeader title="回收上门时间管理" />
		<div class="week weekA">
			<div class="week-nav" v-for="(item,index) in week" :key="index"
				:class="dateDetail.week == item.value ? 'weekA-active' : ''"
				@click="choiceWeek(index)">
				{{item.label}}
			</div>
		</div>
		<div class="week weekB">
			<div class="week-date" v-for="(item,index) in time" :key="index"
				:class="dateDetail.start == item.label || dateDetail.end == item.label ? 'weekB-active' : ''"
				@click="choiceDay(item)">
				{{item.label}}
			</div>
		</div>
		<div class="submit">
			<el-button type="primary" :disabled="disabled" @click="submit">立即生效</el-button>
		</div>
	</div>
</template>

<script>
	import { callTimeList,callTimeUpdate } from "@/api/call/time";
	import time from "./date.json"
	export default {
		name: '',
		data(){
			return{
				week: [
					{label: '周一',value: 1},
					{label: '周二',value: 2},
					{label: '周三',value: 3},
					{label: '周四',value: 4},
					{label: '周五',value: 5},
					{label: '周六',value: 6},
					{label: '周日',value: 7},
				],
				time: time,
				date: [],
				dateDetail: {},
				disabled: false
			}
		},
		mounted() {
			this.GetCallTimeList();
		},
		methods: {
			choiceWeek(i){
				this.dateDetail = this.date[i];
			},
			choiceDay(item){
				// console.log(item)
				let start = this.dateDetail.start;
				let end = this.dateDetail.end;
				if(item.label == start){
					this.dateDetail.start = '';
				}else if(item.label == end){
					this.dateDetail.end = '';
				}else if(start == '' && end == ''){
					this.dateDetail.start = item.label;
				}else if(start != '' && end == ''){
					if(this.SetTime(start) > this.SetTime(item.label)){
						this.dateDetail.start = item.label;
						this.dateDetail.end = start;
					}else if(this.SetTime(start) < this.SetTime(item.label)){
						this.dateDetail.start = start;
						this.dateDetail.end = item.label;
					}
				}else if(start == '' && end != ''){
					if(this.SetTime(end) > this.SetTime(item.label)){
						this.dateDetail.start = item.label;
						this.dateDetail.end = end;
					}else if(this.SetTime(end) < this.SetTime(item.label)){
						this.dateDetail.start = end;
						this.dateDetail.end = item.label;
					}
				}else if(start != item.label && end != item.label){
					if(this.SetTime(start) > this.SetTime(item.label)){
						this.dateDetail.start = item.label;
					}else if(this.SetTime(start) < this.SetTime(item.label) && this.SetTime(end) > this.SetTime(item.label)){
						this.dateDetail.start = item.label;
					}else{
						this.dateDetail.end = item.label;
					}
				}
				// console.log(this.dateDetail)
			},
			SetTime(label){
				for(let i = 0;i < this.time.length; i++){
					if(this.time[i].label == label){
						return this.time[i].value
					}
				}
			},
			GetCallTimeList(){
				callTimeList().then(res => {
					// console.log(res.data)
					if(res.code === 200){
						this.date = res.data;
						this.dateDetail = res.data[0];
					}
				})
			},
			submit(){
				if(this.dateDetail.start == '' && this.dateDetail.end != ''){
					this.$message.error('请选择开始时间!')
				}else if(this.dateDetail.start != '' && this.dateDetail.end == ''){
					this.$message.error('请选择结束时间!')
				}else{
					this.disabled = true;
					callTimeUpdate({
						week: this.dateDetail.week,
						start: this.dateDetail.start,
						end: this.dateDetail.end,
					}).then(res => {
						if(res.code === 200){
							this.$message.success('修改成功')
						}else{
							this.$message.error(res.msg)
						}
						setTimeout(()=>{
							this.disabled = false;
						},1000)
					})
				}
			}
		}
	}
</script>

<style scoped>
.week{
	border-width: 1px;
	border-style: solid;
	border-color: rgba(127, 127, 127, 1);
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.weekA{
	padding: 0 10px;
	margin-bottom: 10px;
}
.weekA-active{
	color: rgb(64, 158, 255) !important;
}
.weekB-active{
	color: #FFFFFF !important;
	background-color: rgb(64, 158, 255);
	border-width: 0 !important;
}
.week-nav{
	font-size: 14px;
	color: #333333;
	line-height: 50px;
	padding: 0 10px;
	cursor: pointer;
}
.weekB{
	padding: 20px 15px;
}
.week-date{
	width: 50px;
	font-size: 14px;
	color: #333333;
	line-height: 40px;
	text-align: center;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(170, 170, 170, 1);
	border-radius: 5px;
	box-sizing: border-box;
	margin-bottom: 20px;
	margin-right: 10px;
	cursor: pointer;
}
.submit{
	display: flex;
	justify-content: flex-end;
	padding-top: 100px;
}
</style>
