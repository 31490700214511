<template>
  <div class="search">
    <el-button type="primary" style="margin-right: 10px" @click="userAdd"
      >新增</el-button
    >
    <el-date-picker
      v-model="form.date"
      type="daterange"
      range-title="创建时间"
      range-separator="~"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      class="date-picker"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      style="margin-right: 10px"
    >
    </el-date-picker>
    <el-input
      v-model="form.name"
      placeholder="请输入回收员ID/回收员姓名/登录账号"
      style="margin-right: 10px"
    ></el-input>
    <el-select
      v-model="form.state"
      placeholder="请选择"
      style="margin-right: 10px"
    >
      <el-option
        v-for="item in optionsState"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-button type="primary" @click="searchQuery">查询</el-button>
    <el-button type="info" @click="searchReset">重置</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: [],
        createTimeStart: "",
        createTimeEnd: "",
        name: "",
        state: "0",
      },
      optionsState: [
        {
          value: "0",
          label: "账号状态",
        },
        {
          value: "1",
          label: "启用",
        },
        {
          value: "2",
          label: "停用",
        },
      ],
    };
  },
  watch: {
    "form.date"(newValue, oldValue) {
      if (newValue != null) {
        newValue.forEach((item, index) => {
          if (index === 0) {
            this.form.createTimeStart = item + " 00:00:00";
          } else {
            this.form.createTimeEnd = item + " 23:59:59";
          }
        });
      } else {
        this.form.createTimeStart = "";
        this.form.createTimeEnd = "";
      }
    },
  },
  methods: {
    userAdd() {
      this.$emit("recoverUserAdd", true);
    },
    searchQuery() {
      this.$emit("changeRecoverUserSearch", this.form);
    },
    searchReset() {
      this.form = {
        date: [],
        createTimeStart: "",
        createTimeEnd: "",
        name: "",
        state: "0",
      };
      this.$emit("changeRecoverUserSearch", this.form);
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  margin-bottom: 20px;
}
.date-picker >>> .el-range-title {
  width: 20% !important;
  padding: 0 0 0 10px !important;
  font-size: 14px;
}
.date-picker >>> .el-range-separator {
  padding: 0;
}
.date-picker >>> .el-range-input {
  width: 30% !important;
}
.search > .el-range-editor.el-input__inner {
  padding: 3px 0px;
}
.search > .el-select {
  width: 200px;
}
.search > .el-input {
  width: 300px;
}
</style>
