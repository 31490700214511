<template>
	<el-dialog title="添加参数"
		width="40%"
		v-dialogdrag
		:visible.sync="parameter.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form">
		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			parameter: Object,
			spec: Array
		},
		data(){
			return{
				disabled: false,
				form: {
					index: this.parameter.index
				},
				option: {
					labelWidth: 120,
					submitBtn: false,
					emptyBtn: false,
					column: [
						{
							label:'参数名称',
							prop:'parameter',
							type: 'input',
							maxlength: 15,
							span: 20,
							rules: [{
								required: true,
								message: "请输入参数名称",
								trigger: "blur"
							}]
						}
					]
				}
			}
		},
		methods: {
			handleSubmit(){
				console.log(this.form.index)
				this.$refs.form.validate((vaild,done)=>{
				    if(vaild){
				    	let type = true;
						this.disabled = true;
				    	this.spec[this.form.index].value.forEach(item=>{
				    		if(item.content == this.form.parameter){
								return type = false;
							}
						})
						if(type){
							this.$root.eventHub.$emit('changeShopGoodsAdditionParameter', { state: true,data: this.form });
						}else{
							this.disabled = false;
							this.$message.error('已有参数名称')
						}
						done()
				    }
				})
			},
			handleClose(){
				this.$root.eventHub.$emit('changeShopGoodsAdditionParameter', { state: false });
			}
		}
	}
</script>

<style>
</style>
