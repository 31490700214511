import {get, post, put,Delete} from '../axios'

// 回收物品大类管理-列表
export const orderClassifyList = params => get('/web/order/big/list',params);

// 回收物品大类管理-新增
export const orderClassifyAdd = params => post('/web/order/big/add',params);

// 回收物品大类管理-删除
export const orderClassifyDelete = params => Delete(`/web/order/big/delete/${params}`);

// 回收物品大类管理-修改
export const orderClassifyUpdate = params => put('/web/order/big/update',params);

// 回收物品大类管理-查看
export const orderClassifyView = params => get(`/web/order/big/view/${params}`);

// 回收物品大类管理-显示修改
export const orderClassifyShowUpdate = params => put('/web/order/big/show/update',params);
