<template>
  <div class="search">
    <el-date-picker
      v-model="form.date"
      range-title="操作时间"
      type="datetimerange"
      class="date-picker"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      style="margin-right: 10px"
    >
    </el-date-picker>
    <el-input
      v-model="form.username"
      placeholder="输入操作人/操作内容"
      style="margin-right: 10px"
    ></el-input>
    <el-button @click="searchQuery">搜索</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: [],
        createTimeStart: "",
        createTimeEnd: "",
        username: "",
      },
    };
  },
  watch: {
    "form.date"(newValue, oldValue) {
      if (newValue != null) {
        newValue.forEach((item, index) => {
          if (index === 0) {
            this.form.createTimeStart = item + " 00:00:00";
          } else {
            this.form.createTimeEnd = item + " 23:59:59";
          }
        });
      } else {
        this.form.createTimeStart = "";
        this.form.createTimeEnd = "";
      }
    }
  },
  methods: {
    searchQuery() {
      console.log(1);
      this.$emit("changeLogOperate", this.form);
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  margin-bottom: 20px;
}
.date-picker {
  width: 480px !important;
}
.date-picker >>> .el-range-title {
  width: 30% !important;
  padding: 0 0 0 10px !important;
  font-size: 14px;
}
.date-picker >>> .el-range-separator {
  padding: 0;
}
.date-picker >>> .el-range-input {
  width: 100% !important;
}
.search > .el-range-editor.el-input__inner {
  padding: 3px 0px;
}
.search > .el-select {
  width: 200px;
}
.search > .el-input {
  width: 300px;
}
</style>
