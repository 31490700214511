import {get, post, put,Delete} from '../axios'

// 回收物品分类管理-列表
export const orderClassifyList = params => get('/web/order/classify/list',params);

// 回收物品分类管理-新增
export const orderClassifyAdd = params => post('/web/order/classify/add',params);

// 回收物品分类管理-删除
export const orderClassifyDelete = params => Delete(`/web/order/classify/delete/${params}`);

// 回收物品分类管理-修改
export const orderClassifyUpdate = params => put('/web/order/classify/update',params);

// 回收物品分类管理-查看
export const orderClassifyView = params => get(`/web/order/classify/view/${params}`);

// 回收物品分类管理-显示修改
export const orderClassifyBigList = params => get('/web/order/classify/big/list',params);

export const orderClassifyShowUpdate = params => put('/web/order/classify/show/update',params);
