<template>
  <el-dialog
    title="新增"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-before="uploadBefore"
      :upload-after="uploadAfter"
      @submit="formSubmit"
    >
      <template slot="phone">
        <input
          class="el-input__inner"
          style="height: 32px"
          placeholder="请输入 联系电话"
          maxlength="11"
          type="tel"
          v-model="form.phone"
          pattern="[0-9]"
          onkeyup="value=value.replace(/[\W]/g,'')"
          onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
        />
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import { imageUrl, actionUrl } from "@/config/public";
import { userAdd } from "@/api/system/index";
export default {
  name: "add",
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      form: {},
      option: {
        column: [
          {
            label: "头像",
            type: "upload",
            listType: "picture-img",
            span: 14,
            row: true,
            prop: "headPortrait",
            loadText: "附件上传中，请稍等",
            accept: 'image/png, image/jpeg',
            fileSize: 1024 * 20,
			tip: '只能上传jpg/png',
            propsHttp: {
              res: "data",
              url: "url",
              name: "path",
              home: imageUrl,
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: actionUrl,
            rules: [
              {
                required: true,
                message: "请上传头像",
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户名",
            prop: "username",
            maxlength: 20,
            showWordLimit:true,
            rules: [
              {
                required: true,
                message: "请输入用户名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "密码",
            prop: "password",
            maxlength: 30,
            showWordLimit:true,
            rules: [
              {
                required: true,
                message: "请输入密码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "昵称",
            prop: "fullName",
            maxlength: 20,
            showWordLimit:true,
            rules: [
              {
                required: true,
                message: "请输入昵称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "联系电话",
            prop: "phone",
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入联系电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属角色",
            prop: "role",
            type: "select",
            dicData: this.$store.state.system.role,
            span: 12,
            multiple: true,
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: "状态",
            prop: "state",
            type: "radio",
            dicData: [
              { label: "启用", value: 1 },
              { label: "禁用", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      console.log(res);
      // this.form.headPortrait = res.image;
      this.$nextTick(() => {
        this.form.headPortrait = res.path;
      });
    },
    handleClose() {
        this.$root.eventHub.$emit("changeAdd", false);
    },
    formSubmit(form, done) {
      let that = this;
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.phone)) {
        this.$message.error('手机号错误')
        done();
        return false;
      }
      userAdd({
        fullName: form.fullName,
        headPortrait: form.headPortrait,
        username: form.username,
        password: form.password,
        phone: form.phone,
        state: form.state,
        roleIds: form.role.toString(),
      })
        .then((res) => {
          // 获取数据成功后的其他操作
          console.log(res);
          if (res.code === 200) {
            that.$message.success(res.msg);
            this.$root.eventHub.$emit("changeAdd", true);
            done();
          } else {
            that.$message.error(res.msg);
            done();
          }
        })
        .catch((err) => {
          that.$message.error(err.msg);
          done();
        });
    },
  },
};
</script>

<style scoped>
</style>
