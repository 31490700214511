<template>
	<div class="search">
		<el-date-picker
			v-model="form.date"
			type="daterange"
			range-title="下单时间"
			range-separator="~"
			start-placeholder="开始日期"
			end-placeholder="结束日期"
			class="date-picker"
			format="yyyy-MM-dd"
			value-format="yyyy-MM-dd"
			style="margin-right: 10px"
		></el-date-picker>
		<el-select v-model="form.goodsClassify" placeholder="请选择回收品类型名称" style="margin-right: 10px">
			<el-option v-for="item in optionsGoodsClassify" :key="item.name" :label="item.name" :value="item.name"></el-option>
		</el-select>
		<el-input v-model="form.name" placeholder="请输入下单人昵称/手机号/联系人/联系方式" style="margin-right: 10px"></el-input>
		<el-select v-model="form.state" placeholder="请选择" style="margin-right: 10px">
			<el-option v-for="item in optionsState" :key="item.value" :label="item.label" :value="item.value"></el-option>
		</el-select>
		<el-select v-model="form.dispatchState" placeholder="请选择" style="margin-right: 10px">
			<el-option v-for="item in optionsDispatchState" :key="item.value" :label="item.label" :value="item.value"></el-option>
		</el-select>
		<el-button type="primary" @click="searchQuery">查询</el-button>
		<el-button type="info" @click="searchReset">重置</el-button>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	data() {
		return {
			form: {
				date: [],
				createTimeStart: '',
				createTimeEnd: '',
				goodsClassify: '全部类型',
				name: '',
				state: '0',
				dispatchState: '0'
			},
			optionsGoodsClassify: this.$store.state.orderList.classifyList,
			// optionsGoodsClassify: [],
			optionsState: [
				{
					value: '0',
					label: '全部状态'
				},
				{
					value: '1',
					label: '已下单'
				},
				{
					value: '2',
					label: '已接单'
				},
				{
					value: '3',
					label: '已完成'
				},
				{
					value: '4',
					label: '已取消'
				}
			],
			optionsDispatchState: [
				{
					value: '0',
					label: '派单状态'
				},
				{
					value: '1',
					label: '未派单'
				},
				{
					value: '2',
					label: '已派单'
				}
			]
		};
	},
	watch: {
		'form.date'(newValue, oldValue) {
			if (newValue != null) {
				newValue.forEach((item, index) => {
					if (index === 0) {
						this.form.createTimeStart = item + ' 00:00:00';
					} else {
						this.form.createTimeEnd = item + ' 23:59:59';
					}
				});
			} else {
				this.form.createTimeStart = '';
				this.form.createTimeEnd = '';
			}
		}
	},
	mounted() {
		this.getClassifyList();
	},
	methods: {
		...mapActions(['getClassifyList']),
		searchQuery() {
			let form = {
				createTimeStart: this.form.createTimeStart,
				createTimeEnd: this.form.createTimeEnd,
				goodsClassify: this.form.goodsClassify == '全部类型' ? '' : this.form.goodsClassify,
				name: this.form.name,
				state: this.form.state,
				dispatchState: this.form.dispatchState
			};
			console.log(form)
			this.$emit('changeSearch', form);
		},
		searchReset() {
			this.form = {
				date: [],
				createTimeStart: '',
				createTimeEnd: '',
				goodsClassify: '全部类型',
				name: '',
				state: '0',
				dispatchState: '0'
			};
			let form = {
				createTimeStart: '',
				createTimeEnd: '',
				goodsClassify: '',
				name: '',
				state: '0',
				dispatchState: '0'
			};
			this.$emit('changeSearch', form);
		}
	}
};
</script>

<style scoped>
.search {
	display: flex;
	margin-bottom: 20px;
}
.date-picker >>> .el-range-title {
	width: 20% !important;
	padding: 0 0 0 10px !important;
	font-size: 14px;
}
.date-picker >>> .el-range-separator {
	padding: 0;
}
.date-picker >>> .el-range-input {
	width: 30% !important;
}
.search > .el-range-editor.el-input__inner {
	padding: 3px 0px;
}
.search > .el-select {
	width: 200px;
}
.search > .el-input {
	width: 300px;
}
</style>
