<template>
	<el-dialog :title="componentsData.title"
		width="60%"
		v-dialogdrag
		:visible.sync="componentsData.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form"
			:upload-after="uploadAfter" :upload-before="uploadBefore"
			:upload-error="uploadError"
			:upload-delete="uploadDelete">

      <template slot="details">
        <avue-ueditor v-model="form.details" :options="uOptions"></avue-ueditor>
      </template>

			<template slot="price">
				<el-input v-model="form.price"
						  autocomplete="off"
						  type="digit"
						  oninput="this.value = this.value.replace(/[^0-9|(.)]/g, '');">
					<template slot="append">元</template>
				</el-input>
			</template>

			<template slot="sort">
				<el-input v-model="form.sort"
						  autocomplete="off"
						  type="text"
						  maxlength="6"
						  oninput="this.value = this.value.replace(/[^0-9]/g, '');"/>
			</template>

		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { imageUrl, actionUrl } from '@/config/public.js';
import { orderClassifyAdd, orderClassifyUpdate,orderClassifyBigList } from '@/api/order/classify.js';
export default {
	props: {
		componentsData: Object
	},
	data() {
		return {
			disabled: false,
			form: this.componentsData.state === 'edit' ? this.componentsData.data : {},
			option: {
			    submitBtn: false,
			    emptyBtn: false,
			    column: [
			        {
			            label:'分类名称',
			            prop:'name',
						type: 'input',
			            span: 18,
						maxlength: 4,
			            rules: [{
			                required: true,
			                message: "请输入分类名称",
			                trigger: "blur"
			            }]
			        },
            {
              label: '所属大类',
              prop: 'classifyId',
              type: 'select',
              dicData: [],
              props: {
                label: "name",
                value: "id"
              },
              span: 18,
              row: true,
              rules: [{
                required: true,
                message: "选择所属大类",
                trigger: "blur"
              }],
            },
					{
						label: '分类icon',
						prop: 'icon',
						type: 'upload',
						accept: 'image/png, image/jpeg',
						span: 24,
						listType: 'picture-img',
						tip: '最多上传1张',
						loadText: '附件上传中，请稍等',
            fileSize: 1024 * 20,
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						action: actionUrl,
						rules: [
							{
								required: true,
								message: '请上传分类icon',
								trigger: 'blur'
							}
						]
					},
					{
					    label:'单价',
					    prop:'price',
						  type: 'input',
						  append: '元',
					    span: 14,
						  slot: true
					},
					{
						label: '',
						prop: 'unit',
						type: 'input',
            span: 6,
            maxlength: 3,
            placeholder: '最多输入3个字',
						labelWidth: 0
					},
            {
              label: '详情',
              span: 20,
              prop: 'details',
              slot: true,
              rules: [{
                required: true,
                message: '请输入详情',
                trigger: 'blur'
              }]
            },
					{
					    label: '排序值',
					    prop: 'sort',
						type: 'number',
					    span: 18,
						slot: true,
						rules: [
							{
								required: true,
								message: '请填写排序值',
								trigger: 'blur'
							}
						]
					}
			    ]
			},
      uOptions: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token
        },
        customConfig: {},
        props: {
          url: 'path',
          res: 'data',
          name: 'path'
        }
      },
		};
	},
  mounted() {
    this.GET_infoManageClassify();
  },
	methods: {
		handleSubmit(form){
			console.log(form)
			this.$refs.form.validate((vaild,done)=>{
			    if(vaild){
					this.disabled = true;
					if(this.componentsData.state === 'add'){
						this.GetOrderClassifyAdd(form,done)
					}else{
						this.GetOrderClassifyUpdate(form,done)
					}
			    }
			})
		},
		GetOrderClassifyAdd(form,done){
			orderClassifyAdd({
				name: form.name,
				icon: form.icon,
				price: form.price,
        classifyId: form.classifyId,
				sort: parseInt(form.sort),
        details: form.details,
				unit: form.unit,
				state: form.price == '' || form.price == 0 ? 2 : 1
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$root.eventHub.$emit('changeClassifyIndex', true);
					this.$message.success('提交成功');
				} else {
					this.$message.error(res.msg);
					this.disabled = false;
				}
				done();
			});
		},
    GET_infoManageClassify(){
      orderClassifyBigList().then(res=>{
        if(res.code===200){
          const classifyId = this.findObject(this.option.column, 'classifyId');
          classifyId.dicData = res.data;
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err=>{
        this.$message.error(err.msg)
      })
    },
		GetOrderClassifyUpdate(form,done){
			orderClassifyUpdate({
				id: form.id,
				name: form.name,
				icon: form.icon,
				price: form.price,
        classifyId: form.classifyId,
				sort: parseInt(form.sort),
        details: form.details,
				unit: form.unit,
				state: form.price == '' || form.price == 0 ? 2 : 1
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$root.eventHub.$emit('changeClassifyIndex', true);
					this.$message.success('提交成功');
				} else {
					this.$message.error(res.msg);
					this.disabled = false;
				}
				done();
			});
		},
		handleClose() {
			this.$root.eventHub.$emit('changeClassifyIndex', false);
		},
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
		uploadAfter(res, done) {
			done();
			this.$nextTick(() => {
				this.form.icon = res.path;
			});
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error(error);
			// console.log(error, column);
		}
	}
};
</script>

<style scoped>
.avue-form >>> .el-upload{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-icon-plus{
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.avue-form >>> .el-upload--picture-card{
    line-height: 100px;
}
.avue-form >>> .avue-upload__avatar{
    width: 100px;
    height: 100px;
}
.avue-form >>> .el-upload-list__item,.el-upload-list__item-actions{
    width: 100px;
    height: 100px;
}
</style>
