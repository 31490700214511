<template>
	<el-dialog title="拒绝理由"
		width="40%"
		v-dialogdrag
		:visible.sync="refuse.type"
		class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false"
		:before-close="handleClose">
		<avue-form :option="option"
			v-model="form" ref="form">
		</avue-form>
		<div class="avue-dialog__footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary"
				@click="handleSubmit(form)" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { shopSalesRefuse } from '@/api/shop/sales.js';
	export default {
		props: {
			refuse: Object
		},
		data(){
			return{
				disabled: false,
				form: {
					ids: this.refuse.ids
				},
				option: {
					labelWidth: 120,
					submitBtn: false,
					emptyBtn: false,
					column: [
						{
							label:'理由',
							prop:'refuseReason',
							type: 'textarea',
							span: 20,
							rules: [{
								required: true,
								message: "请输入拒绝理由",
								trigger: "blur"
							}]
						}
					]
				}
			}
		},
		methods: {
			handleSubmit(form){
				this.$refs.form.validate((vaild,done)=>{
				    if(vaild){
						this.disabled = true;
						shopSalesRefuse({
							ids: form.ids,
							refuseReason: form.refuseReason
						}).then(res => {
							if (res.code === 200) {
								this.$message.success('操作成功');
								this.$root.eventHub.$emit('changeShopOrderRefuse', true);
							}
						});
				    }
				})
			},
			handleClose(){
				this.$root.eventHub.$emit('changeShopOrderRefuse', false);
			}
		}
	}
</script>

<style>
</style>
