import {get, post, put,Delete} from '../axios'

// 商品分类管理-列表
export const shopclassifyList = params => get('/web/shop/classify/list',params);

// 商品分类管理-新增
export const shopclassifyAdd = params => post('/web/shop/classify/add',params);

// 商品分类管理-删除
export const shopclassifyDelete = params => Delete(`/web/shop/classify/delete/${params}`);

// 商品分类管理-查看
export const shopclassifyView = params => get(`/web/shop/classify/view/${params}`);

// 商品分类管理-修改
export const shopclassifyUpdate = params => put('/web/shop/classify/update',params);

// 商品分类管理-显示修改
export const shopclassifyShowUpdate = params => put('/web/shop/classify/show/update',params);
