var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('pagesHeader',{attrs:{"title":"回收物品分类管理"}}),_c('avue-crud',{ref:"crud",attrs:{"data":_vm.data,"option":_vm.option,"page":_vm.pageSync},on:{"update:page":function($event){_vm.pageSync=$event},"on-load":_vm.onLoad},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('edit', row)}}},[_vm._v("编辑")]),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('cel', row)}}},[_vm._v("删除")])]}},{key:"price",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.price == 0)?_c('div',[_vm._v(_vm._s(row.unit))]):_c('div',[_vm._v(_vm._s(row.price)+"元"+_vm._s(row.unit == '' ? '':'/')+_vm._s(row.unit))])]}},{key:"isShow",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('el-switch',{on:{"change":function($event){return _vm.switchChange($event,row.id,index)}},model:{value:(row.isShow),callback:function ($$v) {_vm.$set(row, "isShow", $$v)},expression:"row.isShow"}})]}}])},[_c('template',{slot:"menuLeft"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.clickMenu('add')}}},[_vm._v("新增")])],1)],2),(_vm.componentsData.type)?_c('ClassifyIndex',{attrs:{"componentsData":_vm.componentsData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }